import {
  createStackNavigator,
  StackScreenProps,
} from '@react-navigation/stack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';

import { ColorAndIconSelect } from '@components/FormModals/ColorAndIconSelect.web';
import { WebModal } from '@components/Modals/WebModal';
import { CopyProjectDetails } from '@components/Projects/CopyProjectDetails';
import CreateProject from '@components/Projects/CreateProject';
import EditProject from '@components/Projects/EditProject';
import { Project } from '@graphql/generated';
import { useWebDrawerNavigator } from '@hooks/useWebDrawerNavigator';
import { setImagesAndUser } from '@navigation/auth/auth-stack.web';
import { NestedNavigatorParams } from '@navigation/helpers';
import {
  ProjectDetailTabs,
  ProjectDetailTabsParamList,
} from '@navigation/projects/project-detail-tabs.web';
import { ImagesPreviewNotLogin } from '@screens/Chats/ImagesPreviewNotLogin.web';
import { AllProjects } from '@screens/Projects/AllProjects.web';
import { ArchivedProjects } from '@screens/Projects/ArchivedProjects';

export type ProjectStackParamsList = {
  allprojects: undefined;
  summary: {
    projectId: Project['id'];
  } & Partial<NestedNavigatorParams<ProjectDetailTabsParamList>>;
  details: {
    projectId: Project['id'];
  } & Partial<NestedNavigatorParams<ProjectDetailTabsParamList>>;
  'archived-projects': undefined;
  new: undefined;
  edit: { projectId: Project['id'] };
  duplicate: { projectId: Project['id'] };
  colorSelect: { projectId: Project['id'] };
  'images-preview': undefined;
};

export type ProjectStackScreenProps<T extends keyof ProjectStackParamsList> =
  StackScreenProps<ProjectStackParamsList, T>;

const Stack = createStackNavigator<ProjectStackParamsList>();

export const ProjectsStack = () => {
  const { t } = useTranslation('shared');
  const { showProjectColorAndIconModal, setShowProjectColorAndIconModal } =
    useWebDrawerNavigator();
  const [isImageCarouselVisible, setImageCarouselVisible] = useState(false);
  const isImagesPreview =
    window.location.pathname.startsWith('/images-preview/');

  useEffect(() => {
    if (isImagesPreview) {
      setImagesAndUser();
      setImageCarouselVisible(isImagesPreview);
    }
  }, [isImagesPreview]);

  return (
    <>
      <Stack.Navigator
        initialRouteName={
          isImageCarouselVisible ? 'images-preview' : 'allprojects'
        }
        screenOptions={{ headerShown: false }}>
        {/* <Stack.Screen name='tabs' component={ProjectTabBar} /> */}
        <Stack.Screen name='allprojects' component={AllProjects} />
        <Stack.Screen name='details' component={ProjectDetailTabs} />
        <Stack.Screen name='archived-projects' component={ArchivedProjects} />
        <Stack.Screen
          name='new'
          component={CreateProject}
          options={{
            title: 'NEW title',
          }}
        />
        <Stack.Screen name='edit' component={EditProject} />
        {showProjectColorAndIconModal && (
          <Stack.Group>
            <Stack.Screen
              name='duplicate'
              options={{
                presentation: 'transparentModal',
                cardOverlayEnabled: true,
              }}>
              {({ navigation }) => (
                <WebModal
                  visible
                  width={504}
                  title={t('shared:select')}
                  onClose={() => {
                    setShowProjectColorAndIconModal(false);
                    navigation.goBack();
                  }}>
                  <CopyProjectDetails />
                </WebModal>
              )}
            </Stack.Screen>
            <Stack.Screen
              name='colorSelect'
              options={{
                presentation: 'transparentModal',
                cardOverlayEnabled: true,
              }}>
              {({ navigation }) => (
                <ColorAndIconSelect
                  onClose={() => {
                    setShowProjectColorAndIconModal(false);
                    navigation.goBack();
                  }}
                />
              )}
            </Stack.Screen>
          </Stack.Group>
        )}
        {isImageCarouselVisible && (
          <Stack.Screen name='images-preview' component={AllProjects} />
        )}
      </Stack.Navigator>
      {Platform.OS === 'web' && isImageCarouselVisible && (
        <ImagesPreviewNotLogin
          onDismiss={() => setImageCarouselVisible(false)}
        />
      )}
    </>
  );
};
