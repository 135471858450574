import { array, bool, number, object, string } from 'yup';

import { locationSchema } from './locationSchema';
import { userSchema } from './userSchema';

export const createSubscriptionSchema = object({
  plan: string().required('Required'),
  planName: string().required('Required'),
  billingCycle: string(),
  teamId: number().nullable(),
  paymentComplete: bool().oneOf([true], 'Payment Details are incomplete'),
  priceId: string(),
  price: number(),
  users: array()
    .of(userSchema)
    .ensure()
    .min(1, 'At least 1 member is required')
    .required('required'),
  logo: object().nullable(),
  // name: string().when('teamId', (teamId, schema) => teamId ? schema.nullable() : schema.required("Please fill in the field")),
  // name: string().test(
  //   'billingYear',
  //   'Name is required',
  //   (item, testContext) => {
  //     return true
  //   }
  // ),
  name: string().when(['planName', 'teamId'], {
    is: (planName?: string, teamId?: number) => {
      const check = planName?.toLowerCase().includes('team') && teamId === null;
      return check;
    },
    then: (schema) => schema.required('Name is required'),
    otherwise: (schema) => schema.nullable(),
  }),
  location: locationSchema.nullable(),
  skillIds: array().of(string()).ensure(),
});
