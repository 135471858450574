import { useRoute } from '@react-navigation/native';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FlatList,
  Linking,
  Modal,
  Platform,
  TouchableWithoutFeedback,
} from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';

import { HeaderGoBack } from '@components/HeaderGoBack/HeaderGoBack';
import { ModalHeader } from '@components/Headers/ModalHeader';
import { Box, ShadowBox, Text } from '@components/Restyle';
import Button from '@components/shared/Button/Button';
import Icon from '@components/shared/Icon/RestyleIcon';
import {
  Invoice,
  ListTeamInvoicesDocument,
  useCancelStripeSubscriptionMutation,
  useListTeamInvoicesQuery,
} from '@graphql/generated';
import { TeamsStackScreenProps } from '@navigation/teams/teams-stack';
import { teamStyles } from '@screens/Members/EditNewMemberRoles';
import theme from '@themes/theme';

export const TeamSubscription = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation();
  const route = useRoute<TeamsStackScreenProps<'team-subscription'>['route']>();

  const {
    params: { teamId },
  } = route || {};

  const { data } = useListTeamInvoicesQuery({
    variables: { id: teamId },
  });
  const [cancelStripeSubscription, { loading: cancellationLoading }] =
    useCancelStripeSubscriptionMutation({
      variables: { id: teamId },
      refetchQueries: [
        { query: ListTeamInvoicesDocument, variables: { id: teamId } },
      ],
    });
  const { getTeam: invoiceData } = data || {};
  const { invoices, plan } = invoiceData || {};

  console.log({ plan, invoices, data });

  const planDetails = () => (
    <Box padding='m' borderColor='grey02' borderRadius='s' borderWidth={1}>
      <Box flexDirection='row' justifyContent='space-between' mb='l'>
        <Text variant='labelEmphasized'>
          {t('models:teams.subscriptions.plan')}
        </Text>

        <Box flexDirection='row' alignItems='center'>
          <Box
            width={32}
            height={32}
            backgroundColor='purple'
            alignItems='center'
            justifyContent='center'
            borderRadius='xs'>
            <Icon
              name='Star' // TODO get this from metadata?
              color='background'
            />
          </Box>
          <Text variant='body' ml='xs' mr='m'>
            {plan?.name}
          </Text>
          {/* <TouchableOpacity onPress={() => setModalOpen(true)}>
            <Text variant='labelEmphasized' color='alertRed'>
              {t('models:teams.subscriptions.cancel')}
            </Text>
          </TouchableOpacity> */}
        </Box>
      </Box>
      <Box flexDirection='row' justifyContent='space-between' mb='l'>
        <Text variant='labelEmphasized'>
          {t('models:teams.subscriptions.nextInvoice')}
        </Text>
        <Text variant='body'>
          {t('models:teams.subscriptions.nextInvoiceInfo', {
            amount: (
              (Math.max(plan?.amount || 0, 1) / 100) *
              (plan?.quantity || 1)
            ).toFixed(2),
            date: t('format:dateSlash', { val: plan?.expiration }),
          })}
        </Text>
      </Box>
      <Box flexDirection='row' justifyContent='space-between'>
        <Text variant='labelEmphasized'>
          {t('models:teams.subscriptions.paymentMethod')}
        </Text>
        {invoices && invoices.length > 0 && (
          <Text variant='body'>
            {t('models:teams.subscriptions.paymentMethodInfo', {
              context: invoices[0].description && 'withLast4',
              brand: invoices[0].description, //TODO: should be paymentBrand
              last4: invoices[0].description, //TODO: should be paymentLast4
            })}
          </Text>
        )}
      </Box>
    </Box>
  );

  const invoiceItem = ({ item }: { item: Invoice }) => (
    <Box
      padding='m'
      borderColor='grey02'
      borderRadius='s'
      borderWidth={1}
      flexDirection='row'
      justifyContent='space-between'>
      <Box>
        <Text variant='body'>
          {t('models:teams.subscriptions.invoices.date')}
        </Text>
        <Text variant='labelEmphasized' mb='m'>
          {t('format:timeAgoTasksTableList', { val: item.date })}
        </Text>

        <Text variant='body'>
          {t('models:teams.subscriptions.invoices.description')}
        </Text>
        <Text variant='labelEmphasized'>{item.description}</Text>
      </Box>
      <Box alignItems='flex-end'>
        <Text variant='body'>
          {t('models:teams.subscriptions.invoices.invoiceTotal')}
        </Text>
        <Text variant='labelEmphasized' mb='m'>
          {t('models:teams.subscriptions.invoices.invoiceTotalAmount', {
            amount: (Math.max(item.amount, 1) / 100).toFixed(2),
          })}
        </Text>

        <Text variant='body'>
          {t('models:teams.subscriptions.invoices.status', {
            context: item.status.toLowerCase(),
          })}
        </Text>
        <TouchableOpacity onPress={() => Linking.openURL(item.invoiceUrl)}>
          <Text variant='labelEmphasized' color='greenSecondary'>
            {t('models:teams.subscriptions.invoices.viewInvoice')}
          </Text>
        </TouchableOpacity>
      </Box>
    </Box>
  );
  return (
    <Box
      flex={1}
      padding='m'
      style={Platform.OS === 'web' ? { marginEnd: 300 + theme.spacing.m } : {}}
      marginTop={Platform?.OS !== 'web' ? 'xxl' : 'xxxs'}>
      <Modal animationType='fade' transparent={true} visible={modalOpen}>
        <Box style={teamStyles.modalContainer}>
          <TouchableWithoutFeedback
            onPress={() => setModalOpen(!modalOpen)}
            style={teamStyles.modal}
            accessibilityLabel='CloseModalButton'>
            <Box style={teamStyles.modal} />
          </TouchableWithoutFeedback>

          <ShadowBox
            variant='thin'
            style={
              Platform.OS === 'web' ? teamStyles.popUpWeb : teamStyles.popUp
            }>
            <Box
              marginVertical='m'
              marginHorizontal='m'
              flex={1}
              justifyContent='space-between'>
              <Box
                style={teamStyles.modalHeader}
                mb='l'
                accessibilityLabel='RoleSelectModal'>
                <ModalHeader
                  title={t('models:teams.subscriptions.cancelModal.title')}
                  // submitLabel={t('shared:cancel')}
                  onCancel={() => setModalOpen(false)}
                  showCancel={false}
                  onSubmit={() => setModalOpen(false)}
                />
              </Box>
              <Text variant='labelEmphasized'>
                {t('models:teams.subscriptions.cancelModal.subTitle')}
              </Text>
              <Text>
                <Text mr='xxs' mb='s'>
                  {t('models:teams.subscriptions.cancelModal.accessThru')}
                </Text>
                <Text variant='labelEmphasized'>
                  {t('format:dateSlash', { val: plan?.expiration })}
                </Text>
              </Text>
              <Box flexDirection='row' mt='m' justifyContent='space-between'>
                <Button
                  variant='primary'
                  onPress={() => {
                    setModalOpen(false);
                  }}
                  fullWidth={false}>
                  {t('models:teams.subscriptions.cancelModal.cancel')}
                </Button>
                <Button
                  variant='delete'
                  disabled={cancellationLoading}
                  onPress={() => {
                    setModalOpen(false);
                    cancelStripeSubscription();
                    if (Platform.OS === 'web') {
                      window.location.reload();
                    }
                  }}
                  fullWidth={false}>
                  {t('models:teams.subscriptions.cancelModal.confirm')}
                </Button>
              </Box>
            </Box>
          </ShadowBox>
        </Box>
      </Modal>
      <HeaderGoBack
        redirectTo='team-details'
        titleHeader='Subscription'
        params={{ teamId: teamId }}
        noSpacing
      />
      <Box mb='m'>{planDetails()}</Box>

      <FlatList
        data={invoices || []}
        keyExtractor={(item) => item.id}
        ListEmptyComponent={<Text>No invoices yet</Text>}
        renderItem={invoiceItem}
        ListFooterComponent={() => <Box marginBottom='listFooter' />}
        ListHeaderComponent={() => (
          <Box>
            <Text variant='labelEmphasized' mb='m'>
              {t('models:teams.subscriptions.invoicesLabel', {
                count: invoices?.length,
              })}
            </Text>
          </Box>
        )}
        ItemSeparatorComponent={() => <Box marginBottom='l' />}
      />
    </Box>
  );
};
