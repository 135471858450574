import { WatchQueryFetchPolicy } from '@apollo/client';

import { Team, useGetTeamQuery } from '@graphql/generated';
/**
 * Retrieves a project for the provided `projectId`
 */
export const useTeamFromQuery = ({
  teamId,
  fetchPolicy = 'cache-and-network',
}: {
  teamId?: Team['id'];
  fetchPolicy?: WatchQueryFetchPolicy;
}) => {
  const {
    data: { getTeam } = {},
    loading,
    error,
    refetch,
  } = useGetTeamQuery({
    variables: {
      id: teamId || '',
    },
    fetchPolicy,
    skip: !teamId,
  });

  return { team: getTeam as Team | undefined, loading, error, refetch };
};
