import { useNavigation, useRoute } from '@react-navigation/native';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';

import { Alert } from '@components/Alert';
import { WebModal } from '@components/Modals/WebModal.web';
import { Box, Text } from '@components/Restyle';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import { MemberList } from '@components/User/MemberList';
import {
  User,
  useAssignTeamOwnerMutation,
  useLeaveTeamMutation,
  ListTeamsDocument,
} from '@graphql/generated';
import useMe from '@hooks/useMe';
import { useTeamFromQuery } from '@hooks/useTeamFromQuery';
import { TeamsStackScreenProps } from '@navigation/teams/teams-stack';

export const AssignNewOwner: React.FC = () => {
  const navigation = useNavigation();
  const { me } = useMe();
  const { t } = useTranslation();

  const [showAssignConfirmBox, setShowAssignConfirmBox] = useState(false);
  const [assignUser, setAssignUser] = useState<User | undefined>(undefined);

  const route = useRoute<TeamsStackScreenProps<'assign-new-owner'>['route']>();

  const {
    params: { teamId },
  } = route || {};

  const { team, loading } = useTeamFromQuery({
    teamId,
  });

  const [leaveTeam] = useLeaveTeamMutation({
    onCompleted: () => {
      // refactor when web implements teams
      navigation.popToTop();
    },
    refetchQueries: [{ query: ListTeamsDocument }],
  });

  const [assignTeamOwner] = useAssignTeamOwnerMutation({
    onCompleted: () => {
      if (!teamId) return;

      leaveTeam({
        variables: {
          id: teamId,
        },
      });
    },
    onError: (error) => {
      Alert.alert(t('shared:error'), error.message);
    },
  });

  if (loading) {
    return <ActivityIndicatorLoading />;
  }

  if (!team) {
    return (
      <Box flex={1} paddingTop='m' alignItems='center'>
        <Text>Team not found</Text>
      </Box>
    );
  }
  const { users } = team;

  const { id: myId } = me || { id: '' };
  const teamMembersWithoutMe = users?.filter((u) => u.id !== myId);

  const assignNewOwner = (user: User) => {
    if (!teamId) return;

    assignTeamOwner({
      variables: {
        id: teamId,
        newOwnerId: user.id,
      },
    });
  };

  const assignNewOwnerAlert = (user: User) => {
    if (Platform.OS === 'web') {
      setAssignUser(user);
      setShowAssignConfirmBox(true);
    } else
      Alert.alert(
        t('models:teams.assignNewOwner.title'),
        t('models:teams.assignNewOwner.message', { member: user.name }),
        [
          { text: t('shared:cancel') },
          {
            text: t('shared:assign'),
            onPress: () => assignNewOwner(user),
          },
        ]
      );
  };

  return (
    <Box flex={1}>
      <MemberList
        onPress={(user) => assignNewOwnerAlert(user)}
        users={teamMembersWithoutMe || []}
        listEmptyComponent={
          <Box marginHorizontal='m' mt='l'>
            <Text textAlign='center' color='grey04'>
              {t('models:teams.assignNewOwner.noOtherMembers')}
            </Text>
          </Box>
        }
      />
      {Platform.OS === 'web' && (
        <WebModal
          marginTop='m'
          visible={showAssignConfirmBox}
          width={483}
          height={196}
          title={t('models:teams.leave.title')}
          titleVariant='labelLarge'
          titleColor='textSecondary'
          marginHorizontal='l'
          onClose={() => {
            setShowAssignConfirmBox(false);
          }}>
          <Box flex={1} mt='l' mx='l'>
            <Text variant='webBodySecondary' color='black'>
              {t('models:teams.assignNewOwner.messageDowngradedToAdmin', {
                member: assignUser?.name,
              })}
            </Text>
            <Box
              mt='l'
              flexDirection='row'
              alignItems='flex-end'
              justifyContent='flex-end'>
              <TouchableOpacity
                onPress={() => {
                  setShowAssignConfirmBox(false);
                }}>
                <Box
                  height={56}
                  justifyContent='center'
                  alignContent='center'
                  alignItems='center'
                  accessibilityLabel={t(
                    'models:teams.removeTeamMember.assign'
                  )}>
                  <Text variant='typography' color='textPrimary' mr='l'>
                    {t('shared:cancel')}
                  </Text>
                </Box>
              </TouchableOpacity>

              <TouchableOpacity
                onPress={() => {
                  setShowAssignConfirmBox(false);
                  assignNewOwner(assignUser!);
                }}>
                <Box
                  backgroundColor='black'
                  borderRadius='xs'
                  width={100}
                  height={56}
                  justifyContent='center'
                  alignContent='center'
                  alignItems='center'
                  accessibilityLabel={t(
                    'models:teams.removeTeamMember.assign'
                  )}>
                  <Text variant='typography' color='white'>
                    {t('models:teams.removeTeamMember.assign')}
                  </Text>
                </Box>
              </TouchableOpacity>
            </Box>
          </Box>
        </WebModal>
      )}
    </Box>
  );
};
