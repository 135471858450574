import { Dimensions, Platform } from 'react-native';

const generateWebThumbnail = (
  videoUrl: string,
  width: number,
  height: number
): Promise<string> => {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');
    video.src = videoUrl;
    video.crossOrigin = 'anonymous';
    video.currentTime = 1;

    video.onloadeddata = () => {
      const canvas = document.createElement('canvas');
      canvas.width = width || video.videoWidth;
      canvas.height = height || video.videoHeight;
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        const thumbnailUrl = canvas.toDataURL('image/png');
        resolve(thumbnailUrl);
      } else {
        reject(new Error('Failed to get canvas context'));
      }
    };

    video.onerror = () => reject(new Error('Error loading video'));
  });
};

// Mobile-specific thumbnail generation using the react-native-create-thumbnail library
const generateMobileThumbnail = async (
  videoUrl: string,
  timeStamp = 1
): Promise<string> => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { createThumbnail } = require('react-native-create-thumbnail');
  const thumbnail = await createThumbnail({
    url: videoUrl,
    timeStamp,
  });
  return thumbnail.path;
};

// Unified function to generate thumbnails based on the platform
export const generateThumbnail = async (
  videoUrl: string,
  _width = 300,
  _height = 200
): Promise<string> => {
  try {
    if (Platform.OS === 'web') {
      return await generateWebThumbnail(
        videoUrl,
        Dimensions.get('screen').width,
        window.innerHeight - 293
      );
    } else {
      return await generateMobileThumbnail(videoUrl);
    }
  } catch (error) {
    //throw new Error(`Failed to generate thumbnail: ${error.message}`);
  }
};
