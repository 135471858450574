import React, { useState } from 'react';
import { TouchableOpacity } from 'react-native';

import { Box, Text } from '../Restyle';
import Icon from '../shared/Icon/Icon';

type RecentSearchItem = {
  text: string;
  onDelete: () => void;
  onPress: () => void;
};

const RecentSearchItem: React.FC<RecentSearchItem> = ({
  text,
  onPress,
  onDelete,
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isHoveredText, setIsHoveredText] = useState<boolean>(false);
  return (
    <TouchableOpacity onPress={onPress}>
      <Box
        accessibilityLabel='Recent Search Item'
        flexDirection='row'
        justifyContent='space-between'
        alignItems='center'
        paddingHorizontal='l'
        paddingVertical='s'
        backgroundColor={isHovered ? 'grey01' : 'white'}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}>
        <Icon name='Clock' color='textSecondary' variant='s' />
        <Text
          accessibilityLabel={text}
          color='textSecondary'
          variant='webBodySecondary'
          marginLeft='xs'
          textDecorationLine={isHoveredText ? 'underline' : undefined}
          onMouseEnter={() => setIsHoveredText(true)}
          onMouseLeave={() => setIsHoveredText(false)}>
          {text}
        </Text>
        <Box flex={1}></Box>
        <Icon
          accessibilityLabel='Remove recent search'
          onPress={onDelete}
          name='X'
          color='textSecondary'
          variant='l'
        />
      </Box>
    </TouchableOpacity>
  );
};

export default RecentSearchItem;
