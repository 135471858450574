import dayjs from 'dayjs';
import { Formik } from 'formik';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, TouchableOpacity } from 'react-native';
import Toast from 'react-native-toast-notifications';

import { TeamsModal } from '@components/FormModals/TeamsModal';
import { Box, Text } from '@components/Restyle';
import Button from '@components/shared/Button/Button';
import Icon from '@components/shared/Icon/Icon';
import { useWebDrawer } from '@components/Web/Drawer/WebDrawerContext';
import { PopupSubscriptionButtonWeb } from '@components/Web/PopupSubscriptionButton.web';
import { ProjectMemberRole, Team } from '@graphql/generated';
import { useListTeamsFromQuery } from '@hooks/useListTeamsFromQuery';
import useMe from '@hooks/useMe';
import { useWebDrawerNavigator } from '@hooks/useWebDrawerNavigator';
import { FormValues } from '@navigation/payments/create-subscription-stack';
import { useAppNavigation } from '@navigation/useAppNavigation';
import { createTeamSchema } from '@schemas/createTeamSchema';

export const SubscriptionPlan: React.FC = () => {
  const { t } = useTranslation();
  const { me } = useMe();
  const navigation = useAppNavigation();
  const { setSelectedTeam, setFromSubscriptionPlanList } =
    useWebDrawerNavigator();
  const [isHoveredTitleId, setIsHoveredTitleId] = useState<string | undefined>(
    undefined
  );
  const [isHoveredId, setIsHoveredId] = useState<string | undefined>(undefined);
  const [panelWidth, setPanelWidth] = useState<number>(1040);

  const [isHovered, setIsHovered] = useState(false);
  const [showCreateTeamModal, setShowCreateTeamModal] = useState(false);
  const [activeOnly, setActiveOnly] = useState<boolean>(true);

  const { teams } = useListTeamsFromQuery({});

  const users = () => {
    if (!me) {
      return [];
    }
    const owner = {
      id: me.id,
      avatar: me.avatar,
      name: me.name,
      firstName: me.firstName,
      lastName: me.lastName,
      role: ProjectMemberRole.Owner,
    };
    return [owner];
  };
  const {
    setUpgradeTeamPlanOpen,
    setIdProjectOfTaskDetailOpen,
    setIsTaskWebPanelMaximize,
    setIsTaskWebPanelOpen,
    setIdTaskDetailOpen,
    setIsCreateProjectOpen,
    setEditProjectId,
    setIsTeamPersonal,
    setIdTeamOpen,
    setTeamValues,
  } = useWebDrawer();

  const toastRef = useRef(null);

  const submitForm = async (
    values: FormValues,
    setSubmitting: (a: boolean) => void
  ) => {
    setSubmitting(true);

    setShowCreateTeamModal(false);

    setIsTaskWebPanelOpen(false);
    setIsTaskWebPanelMaximize(false);
    setIdTaskDetailOpen('');
    setIdProjectOfTaskDetailOpen('');
    setIsCreateProjectOpen(false);
    setEditProjectId('');
    setUpgradeTeamPlanOpen(true);
    setIdTeamOpen('');
    setIsTeamPersonal(false);
    setTeamValues(values);
    setSubmitting(false);
    return;
  };

  const initialValues: FormValues = {
    users: users(),
    teamId: null,
    plan: '',
    planName: '',
    billingCycle: 'year',
    paymentComplete: false,
    priceId: undefined,
    price: 0,
    location: null,
    name: '',
    skillIds: [],
    logo: null,
  };

  const filteredTeams = teams.filter((team) => team.personal);

  const sortedTeams = (
    teams
      ? [...teams].sort((teamA, teamB) => {
          // Compare team titles, move "Personal Projects" to the top
          if (teamA.personal) return -1;
          if (teamB.personal) return 1;
          // Sort other teams alphabetically
          return teamA.name.localeCompare(teamB.name);
        })
      : []
  ).filter((t) => {
    const expire = dayjs().isAfter(dayjs(t?.plan?.expiration));
    return (
      (t.plan !== null || t.personal) &&
      (activeOnly
        ? !((t.plan?.isCanceled ?? false) && expire)
        : (t.plan?.isCanceled ?? false) && expire)
    );
  });

  const renderItem = (team: Team, index: number) => {
    const hasAvatar =
      team.avatar !== 'TT' &&
      !(!team.avatar || team.avatar === '' || team.avatar === 'Office');
    return (
      <Box
        key={team.id}
        onMouseEnter={() => setIsHoveredId(team.id)}
        onMouseLeave={() => setIsHoveredId(undefined)}
        height={280}
        borderRadius='xs'
        borderWidth={1}
        borderColor='grey02'
        style={{ marginLeft: index % countPerRow == 0 ? 24 : 18 }}
        backgroundColor={isHoveredId === team.id ? 'grey01' : 'white'}
        mb='m'
        px='m'>
        <TouchableOpacity
          onPress={() => {
            setSelectedTeam(team);
            setFromSubscriptionPlanList(true);
            navigation.navigateToMyprofileSubscriptionDetail(
              team.id,
              team.users?.length || 0,
              team.invoices?.length || 0
            );
          }}>
          <Box
            flexDirection='row'
            alignItems='center'
            my='m'
            onMouseEnter={() => setIsHoveredTitleId(team.id)}
            onMouseLeave={() => setIsHoveredTitleId(undefined)}>
            {!team.personal && team.avatar === 'TT' && (
              <Box
                width={40}
                height={40}
                backgroundColor='black'
                borderRadius='xs'
                alignItems='center'
                justifyContent='center'>
                <Icon name='TT40' width={40} height={40} />
              </Box>
            )}
            {team.personal && (
              <Box
                width={40}
                height={40}
                backgroundColor='greenSecondaryMild'
                alignItems='center'
                justifyContent='center'
                borderRadius='xs'>
                <Icon
                  variant='xl'
                  borderRadius='xs'
                  padding='xs'
                  name='Office'
                  backgroundColor='greenSecondaryMild'
                  isCircle={false}
                />
              </Box>
            )}
            {hasAvatar && (
              <Box
                width={40}
                height={40}
                borderRadius='xs'
                justifyContent='center'
                alignItems='center'>
                <Icon name={team.avatar} variant='xxl' color='white' />
              </Box>
            )}

            <Text
              variant='labelEmphasized'
              color='textPrimary'
              ml='xs'
              textDecorationLine={
                isHoveredTitleId === team.id ? 'underline' : undefined
              }>
              {team.name}
            </Text>
          </Box>
        </TouchableOpacity>

        <Box flexDirection='row' alignItems='center' height={32}>
          <Text variant='labelSmall' color='textPrimary'>
            {t('models:profile.subscriptionPlans.plan')}
          </Text>
          <Box flex={1}></Box>
          <Box flexDirection='row' alignItems='center'>
            <Text variant='webBodySecondary' color='textSecondary'>
              {team.plan?.name}
            </Text>
          </Box>
        </Box>
        <Box flexDirection='row' alignItems='center' height={32}>
          <Text variant='labelSmall' color='textPrimary'>
            {t('models:projects.create.teams.team')}
          </Text>
          <Box flex={1}></Box>
          <Box flexDirection='row' alignItems='center'>
            <Text variant='webBodySecondary' color='textSecondary'>
              {team.personal ? 'Personal Projects' : team.name}
            </Text>
          </Box>
        </Box>
        <Box flexDirection='row' alignItems='center' height={32}>
          <Text variant='labelSmall' color='textPrimary'>
            {t('models:profile.subscriptionPlans.nextInvoice')}
          </Text>
          <Box flex={1}></Box>
          <Text variant='webBodySecondary' color='textSecondary'>
            {t('format:dateSlash', { val: team.plan?.expiration })}
          </Text>
        </Box>
        <Box flexDirection='row' alignItems='center' height={32}>
          <Text variant='labelSmall' color='textPrimary'>
            {t('models:teams.subscriptions.totalTeamMembers')}
          </Text>
          <Box flex={1}></Box>
          <Box flexDirection='row' alignItems='center'>
            <Text variant='webBodySecondary' color='textSecondary'>
              {team.usersCount ?? ''}
            </Text>
          </Box>
        </Box>
        <Box flexDirection='row' alignItems='center' height={32}>
          <Text variant='labelSmall' color='textPrimary'>
            {t('models:teams.subscriptions.totalAmount')}
          </Text>
          <Box flex={1}></Box>
          <Box flexDirection='row' alignItems='center'>
            <Text variant='webBodySecondary' color='textSecondary'>
              {team.plan?.amount
                ? `$${(
                    (team.plan?.amount / 100) *
                    (team?.plan?.quantity || 1)
                  ).toFixed(2)}`
                : ''}
            </Text>
          </Box>
        </Box>
        <Box flexDirection='row' alignItems='center' height={32}>
          <Text variant='labelSmall' color='textPrimary'>
            {t('models:profile.subscriptionPlans.paymentMethod')}
          </Text>

          <Box flex={1}></Box>
          <Box flexDirection='row' alignItems='center'>
            {/*<Icon name='Visa' variant='l' color='white' />*/}
            <Text variant='webBodySecondary' color='textSecondary' ml='xs'>
              {/*Visa ending in *****/}
              ****
            </Text>
          </Box>
        </Box>
      </Box>
    );
  };

  const offsetPopButton: Partial<any> = {
    position: 'right',
    offset: [0, 6],
  };

  // every card min width set to 320, margin to left is 18
  const countPerRow = (panelWidth - (panelWidth % 338)) / 338;
  const planRows =
    (sortedTeams.length - (sortedTeams.length % countPerRow)) / countPerRow +
    (sortedTeams.length % countPerRow > 0 ? 1 : 0);
  const rowList = Array.from({ length: planRows }, (_, index) => index + 1);

  return (
    <Box
      width='100%'
      height='100%'
      onLayout={(event) => {
        // the start and end margin is 8 and 24
        setPanelWidth(event.nativeEvent.layout.width - 30);
      }}>
      <Box ml='l' mt='m' flexDirection='row'>
        <PopupSubscriptionButtonWeb
          title={
            activeOnly
              ? t('models:profile.subscriptionPlans.activeSubscriptions')
              : t('models:profile.subscriptionPlans.canceledSubscriptions')
          }
          boxProps={offsetPopButton}
          onPressActiveSubscriptions={() => {
            setActiveOnly(true);
          }}
          onPressCanceledSubscriptions={() => {
            setActiveOnly(false);
          }}
        />
        <Box flex={1}></Box>

        <Button
          accessibilityLabel='Create Team'
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          isSmall
          height={32}
          backgroundColor={isHovered ? 'grey05' : 'black'}
          prefix={<Icon variant='s' name='Plus' color='white' />}
          onPress={() => {
            setShowCreateTeamModal(true);
          }}
          borderRadius='xs'
          borderColor={isHovered ? 'grey05' : 'black'}
          variant='create'
          marginRight='l'
          prefixMarginRight='xs'
          paddingHorizontal='m'
          paddingVertical='xs'>
          Create Team
        </Button>
      </Box>
      <Box flex={1} mt='m'>
        <ScrollView showsVerticalScrollIndicator={false}>
          {rowList.map((i, index) => (
            <Box
              key={i.toString() + '_' + index.toString()}
              flexDirection='row'
              flex={1}
              justifyContent='space-between'
              alignItems='stretch'
              mr='l'>
              {sortedTeams.map(
                (team, index) =>
                  index >= (i - 1) * countPerRow &&
                  index < i * countPerRow && (
                    <Box flex={1} key={team.id}>
                      {renderItem(team, index)}
                    </Box>
                  )
              )}
              {i === rowList.length &&
                i * countPerRow > sortedTeams.length &&
                Array.from(
                  { length: i * countPerRow - sortedTeams.length },
                  (_, j) => j + 1
                ).map((_, index) => {
                  return <Box mt='m' flex={1} key={index.toString()}></Box>;
                })}
            </Box>
          ))}
        </ScrollView>
      </Box>

      {showCreateTeamModal && (
        <Formik
          initialValues={initialValues}
          validationSchema={createTeamSchema}
          onSubmit={(values, { setSubmitting }) => {
            submitForm(values, setSubmitting);
          }}>
          {() => {
            return (
              <TeamsModal
                title='Team Setup'
                teams={filteredTeams}
                buttonLabel={t('shared:next')}
                onClose={() => setShowCreateTeamModal(false)}
              />
            );
          }}
        </Formik>
      )}
      <Toast ref={toastRef} />
    </Box>
  );
};
