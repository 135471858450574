import React, { useState } from 'react';
import { TouchableOpacity, StyleSheet } from 'react-native';

import CachedImage from '@components/CachedImage/CachedImage';
import PlayCircle from '@components/Icons/PlayCircle';
import RadioButton from '@components/RadioButton/RadioButton';
import { Box } from '@components/Restyle';
import { Document, LocalFile } from '@graphql/generated';
import theme from '@themes/theme';

interface ImageGridProps {
  list: Document[] | LocalFile[];
  onPress?: (image: Document | LocalFile) => void;
  onDelete?: (index: number) => void;
  onLongPress?: (image: Document | LocalFile) => void;
  isSelectMode?: boolean;
  selectedList?: Array<Document | LocalFile>;
}

const ImageGrid: React.FC<ImageGridProps> = ({
  list,
  onPress,
  onDelete,
  onLongPress,
  isSelectMode = false,
  selectedList,
}) => {
  const numImages = 6;
  const [bodyWidth, setBodyWidth] = useState(0);

  const thirdWidth = (bodyWidth - 32) / numImages;

  const getMarginRight = (i: number): number => {
    if ((i + 1) % numImages) {
      return theme.spacing.xxs;
    } else {
      return 0;
    }
  };

  const isDocument = (item: Document | LocalFile): item is Document => {
    return item.__typename === 'Document';
  };

  return (
    <Box
      flexDirection='row'
      flexWrap='wrap'
      onLayout={(e) => setBodyWidth(e.nativeEvent.layout.width)}>
      {list.map((item: Document | LocalFile, i) => {
        // NOTE: Image begins as a LocalFile instance that matches the shape of File type.
        // After processing the image attachment, a Document instance is returned and Document
        // type has a `file` field which is of type File.
        const isVideo = item.isVideo;
        const image = isDocument(item) ? item.file : item;
        const isSelected = selectedList?.find((item2) => {
          return item2.id == item.id;
        });

        return (
          <TouchableOpacity
            accessibilityLabel={item.name}
            key={image.id}
            onPress={() => onPress && onPress(item)}
            onLongPress={() => onLongPress && onLongPress(item)}
            style={[
              {
                width: thirdWidth,
                height: thirdWidth,
                flexDirection: 'row',
                marginRight: getMarginRight(i),
                marginBottom: theme.spacing.xxs,
              },
            ]}>
            <CachedImage
              image={image}
              width={thirdWidth}
              height={thirdWidth}
              isRemoveable={!!onDelete}
              contentType={item.contentType}
            />
            {isVideo && <PlayCircle />}
            {isSelectMode && isSelected && (
              <Box
                style={{
                  ...StyleSheet.absoluteFillObject,
                  backgroundColor: 'rgba(0, 0, 0, 0.4)',
                }}>
                <Box position='absolute' style={{ right: 4, top: 4 }}>
                  <RadioButton
                    iconVariant='m'
                    isSelected={!!isSelected}
                    onPress={() => onPress && onPress(item)}
                  />
                </Box>
              </Box>
            )}
          </TouchableOpacity>
        );
      })}
    </Box>
  );
};

export default ImageGrid;
