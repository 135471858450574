import React from 'react';

import Popup, { MenuItem } from '../shared/Popup/Popup';

type SubscriptionPopupProps = {
  closePopFn?: () => void;
  openEditSubscription: () => void;
  doCancelSubscription: () => void;
};

export const SubscriptionPopup: React.FC<SubscriptionPopupProps> = ({
  closePopFn,
  doCancelSubscription,
  openEditSubscription,
}) => {
  const menuList: MenuItem[] = [
    {
      iconName: 'Edit',
      name: 'Edit',
      onSelect: openEditSubscription,
    },
    {
      iconName: 'XCircle',
      isRed: true,
      name: 'Cancel',
      onSelect: doCancelSubscription,
    },
  ];
  return (
    <Popup
      width={103}
      menuList={menuList}
      closePopFn={closePopFn}
      marginIconToText='xs'
    />
  );
};
