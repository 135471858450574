import React from 'react';

import { Box, Text } from '../Restyle';
import Icon from '../shared/Icon/Icon';

const EmptyStateNoTeams: React.FC = () => {
  return (
    <Box flex={1} alignItems='center' justifyContent='center'>
      <Icon name='WinnerIsometric' width={200} height={155}></Icon>
      <Box>
        <Text
          variant='heading1'
          textAlign='center'
          paddingBottom='xs'
          color='onSurfaceSecondary'>
          Get more with teams
        </Text>
        <Text
          variant='body'
          textAlign='center'
          color='onSurfaceSecondary'
          marginHorizontal='m'>
          Scale across your company by setting up a team here.
        </Text>
      </Box>
    </Box>
  );
};

export default EmptyStateNoTeams;
