import { NavigationProp, useNavigation } from '@react-navigation/native';
import * as Clipboard from 'expo-clipboard';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, StyleSheet } from 'react-native';
import { useToast } from 'react-native-toast-notifications';

import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import { useCreateTeamInvitationMutation } from '@graphql/generated';
import { TeamsStackParamsList } from '@navigation/teams/teams-stack';
import theme from '@themes/theme';

interface TeamActionListProps {
  isOwnerOrAdmin?: boolean;
  setShowAddMembersModal: (show: boolean) => void;
  teamId: string;
}

const TeamActionList: React.FC<TeamActionListProps> = ({
  isOwnerOrAdmin,
  setShowAddMembersModal,
  teamId,
}) => {
  const { t } = useTranslation();
  const navigation = useNavigation<NavigationProp<TeamsStackParamsList>>();
  const toast = useToast();

  if (!isOwnerOrAdmin) return null;

  const [createTeamInvitationMutation] = useCreateTeamInvitationMutation({
    onCompleted: ({ createTeamInvitation }) => {
      if (createTeamInvitation) {
        const formattedLink =
          createTeamInvitation.replace(
            'register/register-with-email',
            'invited-user'
          ) + `&type=team`;
        copyToClipboard(formattedLink);
      }
    },
  });

  const copyToClipboard = async (link: string) => {
    if (!link) return; // Early return if link is falsy
    await Clipboard.setStringAsync(link);

    toast.show('Copied', {
      duration: 2000,
    });
  };

  return (
    <Box flexDirection='row' alignItems='center' mx='m' mt='xs' mb='m'>
      <TouchableOpacity
        onPress={() => {
          setShowAddMembersModal(true);
        }}
        style={styles.marginRight}>
        <Box
          accessibilityLabel={t('shared:addMembers')}
          flexDirection='row'
          alignItems='center'>
          <Icon name='UserPlus' variant='l' backgroundColor='grey01' />
          <Text variant='labelEmphasized' ml='xs'>
            {t('shared:addMembers')}
          </Text>
        </Box>
      </TouchableOpacity>

      <TouchableOpacity
        onPress={() => {
          createTeamInvitationMutation({ variables: { id: teamId } });
        }}
        style={styles.marginRight}>
        <Box
          accessibilityLabel={t('shared:copyLinkToInvite')}
          flexDirection='row'
          alignItems='center'>
          <Icon name='Link' variant='l' backgroundColor='grey01' />
          <Text variant='labelEmphasized' ml='xs'>
            {t('shared:copyLinkToInvite')}
          </Text>
        </Box>
      </TouchableOpacity>

      <TouchableOpacity
        onPress={() => navigation.navigate('team-member-role-select-modal')}>
        <Box
          accessibilityLabel={t('shared:pendingRequest')}
          flexDirection='row'
          alignItems='center'>
          <Icon name='Clock' variant='l' backgroundColor='grey01' />
          <Text variant='labelEmphasized' ml='xs'>
            {t('shared:pendingRequest')}
          </Text>
        </Box>
      </TouchableOpacity>
    </Box>
  );
};

const styles = StyleSheet.create({
  marginRight: {
    marginRight: theme.spacing.l,
  },
});

export default TeamActionList;
