import { useNavigation, useRoute } from '@react-navigation/native';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native-gesture-handler';

import { Box, ShadowBox, Text } from '@components/Restyle';
import Button from '@components/shared/Button/Button';
import Icon from '@components/shared/Icon/RestyleIcon';
import RestyleIcon from '@components/shared/Icon/RestyleIcon';
import { useListProductsQuery } from '@graphql/generated';
import Images from '@root/themes/images';

import theme from '../../../themes/theme';

export const PlanSelect = () => {
  const { t } = useTranslation();
  const navigator = useNavigation();
  const [currentPlan] = useState('Basic');
  const { data: listProductsData } = useListProductsQuery();

  type Plan = {
    id: number;
    iconName: keyof typeof Images;
    color: string;
    name: string;
    audience: string;
    price: string;
    priceSuffix?: string;
    monthlyPricing?: string;
    features: string[];
    buttonColor?: string;
    buttonLabel?: string;
    buttonLabelColor?: string;
    bestValue: boolean;
    position: string;
  };
  const { params } = useRoute();

  const teamId = (params as { teamId: string })?.teamId;
  const isPersonal = (params as { isPersonal: boolean })?.isPersonal;
  const navigation = useNavigation();

  const initialPlans: Plan[] = [
    {
      id: -1,
      name: 'Basic',
      iconName: 'Hash',
      color: 'grey05',
      audience: 'For individuals',
      price: 'Free',
      features: [
        '3 projects',
        '2 GB storage',
        'Unlimited free project & task users',
        'Unlimited Messages',
        'Chat attachments expire in 30 days',
        'Unlimited Cloud-based Messages',
      ],
      position: '-1',
      bestValue: false,
    },
  ];

  const getFeatures = (features: any) => {
    const featureList: string[] = [];
    // ""projects_amount"": ""unlimited"",
    if (features.projects_amount === 'unlimited') {
      featureList.push('Unlimited projects');
    } else if (features.projects_amount === '1') {
      featureList.push(features.projects_amount + ' project');
    } else {
      featureList.push(features.projects_amount + ' projects');
    }
    const storage = features.storage_gb_amount;
    if (storage >= 1000) {
      featureList.push((storage / 1000).toString() + ' TB storage');
    } else {
      featureList.push(storage + ' GB storage');
    }

    featureList.push('Unlimited Free Project & Task Users');

    if (features.tasks_amount === 'unlimited') {
      featureList.push('Unlimited tasks');
    } else if (features.tasks_amount === '1') {
      featureList.push(features.tasks_amount + ' task');
    } else {
      featureList.push(features.tasks_amount + ' tasks');
    }
    if (features.attachment_expiration_in_days === 'unlimited') {
      featureList.push('Chat attachments do not expire');
    } else {
      featureList.push(
        'Chat attachments expire in ' +
          features.attachment_expiration_in_days +
          ' days'
      );
    }
    featureList.push('Unlimited cloud-based messages');

    if (
      features.can_create_team === 'true' &&
      features.allow_members === 'true'
    ) {
      featureList.push('Admin & Member roles');
    } else if (features.allow_members === 'true') {
      featureList.push('Member roles');
    }

    if (features.projects_amount) return featureList;
  };

  const remotePlans = listProductsData?.listProducts.map((product) => {
    const teamPlan = product.name.toLowerCase().includes('team');
    const price =
      (product.prices.find((price) => price.interval == 'year')?.amount || 1) /
      12 /
      100;
    const monthlyPrice =
      (product.prices.find((price) => price.interval == 'month')?.amount || 1) /
      100;
    return {
      id: product.id,
      name: product.name,
      position: product.features.position,
      iconName: teamPlan ? 'Star' : 'Zap',
      color: teamPlan ? 'purple' : 'orange',
      audience: teamPlan ? 'For teams' : 'For individuals',
      price: price.toFixed(0),
      priceSuffix: teamPlan
        ? '/ mo per user billed annually'
        : '/ mo billed annually',
      monthlyPricing: monthlyPrice.toFixed(),
      bestValue: teamPlan,
      buttonColor: teamPlan ? 'onBackground' : 'background',
      buttonLabelColor: teamPlan ? 'background' : 'onBackground',
      buttonLabel: 'Start a 30-day free trial',
      features: getFeatures(product.features),
    };
  });

  const filteredRemotePlans = (remotePlans || []).filter(
    (plan) => plan.name.includes('TaskTag Team') || plan.name === 'Basic'
  );

  const plans = (
    isPersonal
      ? [...initialPlans, ...(remotePlans || [])]
      : [
          ...initialPlans,
          // eslint-disable-next-line no-unsafe-optional-chaining
          ...filteredRemotePlans,
        ]
  ).toSorted((a, b) => parseInt(a.position) - parseInt(b.position));

  const planItem = (product: Plan, isLastOne: boolean) => {
    return (
      <ShadowBox
        variant='thin'
        borderRadius='m'
        marginRight={isLastOne ? 'none' : 'm'}
        style={{ height: '100%' }}>
        <Box
          minWidth={248}
          flex={1}
          borderRadius='m'
          borderColor={product.bestValue ? product.color : undefined}
          borderWidth={product.bestValue ? 1 : 0}>
          <Box>
            {product.bestValue && (
              <Box
                backgroundColor={product.color}
                borderTopRightRadius='m'
                borderTopLeftRadius='m'
                padding='xxs'
                alignItems='center'>
                <Text variant='labelSmall' color='background'>
                  Best Value!
                </Text>
              </Box>
            )}
            <Box padding='m'>
              <Box
                flexDirection='row'
                alignItems='center'
                justifyContent='space-between'
                mb='xs'
                style={{
                  marginTop: product.bestValue
                    ? 0
                    : theme.spacing.xxs * 2 +
                      theme.textVariants.labelSmall.lineHeight,
                }}>
                <Box flexDirection='row' alignItems='center'>
                  <Icon
                    name={product.iconName} // TODO get this from metadata?
                    backgroundColor={product.color} // TODO get this from metadata?
                    color='background'
                  />
                  <Text variant='labelEmphasized' marginHorizontal='xs'>
                    {product.name}
                  </Text>
                </Box>
                {currentPlan == product.name && (
                  <Box
                    borderRadius='xxs'
                    backgroundColor='blueMild'
                    padding='xxs'>
                    <Text variant='metadata' color='blue'>
                      Current plan
                    </Text>
                  </Box>
                )}
              </Box>
              <Text color={product.color} mb='l' variant='labelSmall'>
                {product.audience}
              </Text>
              <Box mb='xl'>
                <Box flexDirection='row' alignItems='baseline'>
                  <Text variant='heading1'>
                    {product.price.toLowerCase() == 'free'
                      ? 'Free'
                      : `$${product.price}`}
                  </Text>
                  {product.priceSuffix && (
                    <Text variant='metadata'>{product.priceSuffix}</Text>
                  )}
                </Box>
                <Text variant='metadataSecondary' color='grey04'>
                  {product.monthlyPricing && product.priceSuffix
                    ? `$${product.monthlyPricing} ${product.priceSuffix}`
                    : ' '}
                </Text>
              </Box>
              <Box height={1} backgroundColor='grey02' mb='m' />
              {product.features?.map((feature) => {
                return (
                  <Box flexDirection='row' mb='m'>
                    <Box mr='xs'>
                      <Icon
                        name='Check' // TODO get this from metadata?
                        color={product.color} // TODO get this from metadata?
                      />
                    </Box>
                    <Text variant='bodySecondary'>{feature}</Text>
                  </Box>
                );
              })}
            </Box>
          </Box>
          <Box flex={1}></Box>
          {product.buttonLabel && (
            <Box px='m' pb='m'>
              <Button
                onPress={() => {
                  navigator.navigate('create-subscription', {
                    planId: product.id,
                    teamId,
                    isPersonal,
                    isPlanPlus: product?.name?.toUpperCase()?.includes('PLUS'),
                  });
                }}
                backgroundColor={product.buttonColor}
                borderRadius='s'
                borderColor={product.buttonLabelColor}
                borderWidth={1}>
                <Text variant='buttonLabel' color={product.buttonLabelColor}>
                  {product.buttonLabel}
                </Text>
              </Button>
            </Box>
          )}
        </Box>
      </ShadowBox>
    );
  };

  const planRows = plans.length / 3 + (plans.length % 3 > 0 ? +1 : 0);
  const rowList = Array.from({ length: planRows }, (_, index) => index + 1);

  return (
    <Box padding='l' marginTop='xxxs'>
      <Box
        flexDirection='row'
        marginHorizontal='none'
        marginTop='none'
        marginBottom='m'>
        <TouchableOpacity
          onPress={() =>
            navigation.navigate('teams-stack', {
              screen: teamId ? 'team-details' : 'my-teams',
              params: { teamId: teamId },
            })
          }
          hitSlop={{ top: 10, bottom: 10, right: 10, left: 10 }}>
          <RestyleIcon name='ChevronLeft' variant='xxl' color='textPrimary' />
        </TouchableOpacity>

        <Box flex={1} flexDirection='row' marginLeft='xs'>
          <Text variant='heading3' color='black' textAlign='left'>
            {t('shared:upgrade')}
          </Text>
        </Box>
      </Box>

      <Text mb='m' variant='heading2'>
        {t('models:payments.web.heading')}
      </Text>

      {rowList.map((i) => (
        <Box
          flexDirection='row'
          mb='l'
          justifyContent='space-between'
          alignItems='stretch'>
          {plans
            .sort((a, b) => parseInt(a.position) - parseInt(b.position))
            .map(
              (plan, index) =>
                index >= (i - 1) * 3 &&
                index < i * 3 && (
                  <Box flex={1}>{planItem(plan, index === i * 3 - 1)}</Box>
                )
            )}
          {i * 3 - 2 >= plans.length && <Box flex={1}></Box>}
          {i * 3 - 1 >= plans.length && <Box flex={1}></Box>}
        </Box>
      ))}
    </Box>
  );
};
