import { InMemoryCache } from '@apollo/client';
import { createFragmentRegistry } from '@apollo/client/cache';
import { disableFragmentWarnings } from 'graphql-tag';
import uniqBy from 'lodash.uniqby';

import {
  AttachmentsFragmentDoc,
  DocumentDetailFragmentDoc,
  DraftMessageDetailFragmentDoc,
  FileDetailsFragmentDoc,
  MessageDetailFragmentDoc,
  ProjectDetailFragmentDoc,
  TaskDetailFragmentDoc,
  UserDetailFragmentDoc,
} from '@graphql/generated';
import { relayStylePagination } from '@hooks/pagination';

export const initializeCache = () => {
  const fragmentRegistry = createFragmentRegistry(
    FileDetailsFragmentDoc,
    AttachmentsFragmentDoc,
    MessageDetailFragmentDoc,
    DraftMessageDetailFragmentDoc,
    UserDetailFragmentDoc,
    ProjectDetailFragmentDoc,
    TaskDetailFragmentDoc,
    DocumentDetailFragmentDoc
  );

  if (process.env.NODE_ENV === 'test') {
    disableFragmentWarnings();
  }

  return new InMemoryCache({
    fragments: fragmentRegistry,
    possibleTypes: {
      Attachment: ['Task', 'Project', 'Document', 'Audit'],
    },
    typePolicies: {
      Document: {
        keyFields: ['clientId'],
      },
      DraftMessage: {
        keyFields: ['chatId'],
      },
      MessageReaction: {
        fields: {
          user: {
            merge(_, incoming) {
              return incoming;
            },
          },
        },
      },
      Message: {
        keyFields: ['clientId', 'authorId'],
        fields: {
          reaction: {
            merge: (
              _: { __ref: string }[] | undefined,
              incoming: { __ref: string }[] | undefined
            ) => {
              return incoming;
            },
          },
          attachments: {
            merge: (
              existing: { __ref: string }[] | undefined,
              incoming: { __ref: string }[] | undefined
            ) => {
              const filteredExisting = (existing || []).filter(
                (e) =>
                  !e?.__ref?.includes('MessageTask') &&
                  !e?.__ref?.includes('MessageProject')
              );
              return uniqBy(
                [...filteredExisting, ...(incoming || [])],
                '__ref'
              );
            },
          },
        },
      },
      Query: {
        fields: {
          listMessages: {
            ...relayStylePagination(),
            keyArgs: ['chatId', 'includeDraft', 'includePublished'],
            merge(existing, incoming) {
              const combineArrays = (
                existing: { node: { __ref: unknown } }[] = [],
                incoming: { node: { __ref: unknown } }[] = []
              ) => {
                // Create a new array that combines both arrays and removes duplicates based on ref
                const filtered = existing.filter(({ node }) => {
                  return !incoming.some((obj) => obj.node.__ref === node.__ref);
                });
                const result = [...incoming, ...filtered];

                return result;
              };
              return {
                ...incoming,
                edges: combineArrays(
                  existing?.edges || [],
                  incoming?.edges || []
                ),
              };
            },
          },
          listTasks: {
            ...relayStylePagination(),
            keyArgs: [
              'projectIds',
              'memberIds',
              'teamIds',
              'sortBy',
              'status',
              'userId',
              'dueGroup',
            ],
          },
          listProjects: {
            ...relayStylePagination(),
            keyArgs: [
              'filterTeamsProjects',
              'archivesOnly',
              'locationOnly',
              'chatId',
              'userId',
              'sortBy',
            ],
          },
          listActivitiesByTask: {
            ...relayStylePagination(),
            keyArgs: ['taskId'],
          },
          listActivitiesByProject: {
            ...relayStylePagination(),
            keyArgs: ['projectId'],
          },
          listRecentProjectsTasks: {
            keyArgs: ['id', 'chatId', 'excludeCompleted', 'collaboratorsOnly'],
          },
          listProjectsRecent: {
            keyArgs: ['id'],
          },
        },
      },
    },
  });
};
