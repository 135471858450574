import { StackActions } from '@react-navigation/native';
import debounce from 'lodash.debounce';
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList } from 'react-native';

import { WebModal } from '@components/Modals/WebModal';
import { Box, Text } from '@components/Restyle';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import SearchInput from '@components/shared/SearchInput/SearchInput';
import { TaskSearchResult } from '@components/Tasks/TaskSearchResult';
import { useSearchLazyQuery, SearchableResult } from '@graphql/generated';
import { useAppNavigation } from '@navigation/useAppNavigation';
import { Search, SearchResultType } from '@root/types';
import { isTaskSearchResult } from '@utils/typeGuards';

const DEBOUNCE = 500;
export const TaskCopySearch = () => {
  const navigation = useAppNavigation();
  const { t } = useTranslation();
  const [searchVal, setSearchVal] = useState('');
  const [loading, setLoading] = useState(false);

  const [globalSearch, { data }] = useSearchLazyQuery();
  const searchData = (data?.search as Search<SearchableResult>[]) || [];
  const initialResults = {
    messages: [],
    tasks: [],
    documents: [],
    projects: [],
  };

  const results =
    searchData.reduce<SearchResultType>((previous, result) => {
      if (isTaskSearchResult(result))
        return { ...previous, tasks: [...previous.tasks, result] };

      return previous;
    }, initialResults) || initialResults;

  const globalSearchCall = () =>
    globalSearch({
      variables: {
        term: searchVal,
        size: 20,
        includeMessages: false,
        includeDocuments: false,
        includeProjects: false,
        includeTasks: true,
      },
      onCompleted: () => setLoading(false),
      onError: () => setLoading(false),
    });

  const debouncedGlobalSearch = useCallback(
    debounce(globalSearchCall, DEBOUNCE),
    [searchVal]
  );

  useEffect(() => {
    if (searchVal) {
      setLoading(true);
      debouncedGlobalSearch();
    }

    return () => {
      debouncedGlobalSearch.cancel();
      setLoading(false);
    };
  }, [searchVal]);

  const taskListData = searchVal ? results.tasks.map((r) => r.record) : [];

  return (
    <WebModal
      visible
      title={t('shared:copy')}
      onClose={navigation.goBack}
      width={504}
      height={512}>
      <>
        <Box
          flexDirection='row'
          alignItems='center'
          marginTop='m'
          marginHorizontal='m'>
          <SearchInput
            onTextChanged={(newText) => setSearchVal(newText)}
            value={searchVal}
          />
        </Box>
        {searchVal ? (
          <>
            {loading ? (
              <ActivityIndicatorLoading />
            ) : (
              <FlatList
                ListEmptyComponent={
                  <Box mt='xs' marginHorizontal='m'>
                    <Text color='grey04' variant='body' textAlign='center'>
                      {t('models:tasks.copy.noResults')}
                    </Text>
                  </Box>
                }
                data={taskListData}
                renderItem={({ item }) => (
                  <Box paddingHorizontal='m'>
                    <TaskSearchResult
                      task={item}
                      searchValue={searchVal}
                      onPress={() => {
                        navigation.dispatch(
                          StackActions.replace('task-copy-select', {
                            taskId: item.id,
                          })
                        );
                      }}
                    />
                  </Box>
                )}
                ListFooterComponent={<Box mb='m' />}
                ListHeaderComponent={<Box mt='m' />}
              />
            )}
          </>
        ) : (
          <Text variant='body' color='grey04' textAlign='center' mt='m'>
            {t('models:tasks.copy.searchCta')}
          </Text>
        )}
      </>
    </WebModal>
  );
};
