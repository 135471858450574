import React from 'react';

import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import { Plan } from '@root/types';

type BenefitList = Pick<Plan, 'color' | 'benefits'>;

const BenefitList: React.FC<BenefitList> = ({ benefits, color }) => {
  return (
    <Box>
      {benefits.map((benefit, index) => (
        <Box
          key={index}
          flexDirection='row'
          alignItems='center'
          marginBottom={index !== benefits.length - 1 ? 'm' : undefined}>
          <Icon name='Check' color={color} variant='s' />
          <Text marginLeft='xs' variant='bodySecondary'>
            {benefit}
          </Text>
        </Box>
      ))}
    </Box>
  );
};

export default BenefitList;
