import {
  CommonActions,
  NavigationProp,
  useNavigation,
} from '@react-navigation/native';
import { Platform } from 'react-native';

import {
  useNavigateToChatDrawer,
  useWebDrawer,
} from '@components/Web/Drawer/WebDrawerContext';

import type { Project, Task, User } from '@graphql/generated';
import type { AppStackParamList as AppStackParamListNative } from '@navigation/app-stack';
import type { AppStackParamList as AppStackParamListWeb } from '@navigation/app-stack.web';
import type { DistinctUnion } from 'types';

type AppStackParamList = DistinctUnion<
  AppStackParamListNative,
  AppStackParamListWeb
>;

/**
 * Returns a navigation object that
 * @returns An object that allows navigation within the application
 */
export const useAppNavigation = <
  T extends NavigationProp<AppStackParamList> = NavigationProp<AppStackParamList>
>() => {
  const navigateToChatDrawer = useNavigateToChatDrawer();
  const navigation = useNavigation<T>();
  const { setIsTaskWebPanelOpen, setIdTaskDetailOpen } = useWebDrawer();

  const nav = {
    ...navigation,
    /**
     * Navigates to a specific project.
     * @param project The project to navigate
     */
    navigateToProject: (project: Pick<Project, 'id'>, resetStack?: boolean) => {
      // Project details overrides this value on initial load.
      // We want to only set a new selected project if we are
      // navigating to a new project.
      if (Platform.OS === 'web') {
        if (!resetStack) {
          navigation.navigate('projects-stack', {
            screen: 'details',
            params: { projectId: project.id },
          });
        } else {
          navigation.dispatch(
            CommonActions.reset({
              index: 0,
              routes: [
                {
                  name: 'projects-stack',
                  params: {
                    screen: 'details',
                    params: { projectId: project.id },
                  },
                },
              ],
            })
          );
        }
      } else {
        const routeParams: AppStackParamList['projects-stack'] = {
          projectId: project.id,
          screen: 'project-details',
          params: {
            projectId: project.id,
          },
        };
        if (!resetStack) {
          navigation.navigate('projects-stack', routeParams);
        } else {
          navigation.dispatch(
            CommonActions.reset({
              index: 0,
              routes: [{ ...routeParams, name: 'project-details' }],
            })
          );
        }
      }
    },

    /**
     * Navigates to a specific task.
     * @param task The task to navigate
     */
    navigateToTask: (
      task: Pick<Task, 'id' | 'projectId'>,
      resetStack?: boolean
    ) => {
      // Task details overrides this value on initial load.
      // We want to only set a new selected task if we are
      // navigating to a new task.

      if (Platform.OS === 'web') {
        setIdTaskDetailOpen(task.id);
        setIsTaskWebPanelOpen(true);
      } else {
        const routeParams: AppStackParamList['tasks-stack'] = {
          taskId: task.id,
          screen: 'task-detail',
          params: {
            taskId: task.id,
          },
        };
        if (!resetStack) {
          navigation.navigate('tasks-stack', routeParams);
        } else {
          navigation.dispatch(
            CommonActions.reset({
              index: 0,
              routes: [{ ...routeParams, name: 'task-detail' }],
            })
          );
        }
      }
    },

    /**
     * Navigates to a specific task within the context of a project.
     *
     * On native, this navigates directly to the task details.
     * @param task The task to navigate
     */
    navigateToProjectTask: (task: Pick<Task, 'id' | 'projectId'>) => {
      if (Platform.OS === 'web') {
        navigation.navigate('projects-stack', {
          screen: 'details',
          params: {
            projectId: task.projectId,
            screen: 'tasks',
            params: {
              projectId: task.projectId,
              taskId: task.id,
            },
          },
        });
      } else {
        nav.navigateToTask(task);
      }
    },

    /** Navigates to a screen in the My profile. */
    navigateToMyprofileSubscriptionDetail: (id: string) => {
      if (Platform.OS === 'web') {
        navigation.navigate('my-account', {
          screen: 'subscriptionPlanDetail',
          params: {
            teamId: id,
          },
        });
      }
    },

    /** Navigates to a screen in the chat drawer. */
    navigateToChatDrawer,

    navigateToContact: (contact: Pick<User, 'id'>) => {
      if (Platform.OS === 'web') {
        navigation.navigate('contacts-stack', {
          screen: 'contacts',
          params: {
            userId: contact.id,
          },
        });
      }
    },
    navigateToProjectFiles: (
      project: Pick<Project, 'id'>,
      resetStack?: boolean
    ) => {
      if (Platform.OS === 'web') {
        if (!resetStack) {
          navigation.navigate('files-stack', {
            screen: 'project-files',
            params: { projectId: project.id },
          });
        } else {
          navigation.dispatch(
            CommonActions.reset({
              index: 0,
              routes: [
                {
                  name: 'files-stack',
                  params: {
                    screen: 'project-files',
                    params: { projectId: project.id },
                  },
                },
              ],
            })
          );
        }
      } else {
        const routeParams: AppStackParamList['files-stack'] = {
          projectId: project.id,
          screen: 'project-files',
          params: {
            projectId: project.id,
          },
        };
        if (!resetStack) {
          navigation.navigate('files-stack', routeParams);
        } else {
          navigation.dispatch(
            CommonActions.reset({
              index: 0,
              routes: [{ ...routeParams, name: 'project-files' }],
            })
          );
        }
      }
    },
  };

  return nav;
};
