import React from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';

import { Box } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import Images from '@themes/images';
import theme from '@themes/theme';

interface ProjectIconGridProps {
  bodyWidth: number;
  numColumns: number;
  selectedIcon?: keyof typeof Images;
  list: (keyof typeof Images)[];
  onPress: (icon: keyof typeof Images) => void;
}

export const ProjectIconGrid: React.FC<ProjectIconGridProps> = ({
  bodyWidth,
  numColumns,
  selectedIcon,
  list,
  onPress,
}) => {
  return (
    <Box width={bodyWidth} alignContent='center' justifyContent='center'>
      <ScrollView showsVerticalScrollIndicator={false}>
        <Box flexDirection='row' flexWrap='wrap'>
          {list.map((item, index) => {
            return (
              <TouchableOpacity
                key={item}
                onPress={() => onPress && onPress(item)}>
                <Box
                  accessibilityLabel='project icon'
                  alignItems='center'
                  justifyContent='center'
                  style={{
                    width: 50,
                    marginRight:
                      (index + 1) % numColumns ? theme.spacing.xxs : 0,
                    marginTop: theme.spacing.xxs,
                    borderRadius: 4,
                    padding: 10,
                    backgroundColor:
                      selectedIcon === item ? theme.colors.grey02 : undefined,
                  }}>
                  <Icon name={item} variant='l' color='textPrimary' />
                </Box>
              </TouchableOpacity>
            );
          })}
        </Box>
      </ScrollView>
    </Box>
  );
};
