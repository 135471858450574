import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectModal } from '@components/FormModals/SelectModal';
import { useListUsersLazyQuery, User } from '@graphql/generated';
import useMe from '@hooks/useMe';
import useSelectedMembers from '@hooks/useSelectedMembers';
import useUsers from '@hooks/useUsers';
import { useAppNavigation } from '@navigation/useAppNavigation';

import { TagSelectType } from './SelectModal/SelectModalContent';

interface MemberSelectProps {
  title?: string;
  defaultRole?: string;
  ownerId?: string;
  showAvatarInSelected?: boolean;
  onClose?: () => void;
  onNext?: () => void;
  buttonLabel?: string;
  users?: User[];
  ignoreEmptyList?: boolean;
}

export const MemberSelect = ({
  title = 'Members',
  defaultRole,
  ownerId,
  showAvatarInSelected = false,
  onClose: onCloseProp,
  onNext,
  users: usersProp,
  buttonLabel,
  ignoreEmptyList = false,
}: MemberSelectProps) => {
  const { t } = useTranslation('models');
  const [list, setList] = useState<TagSelectType[]>(
    (usersProp as TagSelectType[]) || []
  );
  const { users: contacts } = useUsers();
  const { me } = useMe();
  const { setFieldValue, setTouched, touched, getFieldProps } =
    useFormikContext();
  const { setSelectedMembers } = useSelectedMembers();
  const { name: fieldName, value } = getFieldProps('users');
  const navigation = useAppNavigation();
  const [query, { data }] = useListUsersLazyQuery();

  const usersAndMe = usersProp
    ? [...list, ...contacts].filter((obj, index, self) => {
        return index === self.findIndex((o) => o.id === obj.id);
      })
    : contacts;

  const filteredUsers = usersAndMe.filter(
    (u) => u?.id !== ownerId && u?.id !== me?.id
  );

  const filteredValues = value.filter(
    (m: { id: string }) => m.id !== ownerId && m.id !== me?.id
  );
  const owner = value.find((v: { id: string }) => v.id === ownerId);
  const sortedFilteredUsers = filteredUsers.slice().sort((a, b) => {
    if (a?.name && b?.name) {
      return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1;
    }
    return 0;
  });
  const combinedDistinctUsers = [
    ...filteredValues,
    ...sortedFilteredUsers,
  ].filter((item, index, self) => {
    return self.findIndex((t) => t.id === item.id) === index;
  });

  const onClose = onCloseProp || navigation.goBack;

  const onSearch = (value: string) => {
    query({ variables: { term: value } });
  };

  useEffect(() => {
    if (!data) return;
    const { listUsers } = data;

    // const usersWithRoles = listUsers.map(u => ({ user: u, role: 'MEMBER' }))

    setList(listUsers as TagSelectType[]);
  }, [data]);

  return (
    <SelectModal
      title={title}
      list={combinedDistinctUsers}
      buttonLabel={buttonLabel}
      userList={showAvatarInSelected}
      showAvatar
      showIcon={false}
      showBackground={false}
      onClose={onClose}
      onNext={onNext}
      setSelected={(ids: any) => {
        const newUsers = [...(ids instanceof Array ? ids : [ids])].map((i) => {
          return {
            ...usersAndMe.find((u) => u.id === i),
            role:
              value.find((m: { id: string }) => m.id === i)?.role ||
              defaultRole,
          };
        });
        setTouched({ ...touched, [fieldName]: true });
        setFieldValue(fieldName, owner ? [owner, ...newUsers] : [...newUsers]);
        setSelectedMembers(owner ? [owner, ...newUsers] : [...newUsers]);
      }}
      value={filteredValues.map((u: { id: string }) => {
        if (!u) return;
        return u.id;
      })}
      emptyContentMessage={t('resourceNotFound.message', {
        resource: 'member',
        resourcePlural: 'members',
      })}
      ignoreEmptyList={ignoreEmptyList}
      onSearch={onSearch}
    />
  );
};
