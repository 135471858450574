import React from 'react';
import { TouchableOpacity } from 'react-native';

import { Box, Text } from '../../Restyle';
import Icon from '../Icon/Icon';

interface CheckSelectProps {
  label: string;
  isSelected?: boolean;
  onPress: () => void;
}

const CheckSelect: React.FC<CheckSelectProps> = ({
  label,
  isSelected,
  onPress,
}) => {
  return (
    <TouchableOpacity onPress={onPress} accessibilityLabel={label}>
      <Box
        flexDirection='row'
        justifyContent='space-between'
        alignItems='center'
        marginVertical='xs'>
        <Text variant='body' color='textPrimary'>
          {label}
        </Text>
        {isSelected && <Icon name='Check' color='greenSecondary' variant='l' />}
      </Box>
    </TouchableOpacity>
  );
};

export default CheckSelect;
