import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InferType } from 'yup';

import { Box, Text } from '@components/Restyle';
import { Tag } from '@components/shared/Tags/Tag';
import ToggleSwitch from '@components/Tasks/ToggleSwitch.web';
import { PopupSetNotificationButtonWeb } from '@components/Web/PopupSetNotificationButton.web';
import {
  GetMeDocument,
  NotificationSetting,
  NotificationSettingDuration,
  useUpdateNotificationSettingMutation,
} from '@graphql/generated';
import useMe from '@hooks/useMe';
import saveProfileSchema from '@schemas/saveProfileSchema';

export type SaveProfileFormValues = InferType<typeof saveProfileSchema>;

type NotificationSettingsType = {
  title: string;
  mute: boolean;
  durationStr: string;
  settingType: NotificationSetting;
  duration: NotificationSettingDuration;
};
export const MyAccountSettings: React.FC = () => {
  const { t } = useTranslation();
  const { me } = useMe();

  const getStrFromDuration = (duration: NotificationSettingDuration) => {
    switch (duration) {
      case NotificationSettingDuration.Week:
        return '1 week';
      case NotificationSettingDuration.Forever:
        return 'Forever';
      default:
        return '1 day';
    }
  };

  const noti = me?.notificationSetting
    ?.filter((t) => t.settingType === NotificationSetting.Chat)
    ?.at(0);

  const initailItems = [
    {
      title: 'Chat',
      settingType: NotificationSetting.Chat,
      mute: noti?.mute ?? false,
      duration: noti?.duration ?? NotificationSettingDuration.Day,
      durationStr: getStrFromDuration(
        noti?.duration ?? NotificationSettingDuration.Day
      ),
    } as NotificationSettingsType,
  ];

  const [items, setItems] = useState<NotificationSettingsType[]>(initailItems);

  const [updateNotificationSetting] = useUpdateNotificationSettingMutation({
    refetchQueries: [{ query: GetMeDocument }, 'getMe'],
    onError: (_error: any) => {
      console.log('Apollo Error, ', _error);
    },
  });

  const getDurationFromStr = (str: string) => {
    switch (str) {
      case '1 week':
        return NotificationSettingDuration.Week;
      case 'Forever':
        return NotificationSettingDuration.Forever;
      default:
        return NotificationSettingDuration.Day;
    }
  };

  useEffect(() => {
    setItems(initailItems);
  }, []);

  const notiWeeklyUpdate = me?.notificationSetting
    ?.filter((t) => t.settingType === NotificationSetting.WeeklyUpdate)
    ?.at(0);
  const notiDailyDigest = me?.notificationSetting
    ?.filter((t) => t.settingType === NotificationSetting.DailyDigest)
    ?.at(0);
  const [pauseWeeklyUpdate, setPauseWeeklyUpdate] = useState<boolean>(
    notiWeeklyUpdate ? notiWeeklyUpdate?.mute : false
  );
  const [pauseDailyDigest, setPauseDailyDigest] = useState<boolean>(
    notiDailyDigest ? notiDailyDigest?.mute : false
  );

  const getPauseBox = (pause: string) => {
    return (
      <Box
        flexDirection='row'
        justifyContent='flex-start'
        alignContent='center'
        my='xs'>
        <Box justifyContent='center' mr='m'>
          <ToggleSwitch
            onValueChange={async (v) => {
              pause === 'pauseWeeklyUpdate'
                ? setPauseWeeklyUpdate(v)
                : setPauseDailyDigest(v);

              await updateNotificationSetting({
                variables: {
                  attributes: [
                    {
                      settingType:
                        pause === 'pauseWeeklyUpdate'
                          ? NotificationSetting.WeeklyUpdate
                          : NotificationSetting.DailyDigest,
                      mute: v,
                      duration: NotificationSettingDuration.Forever,
                    },
                  ],
                },
                onCompleted: () => {
                  setItems([...items]);
                },
              });
            }}
            value={
              pause === 'pauseWeeklyUpdate'
                ? pauseWeeklyUpdate
                : pauseDailyDigest
            }
          />
        </Box>
        <Box justifyContent='center' width={216}>
          <Text variant='labelSmall' color='textPrimary'>
            {t('models:profile.settings.notifications.' + pause)}
          </Text>
        </Box>
      </Box>
    );
  };

  return (
    <Box alignItems='center'>
      <Box width={602}>
        <Box style={{ marginTop: 16 }}>
          <Box>
            <Text variant='heading2' color='black'>
              {t('models:profile.settings.notifications.title')}
            </Text>
          </Box>
          {items.map((item) => {
            return (
              <Box
                key={item.title}
                flexDirection='row'
                justifyContent='flex-start'
                alignContent='center'
                my='xs'
                mt='m'>
                <Box justifyContent='center' mr='m'>
                  <ToggleSwitch
                    onValueChange={async (v) => {
                      item.mute = v;

                      await updateNotificationSetting({
                        variables: {
                          attributes: [
                            {
                              settingType: item.settingType,
                              mute: v,
                              duration: item.duration,
                            },
                          ],
                        },
                        onCompleted: () => {
                          setItems([...items]);
                        },
                      });
                    }}
                    value={item.mute}
                  />
                </Box>
                <Box justifyContent='center' width={216}>
                  <Text variant='labelSmall' color='textPrimary'>
                    {t(
                      'models:profile.settings.notifications.pausePushNotification'
                    )}
                  </Text>
                  <Text variant='metadata' color='textSecondary' marginTop='xs'>
                    {t(
                      'models:profile.settings.notifications.sendRelatedNotifications',
                      { title: item.title }
                    )}
                  </Text>
                </Box>
                {item.mute && (
                  <Box justifyContent='center' ml='xs'>
                    <Text
                      variant='labelSmall'
                      color='textPrimary'
                      marginBottom='xs'>
                      {t('models:profile.settings.notifications.pauseFor')}
                    </Text>
                    <PopupSetNotificationButtonWeb
                      title={item.durationStr}
                      setPauseFor={async (durationStr: string) => {
                        const duration = getDurationFromStr(durationStr);
                        const settingType = item.settingType;

                        item.duration = duration;
                        item.durationStr = durationStr;
                        setItems([...items]);
                        await updateNotificationSetting({
                          variables: {
                            attributes: [
                              {
                                settingType,
                                mute: item.mute,
                                duration,
                              },
                            ],
                          },
                        });
                      }}
                      boxProps={{
                        position: 'right',
                        offset: [0, -56],
                      }}
                      width={140}
                      maxHeight={150}
                    />
                  </Box>
                )}
              </Box>
            );
          })}
          {getPauseBox('pauseWeeklyUpdate')}
          {getPauseBox('pauseDailyDigest')}
        </Box>
        <Box mt='xl'>
          <Box>
            <Text variant='heading2' color='black'>
              {t('models:profile.settings.preferences.title')}
            </Text>
          </Box>
          <Box
            flex={1}
            flexDirection='row'
            justifyContent='flex-start'
            alignContent='center'
            paddingVertical='xs'>
            <Box justifyContent='center' mr='m'>
              <ToggleSwitch onValueChange={() => {}} value={false} />
            </Box>
            <Box justifyContent='center' width={216}>
              <Text variant='labelSmall' color='textPrimary'>
                {t('models:profile.settings.preferences.searchVisibility')}
              </Text>
              <Text variant='metadata' color='textSecondary' marginTop='xs'>
                {t('models:profile.settings.preferences.setTeamToPrivate')}
              </Text>
            </Box>
            <Box justifyContent='flex-start' ml='xs' my='xs'>
              <Tag
                label={t('shared:comingSoon')}
                labelVariant='metadata'
                variant='secondary'
                padding='xxs'
                borderRadius='xxs'
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
