import React from 'react';
import Highlighter from 'react-native-highlight-words';

import Avatar from '@components/Avatar/Avatar';
import { Box, Text } from '@components/Restyle';
import { User } from '@graphql/generated';
import theme from '@themes/theme';
interface MemberProps {
  member: User;
  highlightedValue?: string;
}

export const Member: React.FC<MemberProps> = ({
  member,
  highlightedValue = '',
}) => {
  const { id, name, avatar } = member;

  return (
    <Box flexDirection='row' alignItems='center'>
      <Avatar id={id} size='medium' avatar={avatar} label={name} />
      <Text
        color='textPrimary'
        variant='labelEmphasized'
        style={{ marginLeft: theme.spacing.s }}>
        <Highlighter
          autoEscape
          highlightStyle={{
            color: theme.colors.greenSecondary,
            backgroundColor: theme.colors.background,
          }}
          searchWords={highlightedValue?.split(' ') || ''}
          textToHighlight={name || ''}
        />
      </Text>
    </Box>
  );
};
