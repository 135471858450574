import React, { useMemo } from 'react';
import { Platform, useWindowDimensions } from 'react-native';

import Icon from '@components/shared/Icon/Icon';

import { Box, Text } from '../Restyle';

const EmptyStateArchivedProjects: React.FC = () => {
  const { height, width } = useWindowDimensions();

  const [EMPTY_ICON_WIDTH, EMPTY_ICON_HEIGHT] = useMemo(() => {
    return width > 375 ? [width / 2, height / 7] : [width / 8, height / 5.5];
  }, [width, height]);

  return (
    <Box
      flex={height > 700 ? 1 : undefined}
      padding='xs'
      justifyContent='center'
      alignItems='center'>
      <Box
        justifyContent='center'
        alignItems='center'
        flexDirection='column'
        marginVertical='m'>
        <Icon
          name={Platform.OS === 'web' ? 'NoProjects' : 'NoProjectsMob'}
          color='white'
          width={Platform.OS === 'web' ? 132 : EMPTY_ICON_WIDTH}
          height={Platform.OS === 'web' ? 118 : EMPTY_ICON_HEIGHT}
        />

        <Text
          variant='body'
          marginTop='l'
          textAlign='center'
          color='onSurfaceSecondary'>
          This Project is Archived
        </Text>
      </Box>
    </Box>
  );
};

export default EmptyStateArchivedProjects;
