import React, { useMemo } from 'react';
import { ColorValue, StyleSheet } from 'react-native';

import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/RestyleIcon';
import images from '@themes/images';
import theme from '@themes/theme';

interface ProjectAvatarProps {
  absolute?: { top?: number; bottom?: number; right?: number; left?: number };
  archived?: boolean;
  color: ColorValue;
  icon?: keyof typeof images;
  initials: string;
  personal?: boolean;
  hideInitial?: boolean;
  dimensions: { width: number; height: number };
}

export const ProjectAvatar: React.FC<ProjectAvatarProps> = ({
  absolute,
  archived,
  color,
  icon,
  initials,
  personal,
  hideInitial,
  dimensions = { width: 48, height: 48 },
}) => {
  const backgroundColor = useMemo(
    () => (archived ? theme.colors.grey04 : color),
    [archived, color]
  );

  const iconName = icon ?? 'BuildingHomeHouse';

  return (
    <Box
      {...dimensions}
      position={absolute ? 'absolute' : undefined}
      {...absolute}
      borderRadius='xs'
      alignItems='center'
      justifyContent='center'
      style={[styles.avatar, { backgroundColor }]}>
      {hideInitial ? (
        <Icon name={iconName} variant='l' color='white' />
      ) : (
        <Text variant='labelLarge' color='white'>
          {initials}
        </Text>
      )}
      {!hideInitial && (
        <Box
          position='absolute'
          bottom={0}
          right={0}
          width={14}
          height={14}
          backgroundColor='black'
          borderRadius='xxxs'
          alignItems='center'
          justifyContent='center'>
          {personal ? (
            <Icon name='Office' variant='xxxs' color='white' />
          ) : (
            <Icon name='TT' variant='xs' />
          )}
        </Box>
      )}
    </Box>
  );
};

const styles = StyleSheet.create({
  avatar: {
    borderRadius: theme.borderRadii.xxs,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default ProjectAvatar;
