import dayjs from 'dayjs';
import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dimensions,
  StyleSheet,
  FlatList,
  TouchableOpacity,
  PanResponder,
} from 'react-native';
import Carousel from 'react-native-snap-carousel';

import Avatar from '@components/Avatar/Avatar';
import CachedImage from '@components/CachedImage/CachedImage';
import TagsCollection from '@components/Chat/TagsCollection.web';
import { MediaShareModal } from '@components/Modals/MediaShareModal.web';
import { WebModal } from '@components/Modals/WebModal.web';
import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import { Document } from '@graphql/generated';
import { usePreviewDocuments } from '@hooks/usePreviewDocuments';
import theme from '@themes/theme';
import { generateTagCollections } from '@utils/tagCollectionService';

export const ImagesPreview: React.FC<{
  isGlobalSearch?: boolean;
  onDismiss?: () => void;
}> = ({ isGlobalSearch = false, onDismiss }) => {
  const {
    activeIndex,
    setActiveIndex,
    previewDocuments = [],
    tagsCollections,
    isViewer,
  } = usePreviewDocuments();
  const screenWidth = Dimensions.get('screen').width;
  const carouselRef = useRef<Carousel<Document>>(null);

  const getTags = (idx: number) => {
    if (tagsCollections.length !== 0) return tagsCollections.flat();
    const item: Document = previewDocuments[idx];
    const list = [];
    item.projectDocuments?.forEach((pd, index2) => {
      if (!list.some((x) => x.projectId === pd.project?.id)) {
        list.push({
          __typename: 'MessageProject',
          id: `${pd.project?.id}-${index2}`,
          projectId: pd.project?.id,
          authorId: item.owner.id,
          author: item.owner,
          project: pd.project,
        });
      }
    });
    item.taskDocuments?.forEach((td, index2) => {
      if (
        !list.some(
          (x) => x.projectId === td.task?.projectId && x.taskId === td.task?.id
        )
      ) {
        list.push({
          __typename: 'MessageTask',
          id: `${td.task?.id}-${index2}`,
          authorId: item.owner.id,
          author: item.owner,
          projectId: td.task?.projectId,
          taskId: td.task?.id,
          task: td.task,
        });
      }
    });
    return generateTagCollections(list).flat();
  };
  const [tags, setTags] = useState(getTags(activeIndex));
  const [imgPercentage, setImgPercentage] = useState(100);
  const MAX_PERCENTAGE = 200;
  const MIN_PERCENTAGE = 10;

  document.addEventListener('keydown', function (event) {
    if (activeIndex < previewDocuments.length && event.keyCode === 39) {
      carouselRef.current?.snapToNext();
    } else if (activeIndex > 0 && event.keyCode === 37) {
      carouselRef.current?.snapToPrev();
    }
  });

  const { t: formatTime } = useTranslation('format');

  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const panResponder = useRef(
    PanResponder.create({
      onStartShouldSetPanResponder: () => true,
      onPanResponderMove: (_evt, gestureState) => {
        setOffset({
          x: offset.x + gestureState.dx,
          y: offset.y + gestureState.dy,
        });
      },
      onPanResponderRelease: (_evt, gestureState) => {
        setOffset({
          x: offset.x + gestureState.dx,
          y: offset.y + gestureState.dy,
        });
      },
    })
  ).current;

  const downloadImage = async () => {
    try {
      const url = previewDocuments[activeIndex].file.url;
      fetch(url)
        .then((resp) => resp.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = previewDocuments[activeIndex].name;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch(() => alert('An error happened.'));
    } finally {
      // setLoaderDisplay(false);
    }
  };

  useEffect(() => {
    return () => setTags([]);
  }, []);

  const carouselItem = (item: Document) => {
    const { file } = item;

    if (item.contentType?.includes('video/')) {
      return (
        <video
          controls
          style={{ width: screenWidth, height: window.innerHeight - 293 }}
          src={file.url}
          onError={(e) => console.error('Error loading video', e)}
        />
      );
    } else {
      return (
        <Box {...panResponder.panHandlers}>
          <CachedImage
            image={file}
            height={window.innerHeight - 293}
            screenWidth={screenWidth}
            style={{
              transform: [
                { scale: imgPercentage / 100 },
                { translateX: imgPercentage > 100 ? offset.x : 0 },
                { translateY: imgPercentage > 100 ? offset.y : 0 },
              ],
            }}
            resizeMode='contain'
          />
        </Box>
      );
    }
  };

  return (
    <WebModal
      accessibilityLabel='Preview'
      visible
      width={window.innerWidth}
      height={window.innerHeight}
      isFullScreen={true}>
      <>
        <Box
          style={{ height: 79 }}
          flexDirection='row'
          justifyContent='space-between'
          alignItems='center'
          backgroundColor='grey05'
          paddingHorizontal='m'>
          <Box
            justifyContent='flex-start'
            alignItems='center'
            flexDirection='row'
            style={{ height: 43 }}>
            <Icon
              name='ChevronLeft'
              variant='m'
              color='white'
              onPress={onDismiss}
            />
            <Avatar
              avatar={previewDocuments[activeIndex].owner.avatar}
              size='small'
              marginLeft={theme.spacing.xs}
            />
            <Box style={{ height: 43 }} marginLeft='xs'>
              <Text variant='labelEmphasized' color='white' marginBottom='xxxs'>
                {previewDocuments[activeIndex].owner.name}
              </Text>
              <Text variant='metadataSecondary' color='grey02'>
                {formatTime('timeAgoMessageList', {
                  val: previewDocuments[activeIndex].createdAt,
                }) +
                  ' at ' +
                  dayjs(previewDocuments[activeIndex].createdAt).format(
                    'hh:mm A'
                  )}
              </Text>
            </Box>
          </Box>

          <Box justifyContent='center'>
            <Text variant='labelEmphasized' color='white'>
              {previewDocuments[activeIndex].name}
            </Text>
          </Box>

          <Box
            justifyContent='flex-end'
            alignItems='center'
            flexDirection='row'
            style={{ height: 40 }}>
            {!isViewer && (
              <Icon
                name='Download'
                variant='m'
                color='white'
                style={{ marginRight: 20 }}
                onPress={downloadImage}
              />
            )}
            {!previewDocuments[activeIndex].contentType?.includes('video') && (
              <>
                <Icon
                  name='ZoomOut'
                  variant='m'
                  color={imgPercentage == MIN_PERCENTAGE ? 'grey04' : 'white'}
                  style={{ marginRight: 20 }}
                  onPress={() => {
                    const newPercentage = imgPercentage - 10;
                    if (newPercentage >= MIN_PERCENTAGE) {
                      setImgPercentage(newPercentage);
                      setOffset({ x: 0, y: 0 });
                    }
                  }}
                />
                <Icon
                  name='ZoomIn'
                  variant='m'
                  color={imgPercentage == MAX_PERCENTAGE ? 'grey04' : 'white'}
                  style={{ marginRight: 20 }}
                  onPress={() => {
                    const newPercentage = imgPercentage + 10;
                    if (newPercentage <= MAX_PERCENTAGE) {
                      setImgPercentage(newPercentage);
                      setOffset({ x: 0, y: 0 });
                    }
                  }}
                />
                <Text
                  variant='labelSmall'
                  color='white'
                  style={{ marginRight: 20, minWidth: 40 }}>
                  {imgPercentage}%
                </Text>
              </>
            )}
            {!isViewer && <MediaShareModal isGlobalSearch={isGlobalSearch} />}
          </Box>
        </Box>
        <Box flex={1} backgroundColor='black'>
          <Box flex={1} justifyContent='center'>
            <Box justifyContent='center' alignItems='center'>
              <Carousel
                data={previewDocuments}
                renderItem={({ item }: { item: Document }) => {
                  return carouselItem(item);
                }}
                itemWidth={screenWidth * 2}
                sliderWidth={screenWidth}
                itemHeight={screenWidth}
                sliderHeight={screenWidth}
                onBeforeSnapToItem={() => {
                  setImgPercentage(100);
                  setOffset({ x: 0, y: 0 });
                }}
                onSnapToItem={(index) => {
                  setActiveIndex(index);
                  setTags(getTags(index));
                }}
                firstItem={activeIndex}
                initialNumToRender={previewDocuments.length}
                ref={carouselRef}
                scrollEnabled={previewDocuments.length > 1}
              />

              {previewDocuments.length > 1 && (
                <>
                  {activeIndex !== 0 && (
                    <Box style={[styles.arrow, styles.leftArrow]}>
                      <Icon
                        color='white'
                        style={{ width: 42, height: 42 }}
                        name='ArrowLeftCircle'
                        onPress={() => carouselRef.current?.snapToPrev()}
                      />
                    </Box>
                  )}
                  {activeIndex !== previewDocuments.length - 1 && (
                    <Box style={[styles.arrow, styles.rightArrow]}>
                      <Icon
                        color='white'
                        style={{ width: 42, height: 42 }}
                        name='ArrowRightCircle'
                        onPress={() => carouselRef.current?.snapToNext()}
                      />
                    </Box>
                  )}
                </>
              )}
            </Box>
          </Box>
          <Box justifyContent='center' alignItems='center'>
            <Box
              style={{
                height: 62,
                marginBottom: 42,
                width:
                  previewDocuments.length * 36 +
                  (previewDocuments.length + 1) * 8,
                backgroundColor: '#222222',
              }}
              borderRadius='xxs'
              flexDirection='row'
              justifyContent='space-between'
              paddingHorizontal='xs'
              paddingVertical='xs'>
              {previewDocuments.map((item, index) => {
                return activeIndex !== index ? (
                  <Box
                    width='xl'
                    height='xxl'
                    justifyContent='center'
                    alignItems='center'
                    pointerEvents='auto'>
                    <TouchableOpacity
                      onPress={() => {
                        carouselRef.current?.snapToItem(index);
                      }}>
                      <CachedImage
                        image={item.file}
                        width={theme.spacing.xl}
                        height={theme.spacing.xxl}
                        contentType={item.contentType}
                      />
                    </TouchableOpacity>
                  </Box>
                ) : (
                  <Box
                    width='xl'
                    height='xxl'
                    justifyContent='center'
                    alignItems='center'
                    pointerEvents='auto'>
                    <TouchableOpacity
                      onPress={() => {
                        carouselRef.current?.snapToItem(index);
                      }}>
                      <CachedImage
                        style={{
                          borderColor: theme.colors.greenSecondary,
                          borderWidth: theme.spacing.xxxs,
                        }}
                        image={item.file}
                        width={theme.spacing.xl}
                        contentType={item.contentType}
                        height={theme.spacing.xxl}
                      />
                    </TouchableOpacity>
                  </Box>
                );
              })}
            </Box>
          </Box>
          <Box
            backgroundColor='grey05'
            justifyContent='center'
            alignItems='center'
            style={{
              paddingBottom: 0,
              height: 54,
            }}>
            {tags.length > 0 && (
              <FlatList
                horizontal
                style={{
                  position: 'absolute',
                  top: 1,
                  backgroundColor: theme.colors.grey05,
                  paddingVertical: theme.spacing.xxs,
                }}
                data={tags}
                renderItem={({ item, index }) => (
                  <Box key={index}>
                    <TagsCollection
                      fromImagesPreview={true}
                      tagsCollection={item}
                      showAuthor={false}
                      borderless
                      isRow
                    />
                  </Box>
                )}
                ItemSeparatorComponent={() => <Box marginRight='xxs' />}
                ListHeaderComponent={() => <Box marginLeft='m' />}
                ListFooterComponent={() => <Box marginLeft='m' />}
              />
            )}
          </Box>
        </Box>
      </>
    </WebModal>
  );
};

const styles = StyleSheet.create({
  arrow: {
    position: 'absolute',
    top: `50%`,
    zIndex: 99,
  },
  rightArrow: {
    right: theme.spacing.m,
  },
  leftArrow: {
    left: theme.spacing.m,
  },
});
