import { useNavigation } from '@react-navigation/native';
import React from 'react';

import Modal from './Modal';

const CreateJoinTeamModal: React.FC = () => {
  const navigation = useNavigation();

  return (
    <Modal
      items={[
        {
          title: 'Join a team',
          iconName: 'Office',
          comingSoon: true,

          onSelect: () => {},
        },
        {
          title: 'Create a team ',
          iconName: 'Plus',
          onSelect: () => {
            navigation.goBack();
            navigation.navigate('create-team-stack');
            // navigation.navigate('payments-stack', { screen: 'pick-plans' });
          },
        },
      ]}
    />
  );
};

export default CreateJoinTeamModal;
