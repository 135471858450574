import { useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FlatList,
  Platform,
  StyleSheet,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import { PanGestureHandler } from 'react-native-gesture-handler';
// eslint-disable-next-line import/default
import Animated, {
  runOnJS,
  useAnimatedGestureHandler,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { snapPoint } from 'react-native-redash';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { AvatarProps } from '@components/Avatar/Avatar';
import MemberListSummary from '@components/MemberListSummary/MemberListSummary';
import { Box, ShadowBox, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import { Tag } from '@components/shared/Tags/Tag';
import { User } from '@graphql/generated';
import useChatInput from '@hooks/useChatInput';
import Images from '@themes/images';
import theme from '@themes/theme';

export type ModalItemType = {
  title: string;
  iconName?: keyof typeof Images;
  label?: string;
  onSelect: () => void;
  color?: keyof typeof theme.colors | undefined;
  titleColor?: keyof typeof theme.colors | undefined;
  comingSoon?: boolean;
  accessibilityLabel?: string;
  trailingIconName?: keyof typeof Images;
  users?: User[];
};

interface ModalProps {
  items?: ModalItemType[];
  goBack?: () => void;
  gestureDisabled?: boolean;
  paddingDisabled?: boolean;
  children?: JSX.Element;
  showHeader?: boolean;
  headerTitle?: string;
  headerSaveDisable?: boolean;
  onSave?: () => void;
  cancelLabel?: string;
  showSaveButton?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  items,
  goBack,
  children,
  gestureDisabled,
  paddingDisabled,
  showHeader,
  headerTitle,
  onSave,
  headerSaveDisable,
  showSaveButton = true,
  cancelLabel,
}) => {
  const insets = useSafeAreaInsets();
  const navigation = useNavigation();
  const { t } = useTranslation();

  const [height, setHeight] = useState<number>(0);
  const { setModalHeight } = useChatInput();

  const translateY = useSharedValue(0);

  const onGestureEvent = useAnimatedGestureHandler({
    onActive: ({ translationY }) => {
      if (translationY < 0) {
        // swiping up, so reduce the Y position match
        translateY.value = translationY / 10;
      } else {
        // swiping down, exact Y match
        translateY.value = translationY;
      }
    },
    onEnd: ({ translationY, velocityY }) => {
      const goBack = snapPoint(translationY, velocityY, [0, height]) === height;
      if (goBack) {
        runOnJS(navigation.pop)();
      } else {
        translateY.value = withTiming(0);
      }
    },
  });

  const style = useAnimatedStyle(() => {
    return {
      transform: [{ translateY: translateY.value }],
    };
  });

  const userAvatars = (users: User[]): AvatarProps[] => {
    return (
      users?.map((user) => {
        const { name, id, avatar } = user;
        return {
          id: id,
          label: name,
          avatar: avatar,
          size: 'small',
        };
      }) || []
    );
  };

  const paddingTop = insets.top < 45 ? 45 : insets.top;

  return (
    <Box
      style={{
        position: `absolute`,
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
        justifyContent: 'flex-end',
        backgroundColor: 'transparent',
        paddingTop,
      }}>
      <TouchableWithoutFeedback
        onPress={() => (goBack ? goBack() : navigation.goBack())}>
        <View
          style={{
            flex: 1,
            zIndex: 99,
            marginBottom: -10,
          }}></View>
      </TouchableWithoutFeedback>

      <PanGestureHandler
        onGestureEvent={onGestureEvent}
        enabled={!gestureDisabled}>
        <Animated.View style={style}>
          <Box style={styles.belowModalWhiteSpace} />
          <ShadowBox
            variant='dark'
            borderTopLeftRadius='l'
            borderTopRightRadius='l'
            backgroundColor='white'
            paddingTop='m'
            paddingHorizontal={paddingDisabled ? undefined : 'm'}
            paddingVertical='l'
            onLayout={(event) => {
              const { height } = event.nativeEvent.layout;
              setHeight(height);
              setModalHeight(height);
            }}>
            <Box style={{ paddingBottom: insets.bottom }}>
              {Platform.OS !== 'web' && (
                <TouchableOpacity
                  onPress={() => (goBack ? goBack() : navigation.goBack())}>
                  <Box
                    backgroundColor='grey03'
                    height={4}
                    width={56}
                    borderRadius='xxxs'
                    alignItems='center'
                    alignSelf='center'
                    marginBottom='xs'
                  />
                </TouchableOpacity>
              )}
              {showHeader && (
                <Box
                  flexDirection='row'
                  marginTop='m'
                  marginBottom='xs'
                  justifyContent='space-between'>
                  <Box style={styles.back}>
                    <TouchableOpacity
                      onPress={() => (goBack ? goBack() : navigation.goBack())}>
                      <Text variant='buttonLabel' color='greenSecondary'>
                        {cancelLabel ? cancelLabel : t('shared:cancel')}
                      </Text>
                    </TouchableOpacity>
                  </Box>

                  <Box flex={1}>
                    <Text variant='labelEmphasized' textAlign='center'>
                      {headerTitle}
                    </Text>
                  </Box>

                  {showSaveButton && (
                    <Box style={styles.save}>
                      <TouchableOpacity
                        disabled={headerSaveDisable ?? false}
                        onPress={onSave}>
                        {!(headerSaveDisable ?? false) ? (
                          <Text color='greenSecondary'>
                            {t('models:chat.editTags.done')}
                          </Text>
                        ) : (
                          <Text color='grey04'>
                            {t('models:chat.editTags.done')}
                          </Text>
                        )}
                      </TouchableOpacity>
                    </Box>
                  )}
                </Box>
              )}
              <>
                {items && (
                  <FlatList
                    data={items}
                    scrollEnabled={false}
                    renderItem={({ item, index }) => (
                      <TouchableOpacity
                        key={index}
                        onPress={() => {
                          if (!showHeader && !item.onSelect) {
                            navigation.goBack();
                            return;
                          }
                          item.onSelect();
                        }}
                        accessibilityLabel={
                          item.accessibilityLabel
                            ? item.accessibilityLabel
                            : item.title
                        }>
                        <Box
                          flexDirection='row'
                          marginTop='l'
                          alignItems='center'>
                          <Box flex={1} flexDirection='row'>
                            <Box flex={1} flexDirection='row'>
                              {item.iconName && (
                                <Icon
                                  name={item.iconName}
                                  variant='l'
                                  marginRight='m'
                                  color={item.color || 'textPrimary'}
                                />
                              )}
                              <Text
                                variant='body'
                                color={item.color || 'textPrimary'}>
                                {item.title}
                              </Text>
                            </Box>
                            {item.label && (
                              <Text
                                variant='metadata'
                                color='textSecondary'
                                textTransform='capitalize'>
                                {item.label}
                              </Text>
                            )}
                            {item.comingSoon && (
                              <Tag
                                label={t('shared:comingSoon')}
                                labelVariant='metadata'
                                variant='secondary'
                                padding='xxs'
                                borderRadius='xxs'
                              />
                            )}
                            {item.trailingIconName && (
                              <Icon
                                name='Check'
                                color='greenBrand'
                                variant='l'
                              />
                            )}
                            {item.users && (
                              <Box flexDirection='row'>
                                <MemberListSummary
                                  isReadBy
                                  avatars={userAvatars(item.users)}
                                  onPress={() =>
                                    navigation.navigate(
                                      'chat-message-read-by-modal'
                                    )
                                  }
                                  maxAvatars={3}
                                />
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </TouchableOpacity>
                    )}
                  />
                )}
              </>
              <Box style={[{ maxHeight: `100%` }]}>{children}</Box>
            </Box>
          </ShadowBox>
        </Animated.View>
      </PanGestureHandler>
    </Box>
  );
};

const styles = StyleSheet.create({
  belowModalWhiteSpace: {
    ...StyleSheet.absoluteFillObject,
    top: `100%`,
    height: theme.spacing.listFooter,
    backgroundColor: theme.colors.white,
    marginTop: -theme.spacing.xs,
    zIndex: 2,
  },
  back: {
    zIndex: 99,
    position: 'absolute',
    left: 0,
  },
  save: {
    zIndex: 99,
    position: 'absolute',
    right: 0,
  },
  avatarContainer: {
    width: 56,
    height: 56,
    borderRadius: 28,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'black',
  },
});

export default Modal;
