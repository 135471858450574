import React from 'react';
import {
  FlatListProps,
  Platform,
  Pressable,
  RefreshControl,
} from 'react-native';
import { FlatList } from 'react-native-gesture-handler';

import { ProjectTag } from '@components/Chat/ProjectTag';
import RadioButton from '@components/RadioButton/RadioButton';
import { Box } from '@components/Restyle/index';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import { Project } from '@graphql/generated';
import { ProjectTagType } from '@src/types/project';
import theme from '@themes/theme';

interface ProjectTagListProps {
  filterVal?: string;
  projectTags: ProjectTagType[];
  onSelect: (item: ProjectTagType) => void;
  onRefresh?: () => void;
  /** Controls loading indicator when fetching additional projects */
  refreshing?: boolean;
  fetchMore?: () => void;
  listEmptyComponent?: JSX.Element;
  loading?: boolean;
  onPressDisable?: boolean;
  showsVerticalScrollIndicator?: boolean;
  iconColor?: keyof typeof theme.colors;
  itemSeparatorComponent?: FlatListProps<ProjectTagType>['ItemSeparatorComponent'];
  isSelectable?: boolean;
  selectedIds?: Project['id'][];
  isFrom?: string;
  isMember?: boolean;
}

export const ProjectTagList = ({
  filterVal,
  projectTags,
  onSelect,
  onRefresh,
  fetchMore,
  refreshing = false,
  listEmptyComponent,
  loading,
  onPressDisable,
  showsVerticalScrollIndicator = true,
  iconColor,
  itemSeparatorComponent,
  isSelectable,
  selectedIds,
  isFrom,
  isMember,
}: ProjectTagListProps) => {
  const renderItem = ({ item }: ListRenderItemInfo<ProjectTagType>) => {
    return (
      <Pressable
        onPress={() => !onPressDisable && onSelect(item)}
        accessibilityLabel={item.name}>
        <Box flexDirection='row'>
          {isSelectable && (
            <Box mr='xs' alignItems='center' alignSelf='center'>
              <RadioButton
                isSelected={!!selectedIds?.some((id) => item.id === id)}
                iconVariant='m'
                onPress={() => onSelect && onSelect(item)}
              />
            </Box>
          )}
          <Box flex={1} pointerEvents={isSelectable ? 'none' : 'auto'}>
            <ProjectTag
              project={{ ...item }}
              filterVal={filterVal}
              iconColor={iconColor}
              isFrom={isFrom}
              isMember={isMember}
            />
          </Box>
        </Box>
      </Pressable>
    );
  };
  const renderItemSeparatorComponent = () => (
    <Box height={1} backgroundColor='grey01' marginTop='m' marginBottom='s' />
  );

  if (loading) return <ActivityIndicatorLoading />;
  return (
    <FlatList
      showsVerticalScrollIndicator={showsVerticalScrollIndicator}
      ListEmptyComponent={listEmptyComponent}
      keyboardDismissMode='on-drag'
      keyboardShouldPersistTaps='always'
      data={projectTags}
      style={{
        paddingHorizontal:
          Platform.OS === 'web' ? theme.spacing.xs : theme.spacing.m,
      }}
      renderItem={renderItem}
      ItemSeparatorComponent={
        itemSeparatorComponent || renderItemSeparatorComponent
      }
      ListFooterComponent={() => <Box paddingBottom='xs' />}
      ListHeaderComponent={() => <Box height={theme.spacing.m} />}
      refreshing={refreshing}
      refreshControl={
        <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
      }
      onEndReached={fetchMore}
    />
  );
};
