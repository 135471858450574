import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, useWindowDimensions } from 'react-native';
import Highlighter from 'react-native-highlight-words';

import CachedImage from '@components/CachedImage/CachedImage';
import PlayCircle from '@components/Icons/PlayCircle';
import RadioButton from '@components/RadioButton/RadioButton';
import { Box, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import { Tag } from '@components/shared/Tags/Tag';
import { Document, LocalFile } from '@graphql/generated';
import theme from '@themes/theme';
import { formatSize } from '@utils//fileUtils';

interface FileCardInterface {
  document: Document;
  onPress?: (document: Document | LocalFile) => void;
  filterVal?: string;
  onMorePress?: () => void;
  showTags?: boolean;
  onLongPress?: (image: Document | LocalFile) => void;
  isSelectMode?: boolean;
  selectedList?: Array<Document | LocalFile>;
}

const FileCard: React.FC<FileCardInterface> = ({
  document,
  filterVal = '',
  onPress,
  onMorePress,
  showTags = true,
  onLongPress,
  isSelectMode = false,
  selectedList,
}) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const { name, createdAt, size = 2, isImage, contentType, isVideo } = document;
  const projectDocuments = document?.projectDocuments;
  const messageDocuments = document?.messageDocuments;
  const isSelected = selectedList?.find((item2) => {
    return item2.id == document.id;
  });
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredTitle, setIsHoveredTitle] = useState(false);

  return (
    <TouchableOpacity
      onPress={() => onPress && onPress(document)}
      onLongPress={() => onLongPress && onLongPress(document)}>
      <Box
        flexDirection='row'
        alignItems='center'
        paddingVertical='xs'
        paddingHorizontal='l'
        backgroundColor={isHovered ? 'grey01' : undefined}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}>
        {isSelectMode && (
          <Box pr='xs'>
            <RadioButton
              iconVariant='m'
              isSelected={!!isSelected}
              onPress={() => onPress && onPress(document)}
            />
          </Box>
        )}
        {isImage ? (
          <Box style={{ position: 'relative', width: 60, height: 48 }}>
            <CachedImage
              image={document.file}
              width={60}
              height={48}
              borderRadius={theme.spacing.xs}
              contentType={contentType}
            />
            {isVideo && (
              <PlayCircle
                iconSize={20}
                containerStyle={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              />
            )}
          </Box>
        ) : (
          <Icon
            fileType={document.contentType || undefined}
            color='blue'
            variant='l'
            width={36}
            height={36}
            paddingHorizontal='s'
            backgroundColor='grey01'
            borderRadius='xs'
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              paddingVertical: 6,
            }}
          />
        )}
        <Box flex={1} marginLeft='xs'>
          <Box flex={1} marginLeft='xxxs' marginBottom='xxs'>
            <Text
              accessibilityLabel='File title'
              variant='webBodySecondary'
              color='textPrimary'
              numberOfLines={1}
              marginBottom='xxxs'
              textDecorationLine={isHoveredTitle ? 'underline' : undefined}
              onMouseEnter={() => setIsHoveredTitle(true)}
              onMouseLeave={() => setIsHoveredTitle(false)}>
              <Highlighter
                autoEscape
                highlightStyle={{
                  color: theme.colors.textPrimary,
                  backgroundColor: theme.colors.yellowBright,
                }}
                searchWords={filterVal?.split(' ') || ''}
                textToHighlight={name}
              />
            </Text>
          </Box>

          <Box flexDirection='row' marginLeft='xxxs'>
            {showTags &&
              projectDocuments &&
              projectDocuments?.[0]?.project?.name && (
                <Box maxWidth={width / 3} flexDirection='row'>
                  <Tag
                    filterVal={filterVal}
                    label={projectDocuments?.[0]?.project?.name}
                    variant='webFolder'
                    prefix={<Icon name='Folder' variant='xs' color='grey04' />}
                    prefixMarginRight='xxs'
                    iconWidth={14}
                    iconHeight={14}
                    padding='none'
                    borderRadius='none'
                  />
                  <Icon name='Dot' variant='s' color='grey04' />
                </Box>
              )}

            {showTags &&
              messageDocuments &&
              messageDocuments?.[0]?.message?.chat?.name && (
                <Box maxWidth={width / 3} flexDirection='row'>
                  <Tag
                    filterVal={filterVal}
                    label={messageDocuments?.[0]?.message?.chat?.name}
                    variant='webFolder'
                    prefix={<Icon name='Chat' variant='xs' color='grey04' />}
                    prefixMarginRight='xxs'
                    iconWidth={14}
                    iconHeight={14}
                    padding='none'
                    borderRadius='none'
                  />
                  <Icon name='Dot' variant='s' color='grey04' />
                </Box>
              )}

            <Tag
              filterVal={filterVal}
              label={t('format:abbrMonthAndDay', { val: createdAt })}
              variant='webFolder'
              prefix={<Icon name='Calendar' variant='xs' color='grey04' />}
              prefixMarginRight='xxs'
              iconWidth={14}
              iconHeight={14}
              padding='none'
              borderRadius='none'
            />
          </Box>
        </Box>
        {showTags &&
          projectDocuments &&
          projectDocuments?.[0]?.project?.team && (
            <Box
              flex={1}
              flexDirection='row'
              alignItems='center'
              justifyContent='flex-end'
              padding='xxs'
              paddingRight='m'>
              <Text
                marginRight='xs'
                numberOfLines={1}
                ellipsizeMode='tail'
                variant='webBodySecondary'>
                {projectDocuments?.[0]?.project?.team?.name}
              </Text>
              <Icon
                name={
                  projectDocuments?.[0]?.project?.team?.personal
                    ? 'Office'
                    : 'TT24'
                }
                variant='l'
                color={
                  projectDocuments?.[0]?.project?.team?.personal
                    ? 'white'
                    : 'textPrimary'
                }
                backgroundColor={
                  projectDocuments?.[0]?.project?.team?.personal
                    ? 'greenSecondary'
                    : 'textPrimary'
                }
                padding={
                  projectDocuments?.[0]?.project?.team?.personal
                    ? 'xxs'
                    : 'none'
                }
                width={projectDocuments?.[0]?.project?.team?.personal ? 16 : 24}
                height={
                  projectDocuments?.[0]?.project?.team?.personal ? 16 : 24
                }
                borderRadius='xxs'
              />
            </Box>
          )}
        <Box alignItems='flex-end'>
          {onMorePress && (
            <>
              {!isSelectMode && (
                <Icon
                  name='MoreHorizontal'
                  color='textPrimary'
                  variant='m'
                  marginBottom='xs'
                  onPress={onMorePress}
                />
              )}
              <Text variant='metadata' color='textSecondary'>
                {formatSize(size || 0)}
              </Text>
            </>
          )}
        </Box>
      </Box>
    </TouchableOpacity>
  );
};

export default FileCard;
