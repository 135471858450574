import { useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';
import { RefreshControl } from 'react-native';

import EmptyStateNoProjects from '@components/EmptyState/EmptyStateNoProjects';

import ProjectList from '../../components/ProjectList/ProjectList';
import { useListProjectsWithDocumentsQuery } from '../../graphql/generated';

const AllFiles: React.FC = () => {
  const navigation = useNavigation();
  const [refreshing, setRefreshing] = useState(false);

  const { data, refetch, loading } = useListProjectsWithDocumentsQuery({
    onCompleted: () => {
      setRefreshing(false);
    },
    onError: () => {
      setRefreshing(false);
    },
  });
  const { listProjectsWithDocuments: projects } = data || {
    listProjectsWithDocuments: [],
  };

  return (
    <ProjectList
      projects={projects}
      expanded={false}
      listEmptyComponent={<EmptyStateNoProjects hideArrow />}
      onClick={(project) => {
        navigation.navigate('files-stack', {
          projectId: project.id,
          screen: 'project-files',
          params: {
            projectId: project.id,
          },
        });
      }}
      hideInitial={false}
      loading={loading || refreshing}
      refreshControl={
        <RefreshControl
          refreshing={refreshing}
          onRefresh={() => {
            setRefreshing(true);
            refetch();
          }}
        />
      }
    />
  );
};

export default AllFiles;
