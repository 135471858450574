import { CommonActions, useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FlatList,
  Modal,
  Platform,
  TouchableOpacity,
  TouchableWithoutFeedback,
  StyleSheet,
} from 'react-native';

import { ModalHeader } from '@components/Headers/ModalHeader';
import { AddMemberType } from '@components/Invite/SelectMembersList';
import { Box, ShadowBox, Text } from '@components/Restyle';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import Button from '@components/shared/Button/Button';
import { MemberRole, MemberType } from '@components/User/MemberRole';
import {
  User,
  TeamRole,
  TaskMemberRole,
  ProjectMemberRole,
} from '@graphql/generated';
import theme from '@themes/theme';

export type UserAndRole = {
  user: AddMemberType;
  role: TeamRole;
};
interface EditNewMemberRolesProps<Roles> {
  updateMemberRole: (id: User['id'], role: Roles) => void;
  onSubmit: () => void;
  isSubmitting: boolean;
  isTaskAssignee: boolean;
  usersAndRoles?: MemberType<Roles>[];
  roles: string[];
  currentRole?: string | null;
  pricingInfo?: string | null;
}

export const EditNewMemberRoles = <Roles,>({
  updateMemberRole,
  onSubmit,
  isSubmitting,
  isTaskAssignee,
  usersAndRoles,
  roles,
  currentRole,
  pricingInfo,
}: EditNewMemberRolesProps<Roles>) => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  // eslint-disable-next-line no-nested-ternary
  const memberRole = isTaskAssignee
    ? [TaskMemberRole.Viewer]
    : currentRole == ProjectMemberRole.Editor
    ? [ProjectMemberRole.Editor, ProjectMemberRole.Viewer]
    : roles;

  return (
    <Box flex={1} backgroundColor='white'>
      <Modal animationType='fade' transparent={true} visible={modalOpen}>
        <Box style={teamStyles.modalContainer}>
          <TouchableWithoutFeedback
            onPress={() => setModalOpen(!modalOpen)}
            style={teamStyles.modal}
            accessibilityLabel='CloseModalButton'>
            <Box style={teamStyles.modal} />
          </TouchableWithoutFeedback>

          <ShadowBox
            variant='thin'
            style={
              Platform.OS === 'web' ? teamStyles.popUpWeb : teamStyles.popUp
            }>
            <Box
              marginVertical='m'
              marginHorizontal='m'
              flex={1}
              justifyContent='space-between'>
              <Box
                style={teamStyles.modalHeader}
                accessibilityLabel='RoleSelectModal'>
                <ModalHeader
                  title={t('shared:pricing')}
                  submitLabel={t('shared:cancel')}
                  onCancel={() => setModalOpen(false)}
                  showCancel={false}
                  onSubmit={() => setModalOpen(false)}
                />
              </Box>
              <Text>{pricingInfo}</Text>
              <FlatList
                data={usersAndRoles}
                renderItem={({ item }) => {
                  return (
                    <Box>
                      <MemberRole<Roles> roles={memberRole} member={item} />
                    </Box>
                  );
                }}
                showsHorizontalScrollIndicator={Platform.OS !== 'web'}
                showsVerticalScrollIndicator={Platform.OS !== 'web'}
                keyExtractor={(item) => item.user.id}
                ItemSeparatorComponent={() => <Box marginTop='m' />}
                ListFooterComponent={() => <Box marginBottom='listFooter' />}
                ListHeaderComponent={() => <Box marginTop='m' />}
              />
              <Box flexDirection='row' mt='m' flex={1}>
                <Button
                  variant='primary'
                  disabled={isSubmitting}
                  onPress={() => {
                    // reset team stack
                    setModalOpen(false);
                    onSubmit();
                    navigation.dispatch(
                      CommonActions.reset({
                        index: 0,
                        routes: [{ name: 'home' }],
                      })
                    );
                  }}
                  fullWidth={true}>
                  {t('shared:confirm')}
                </Button>
              </Box>
            </Box>
          </ShadowBox>
        </Box>
      </Modal>
      <FlatList
        data={usersAndRoles?.filter((item) => item.role !== 'OWNER')}
        renderItem={({ item }) => {
          return (
            <Box marginLeft='m' marginRight='l'>
              <MemberRole<Roles>
                roles={memberRole}
                member={item}
                updateRole={(role: Roles) => {
                  updateMemberRole(item.user.id, role);
                }}
              />
            </Box>
          );
        }}
        showsHorizontalScrollIndicator={Platform.OS !== 'web'}
        showsVerticalScrollIndicator={Platform.OS !== 'web'}
        keyExtractor={(item) => item.user.id}
        ItemSeparatorComponent={() => <Box marginTop='m' />}
        ListFooterComponent={() => <Box marginBottom='listFooter' />}
        ListHeaderComponent={() => <Box marginTop='m' />}
      />

      {Platform.OS === 'web' ? (
        <Box
          flexDirection='row'
          alignItems='center'
          justifyContent='flex-end'
          mr='l'
          marginVertical='m'>
          <TouchableOpacity
            onPress={() => navigation.goBack()}
            disabled={isSubmitting}>
            <Text>{t('shared:back')}</Text>
          </TouchableOpacity>
          <Button
            variant='primary'
            disabled={isSubmitting}
            onPress={() => {
              if (pricingInfo) setModalOpen(true);
              else onSubmit();
            }}
            marginLeft='l'>
            {t('shared:addMembers')}
          </Button>
        </Box>
      ) : (
        <Button
          variant='primary'
          disabled={isSubmitting}
          onPress={() => {
            if (pricingInfo) setModalOpen(true);
            else onSubmit();
          }}
          float='bottom-inset'>
          {isSubmitting ? <ActivityIndicatorLoading /> : t('shared:confirm')}
        </Button>
      )}
    </Box>
  );
};

export const teamStyles = StyleSheet.create({
  modal: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'black',
    opacity: 0.5,
  },
  modalContainer: {
    ...StyleSheet.absoluteFillObject,
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalHeader: {
    paddingBottom: theme.spacing.m,
    paddingTop: theme.spacing.l,
  },
  popUp: {
    position: 'absolute',
    backgroundColor: 'white',
    width: '100%',
    bottom: 0,
    paddingBottom: theme.spacing.xxl,
    borderTopLeftRadius: theme.borderRadii.l,
    borderTopRightRadius: theme.borderRadii.l,
  },
  popUpWeb: {
    position: 'absolute',
    backgroundColor: 'white',
    width: 500,
    maxHeight: 600,
    paddingBottom: theme.spacing.l,
    borderRadius: theme.borderRadii.s,
  },
});
