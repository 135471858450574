import { useRoute } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { useToast } from 'react-native-toast-notifications';

import { CustomToast } from '@components/CustomToast/CustomToast';
import { HeaderGoBack } from '@components/HeaderGoBack/HeaderGoBack';
import { Box, Text } from '@components/Restyle';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import Icon from '@components/shared/Icon/Icon';
import ListNavigationItem, {
  ListNavigationItemProps,
} from '@components/shared/ListNavigationItem/ListNavigationItem';
import { TeamCard } from '@components/Team/TeamCard';
import { useWebDrawer } from '@components/Web/Drawer/WebDrawerContext';
import {
  ListTeamsDocument,
  Team,
  useDeleteTeamMutation,
  useLeaveTeamMutation,
} from '@graphql/generated';
import useMe from '@hooks/useMe';
import { useTeamFromQuery } from '@hooks/useTeamFromQuery';
import { useWebDrawerNavigator } from '@hooks/useWebDrawerNavigator';
import { useAppNavigation } from '@navigation/useAppNavigation';
import EditTeamModal from '@screens/Teams/EditTeamModal.web';
import TeamNavList from '@screens/Teams/TeamNavList.web';
import WebModalComponent from '@screens/Teams/WebModalComponent.web';

import type { TeamsStackScreenProps } from '@navigation/teams/teams-stack';

const TeamDetails: React.FC = () => {
  const isPaymentsFeatureActivated = true;
  const { t } = useTranslation();
  const appNavigation = useAppNavigation();
  const { setSelectedTeam, setFromSubscriptionPlanList } =
    useWebDrawerNavigator();
  const route = useRoute<TeamsStackScreenProps<'team-details'>['route']>();
  const { me } = useMe();
  const [teamIdRequested, setTeamIdRequested] = useState('');
  const [showOwnerLeaveMsgBox, setShowOwnerLeaveMsgBox] = useState(false);
  const [showLeaveDeleteMsgBox, setShowLeaveDeleteMsgBox] = useState(false);
  const [showMemberLeaveMsgBox, setShowMemberLeaveMsgBox] = useState(false);
  const [showCreateTeamModal, setShowCreateTeamModal] = useState(false);

  const toast = useToast();

  const {
    setUpgradeTeamPlanOpen,
    setIdProjectOfTaskDetailOpen,
    setIsTaskWebPanelMaximize,
    setIsTaskWebPanelOpen,
    setIdTaskDetailOpen,
    setIsCreateProjectOpen,
    setEditProjectId,
    setIdTeamOpen,
    setIsTeamPersonal,
  } = useWebDrawer();

  const {
    params: { teamId },
  } = route || {};

  const { team, loading } = useTeamFromQuery({
    teamId,
  });

  const [leaveTeam] = useLeaveTeamMutation({
    onCompleted: () => appNavigation.goBack(),
    refetchQueries: [{ query: ListTeamsDocument }],
  });

  const [deleteTeam] = useDeleteTeamMutation({
    onCompleted: () => appNavigation.goBack(),
    refetchQueries: [{ query: ListTeamsDocument }],
  });

  useEffect(() => {
    if (!toast?.show) return;
    if (teamIdRequested === team?.id) return;

    setTeamIdRequested((team as Team)?.id);

    if (team && !team?.personal && !team?.plan?.id && isOwner) {
      showToast(toastContent2());
    }
  }, [loading]);

  const showToast = (content: JSX.Element) => {
    toast.show(
      <CustomToast>
        <TouchableOpacity onPress={() => openTeamPanel(team)}>
          {content}
        </TouchableOpacity>
      </CustomToast>,
      {
        type: 'custom',
        placement: 'bottom',
        duration: 10000,
        animationType: 'zoom-in',
      }
    );
  };

  const toastContent2 = () => {
    return toastContent(
      t('models:customToast.teamPlan'),
      t('models:customToast.upgradePlanTeam'),
      t('models:customToast.firstBenefitPlan'),
      t('models:customToast.secondBenefitPlan')
    );
  };

  const openTeamPanel = (team: Team) => {
    setIsTaskWebPanelOpen(false);
    setIsTaskWebPanelMaximize(false);
    setIdTaskDetailOpen('');
    setIdProjectOfTaskDetailOpen('');
    setIsCreateProjectOpen(false);
    setEditProjectId('');
    setUpgradeTeamPlanOpen(true);
    setIdTeamOpen(team?.id);
    setIsTeamPersonal(team?.personal);
  };

  const toastContent = (
    line1: string,
    line2: string,
    line3: string,
    line4: string
  ) => {
    return (
      <Box maxWidth={300} gap='xxs'>
        <Text color='white' fontWeight='bold'>
          {line1}
        </Text>
        <Box gap='xxxs'>
          <Text color='white'>{line2}</Text>
          <Box
            flexDirection='row'
            alignItems='center'
            gap='xxs'
            marginTop='xxs'>
            <Icon
              width={20}
              height={20}
              variant='l'
              color='white'
              name='Check'
            />
            <Text color='white'>{line3}</Text>
          </Box>
          <Box flexDirection='row' alignItems='center' gap='xxs'>
            <Icon
              width={20}
              height={20}
              variant='l'
              color='white'
              name='Check'
            />
            <Text color='white'>{line4}</Text>
          </Box>
        </Box>
      </Box>
    );
  };

  if (!team?.id && loading) {
    return <ActivityIndicatorLoading />;
  }

  if (!team) {
    return (
      <Box flex={1} paddingTop='m' alignItems='center'>
        <Text>Team not found</Text>
      </Box>
    );
  }

  const { id, personal, owner, usersCount } = team;

  const isOwner = me?.id === owner?.id;

  const leaveTeamCheck = () => {
    if (isOwner) {
      // show pop up
      if (usersCount === 1) {
        setShowLeaveDeleteMsgBox(true);
      } else {
        setShowOwnerLeaveMsgBox(true);
      }
    } else {
      setShowMemberLeaveMsgBox(true);
    }
  };

  const shouldBeDisplayedWhen = true;

  const webNavItems: ListNavigationItemProps[] =
    shouldBeDisplayedWhen &&
    !['trialing', 'active'].includes(team?.plan?.status as unknown as string) &&
    !team?.personal &&
    isOwner
      ? [
          {
            title: 'Upgrade',
            description: 'Enjoy more features!',
            iconName: 'Zap',
            iconBackgroundColor: 'greenSecondaryMild',
            iconColor: 'greenBrand',
            disabled: false,
            onPress: () => {
              toast.hideAll();
              setIsTaskWebPanelOpen(false);
              setIsTaskWebPanelMaximize(false);
              setIdTaskDetailOpen('');
              setIdProjectOfTaskDetailOpen('');
              setIsCreateProjectOpen(false);
              setEditProjectId('');
              setUpgradeTeamPlanOpen(true);
              setIdTeamOpen(id);
              setIsTeamPersonal(team?.personal);
            },
            comingSoon: false,
          },
        ]
      : [];

  const handleSubscriptionPress = () => {
    setSelectedTeam(team);
    setFromSubscriptionPlanList(false);
    appNavigation.navigateToMyprofileSubscriptionDetail(
      team.id,
      usersCount,
      team.invoices?.length || 0
    );
  };

  const ownerNavItems: ListNavigationItemProps[] = isOwner
    ? [
        {
          title: 'Subscription',
          description: 'Manage subscription',
          iconName: 'CreditCard',
          iconBackgroundColor: 'grey01',
          disabled: !isPaymentsFeatureActivated || !team.plan,
          comingSoon: !isPaymentsFeatureActivated,
          onPress: handleSubscriptionPress,
        },
      ]
    : [];

  const navItems = [...webNavItems, ...ownerNavItems];
  if (!personal) {
    navItems.push({
      title: 'Leave',
      titleColor: 'alertRed',
      description: 'Leave the team',
      iconName: 'Leave',
      iconBackgroundColor: 'grey01',
      onPress: leaveTeamCheck,
    });
  }

  const handleOwnerLeaveBoxVisibility = () => {
    setShowOwnerLeaveMsgBox(false);
  };

  const handleLeaveDeleteBoxVisibility = () => {
    setShowLeaveDeleteMsgBox(false);
  };

  const handleMemberLeaveBoxVisibility = () => {
    setShowMemberLeaveMsgBox(false);
  };

  const handleShowCreateTeamModal = () => {
    setShowCreateTeamModal(true);
  };

  const handleOnPressOk = () => {
    if (showOwnerLeaveMsgBox) {
      setShowOwnerLeaveMsgBox(false);
      appNavigation.navigate('assign-new-owner', { teamId });
    } else if (showLeaveDeleteMsgBox) {
      setShowLeaveDeleteMsgBox(false);
      if (!id) return;
      deleteTeam({
        variables: { id },
      });
    } else if (showMemberLeaveMsgBox) {
      setShowMemberLeaveMsgBox(false);
      if (!id) return;
      // call mutation to leave the team. onCompleted > goBack();
      leaveTeam({
        variables: {
          id,
        },
      });
    }
  };

  const listHeaderComponent = () => {
    return (
      <Box marginTop='xxs' marginBottom='l'>
        <TeamCard
          team={team}
          onEditPress={() => {
            setShowCreateTeamModal(true);
          }}
          isOwner={isOwner}
        />
      </Box>
    );
  };

  const listFooterComponent = () => {
    return <Box marginBottom='listFooter' />;
  };

  const itemSeparatorComponent = () => {
    return <Box marginBottom='l' />;
  };

  const renderItem = ({ item, index }) => {
    return (
      <Box key={index}>
        <ListNavigationItem {...item} marginBottom='none' />
      </Box>
    );
  };

  return (
    <Box style={{ width: '100%' }} marginTop='xxxs' paddingLeft='l'>
      <HeaderGoBack redirectTo='myprofile' titleHeader='Team' noSpacing />
      <Box flex={1} alignItems='center'>
        <Box width={776} marginTop='xxxs'>
          <Box marginBottom='l'>
            <TeamNavList
              teamId={teamId}
              listHeaderComponent={listHeaderComponent}
              itemSeparatorComponent={itemSeparatorComponent}
            />
          </Box>
          <FlatList
            data={navItems}
            renderItem={renderItem}
            ItemSeparatorComponent={itemSeparatorComponent}
            ListFooterComponent={listFooterComponent}
          />

          <WebModalComponent
            modalWidth={483}
            modalHeight={192}
            xVisible={showOwnerLeaveMsgBox}
            msgBody={t('models:teams.leave.message')}
            okButtonTitle={t('models:teams.removeTeamMember.assign')}
            onCloseModal={handleOwnerLeaveBoxVisibility}
            onPressOK={handleOnPressOk}
          />

          <WebModalComponent
            modalWidth={483}
            modalHeight={192}
            xVisible={showLeaveDeleteMsgBox}
            msgBody={t('models:teams.leave.deleteMessage')}
            okButtonTitle={t('shared:delete')}
            onCloseModal={handleLeaveDeleteBoxVisibility}
            onPressOK={handleOnPressOk}
          />
          <WebModalComponent
            modalWidth={483}
            modalHeight={176}
            xVisible={showMemberLeaveMsgBox}
            msgBody={t('models:teams.leave.memberLeaveMessage')}
            okButtonTitle={t('models:teams.leave.title')}
            onCloseModal={handleMemberLeaveBoxVisibility}
            onPressOK={handleOnPressOk}
          />
          <EditTeamModal
            team={team}
            showCreateTeamModal={showCreateTeamModal}
            setShowCreateTeamModal={handleShowCreateTeamModal}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default TeamDetails;
