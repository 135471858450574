import { Formik } from 'formik';
import React from 'react';

import { TeamsModal } from '@components/FormModals/TeamsModal';
import {
  GetTeamDocument,
  Team,
  TeamAttributes,
  useUpdateTeamMutation,
} from '@graphql/generated';
import { FormValues } from '@navigation/payments/create-subscription-stack';
import { createTeamSchema } from '@schemas/createTeamSchema';

interface EditTeamModalProps {
  showCreateTeamModal: boolean;
  team: Team;
  setShowCreateTeamModal: (show: boolean) => void;
}

const EditTeamModal: React.FC<EditTeamModalProps> = ({
  showCreateTeamModal,
  team,
  setShowCreateTeamModal,
}) => {
  const initialValues: FormValues = {
    location: {
      address: team?.address,
      latitude: team?.latitude,
      longitude: team?.longitude,
    } as Location,
    name: team?.name,
    skillIds: team?.skills ? team?.skills.map((t) => t.id) : [],
    logo: team?.avatarFile ? team.avatarFile : null,
  };

  if (!showCreateTeamModal) return null;

  const [updateTeam] = useUpdateTeamMutation({
    onCompleted: () => {
      setShowCreateTeamModal(false);
    },
    onError: () => {
      setShowCreateTeamModal(false);
    },
    refetchQueries: [{ query: GetTeamDocument, variables: { id: team?.id } }],
  });

  const submitForm = async (
    values: FormValues,
    setSubmitting: (a: boolean) => void
  ) => {
    setSubmitting(true);
    const { name, location, skillIds } = values;
    const { address, latitude, longitude } = location || {
      address: undefined,
      latitude: undefined,
      longitude: undefined,
    };
    const attributes = {
      name,
      skillIds,
      address,
      latitude,
      longitude,
    } as TeamAttributes;

    await updateTeam({
      variables: {
        id: team.id,
        attributes,
      },
    });

    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={createTeamSchema}
      onSubmit={async (values, { setSubmitting }) => {
        await submitForm(values, setSubmitting);
      }}>
      {() => (
        <TeamsModal
          title='models:teams.editTeam'
          onClose={() => setShowCreateTeamModal(false)}
        />
      )}
    </Formik>
  );
};

export default EditTeamModal;
