import {
  StackActions,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import * as Clipboard from 'expo-clipboard';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import Toast from 'react-native-toast-notifications';

import { ContactType } from '@components/Invite/Contact';
import ContactList from '@components/Invite/ContactList';
import { ListScreenActionHeader } from '@components/ListScreenActionHeader';
import { Box } from '@components/Restyle';
import Icon from '@components/shared/Icon/RestyleIcon';
import {
  ChatDrawerScreen,
  useWebDrawer,
} from '@components/Web/Drawer/WebDrawerContext';
import {
  useCreateChatMutation,
  useCreateInviteMutation,
  useListContactUsersQuery,
  User,
} from '@graphql/generated';
import useActiveChat from '@hooks/useActiveChat';
import { ContactPanel } from '@navigation/contacts/contact-panel';
import { ContactsTabStackScreenProps } from '@navigation/contacts/contacts-stack.web';
import { useAppNavigation } from '@navigation/useAppNavigation';
import theme from '@themes/theme';
import { convertUsersToContacts } from '@utils/convertUsersToContacts';

export const Contacts: React.FC = () => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const route = useRoute();
  const { navigateToChatDrawer: navigateTo } = useAppNavigation();

  const { params: { userId } = { userId: undefined } } =
    useRoute<ContactsTabStackScreenProps<'contacts'>['route']>() || {};

  const { data: listContacts } = useListContactUsersQuery();
  const listContactsToContactType = convertUsersToContacts(
    (listContacts?.listContactUsers as User[]) || []
  );
  const [search, setSearch] = useState('');
  const [isMaximize, setIsMaximize] = useState(false);
  const { setActiveMessageCursor, setIsOpenChat } = useActiveChat();
  const { chatExpanded, chatListOpen, setChatListOpen, setShouldChatListOpen } =
    useWebDrawer();
  const toastRef = useRef(null);

  const openProfile = (contact: ContactType) => {
    if (route.params?.screen === 'contact-task-details') {
      const pushAction = StackActions.push('contacts', {
        screen: 'contact',
        userId: contact.id,
        params: {
          screen: 'contact-info',
        },
      });
      navigation.dispatch(pushAction);
    } else {
      navigation.navigate('contacts', {
        userId: contact.id,
      });
    }
  };
  const [createChat] = useCreateChatMutation({
    onCompleted: (data) => {
      const { createChat: createChatData } = data;
      if (!chatExpanded && chatListOpen) {
        setShouldChatListOpen(true);
        setChatListOpen(false);
      }
      setIsOpenChat(true);
      setActiveMessageCursor && setActiveMessageCursor(undefined);
      navigateTo({
        screen: ChatDrawerScreen.details,
        activeChat: createChatData,
      });
    },
  });
  const openChat = ({ id }: ContactType) => {
    createChat({
      variables: {
        attributes: {
          userIds: [id],
        },
      },
    });
  };

  const onMaximize = () => setIsMaximize(!isMaximize);

  const onClose = () => {
    setIsMaximize(false);
    navigation.navigate('contacts');
  };

  const [createInviteMutation] = useCreateInviteMutation({
    variables: {},
    onCompleted: (data) => {
      copyToClipboard(
        data?.createInvite.replace(
          'register/register-with-email',
          'invited-user'
        )
      );
    },
  });

  const copyToClipboard = async (link: string) => {
    toastRef.current?.show('Copied', {
      duration: 2000,
    });

    await Clipboard.setStringAsync(link || '');
  };

  return (
    <Box flex={1}>
      <Box flex={1} flexDirection='row' justifyContent='space-between'>
        {!isMaximize && (
          <Box mx='m' flex={1}>
            <Box my='l'>
              <ListScreenActionHeader
                createNewButtonProps={{
                  onPress: () => createInviteMutation(),
                  text: t('models:contacts.inviteFriends'),
                  prefix: (
                    <Box style={[styles.outerPerson]}>
                      <Icon name='UserCircle' />
                    </Box>
                  ),
                  variant: 'editBold',
                  style: {
                    backgroundColor: 'background',
                    borderWidth: 0,
                  },
                }}
                search={{
                  onTextChanged: setSearch,
                  value: search,
                }}
              />
            </Box>
            <ContactList
              filterVal={search}
              contacts={listContactsToContactType}
              alphabetize
              onPress={(item: ContactType) => openProfile(item)}
              showMessageIcon={true}
              onMessagePress={(item: ContactType) => openChat(item)}
            />
          </Box>
        )}
        {userId && (
          <ContactPanel
            userId={userId}
            isMaximize={isMaximize}
            onMaximize={onMaximize}
            onClose={onClose}
          />
        )}
      </Box>
      <Toast ref={toastRef} />
    </Box>
  );
};

const styles = StyleSheet.create({
  outerPerson: {
    width: 40,
    height: 40,
    backgroundColor: theme.colors.grey01,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.borderRadii.xs,
    marginRight: theme.spacing.s,
  },
});
