import React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import { BillingCycle } from '../../../types';
import { Box, Text } from '../Restyle';
interface IPaymentCard {
  billingCycle: BillingCycle;
  monthlyCost: number;
  totalCost?: number;
  savingPercentage?: number;
  setBillingCycle: (cycle: BillingCycle) => void;
  isSelected: boolean;
}

const PaymentOptionCard: React.FC<IPaymentCard> = ({
  billingCycle,
  monthlyCost,
  totalCost,
  savingPercentage,
  setBillingCycle,
  isSelected,
}) => {
  const { t } = useTranslation();

  return (
    <TouchableOpacity
      onPress={() => setBillingCycle(billingCycle)}
      style={{ flex: 1 }}>
      <Box
        flex={1}
        borderColor={isSelected ? 'buttonBackground' : 'grey02'}
        borderWidth={1}
        borderRadius='m'
        paddingHorizontal='m'
        paddingVertical='s'>
        <Text variant='labelLarge'>
          {t('models:payments.monthlyCost', { monthlyCost })}
        </Text>
        {totalCost && (
          <Text variant='labelSmall' style={{ flex: 1 }}>
            {t('models:payments.totalCost', { totalCost })}
          </Text>
        )}
        <Text
          variant='bodySecondary'
          marginTop={totalCost ? undefined : 'xs'}
          style={{ flex: 1 }}>
          {t('models:payments.billingCycle', { billingCycle })}
        </Text>
        {!!savingPercentage && (
          <Text variant='labelSmall' color='greenSecondary'>
            {t('models:payments.annualSavings', { savingPercentage })}
          </Text>
        )}
      </Box>
    </TouchableOpacity>
  );
};

export default PaymentOptionCard;
