import React from 'react';

import { Plan } from '../../../types';
import { Box, Text } from '../Restyle';
import Icon from '../shared/Icon/Icon';

type PlanTitle = Pick<Plan, 'planName' | 'icon' | 'color' | 'description'>;

const PlanTitle: React.FC<PlanTitle> = ({
  planName,
  description,
  icon,
  color,
}) => {
  return (
    <Box>
      <Box flexDirection='row' alignItems='center'>
        <Icon name={icon} backgroundColor={color} color='white' variant='s' />
        <Text marginLeft='xs' variant='labelEmphasized'>
          {planName}
        </Text>
      </Box>
      <Text marginTop='xs' variant='labelSmall' color={color}>
        {description}
      </Text>
    </Box>
  );
};

export default PlanTitle;
