import { Field, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, TouchableOpacity } from 'react-native';

import { Box, ShadowBox, Text } from '@components/Restyle';
import Button from '@components/shared/Button/Button';
import { EmailsField } from '@components/shared/EmailsField/EmailsField';
import Icon from '@components/shared/Icon/Icon';
import TextField from '@components/shared/TextField/TextField';
import { RegisterStackScreenProps } from '@navigation/auth/register-stack-new.web';
import { LoadingScreen } from '@screens/Auth/LoadingScreen.web';
import { RegisterFormValues } from '@screens/Auth/RegisterForm.web';
import { useAuthContext } from '@src/context/authContext';

const CreateTeamOnlyWeb: React.FC<
  RegisterStackScreenProps<'create-team'>
> = () => {
  const { t } = useTranslation();
  const {
    values,
    setFieldValue,
    errors,
    touched,
    isValid,
    setTouched,
    submitForm,
  } = useFormikContext<RegisterFormValues>();

  const { registerLoading, setRegisterLoading } = useAuthContext();

  useEffect(() => {
    setFieldValue('step', 3);
  }, []);

  useEffect(() => {
    const handleBackButton = () => {
      setFieldValue('step', 2);
    };
    if (Platform.OS === 'web') {
      window.addEventListener('popstate', handleBackButton);
    }

    return () => {
      if (Platform.OS === 'web') {
        window.removeEventListener('popstate', handleBackButton);
      }
    };
  }, []);

  const disabled =
    !values.team_name ||
    (touched.team_name && !!errors.team_name) ||
    (!!errors.team_name && !isValid);

  if (registerLoading) return <LoadingScreen />;

  return (
    <Box
      flex={1}
      backgroundColor='grey01'
      alignItems='center'
      justifyContent='center'>
      <ShadowBox
        variant='base'
        backgroundColor='white'
        borderRadius='m'
        style={{
          width: 492,
        }}>
        <Box
          alignItems='center'
          borderRadius='m'
          px='xl'
          mx='xxs'
          py='l'
          my='xs'
          backgroundColor='white'>
          <Icon name='Logo' width={142} height={43} marginBottom='xl' />
          <Text variant='heading2' marginVertical='xxs'>
            {t('models:onBoarding.createYourTeam')}
          </Text>
          <Text variant='webBodySecondary' marginBottom='l'>
            {t('models:onBoarding.bringTogetherThePerfectTeamInJustAFewClicks')}
          </Text>
          <Field
            autoFocus
            component={TextField}
            label='Team/Workplace name'
            name='team_name'
            placeholder='Team Name'
            isRequired
            isLarge
            textInputProps={{
              autoCapitalize: 'words',
              autoCorrect: false,
            }}
            accessibilityLabel='Team/Workplace name'
          />
          <Field
            component={EmailsField}
            label='Invite people to your team'
            name='team_emails'
            placeholder='Add emails'
            isLarge
            enable={!!values.team_name}
            textInputProps={{
              autoCapitalize: 'words',
              autoCorrect: false,
              editable: false,
            }}
            accessibilityLabel='Invite people to your team'
          />
          <Button
            disabled={registerLoading || disabled}
            variant='primary'
            fullWidth
            isSmall
            onPress={async () => {
              setTouched({
                ...touched,
                team_name: true,
              });
              if (!(!!errors.team_name && !isValid)) {
                setRegisterLoading(true);
                try {
                  await submitForm();
                } catch (error) {
                  console.log(error);
                } finally {
                  setRegisterLoading(false);
                }
              }
            }}
            accessibilityLabel={t('models:onBoarding.createTeam')}>
            {t('models:onBoarding.createTeam')}
          </Button>
          <TouchableOpacity
            accessibilityLabel={t('models:onBoarding.skip')}
            onPress={() => {
              window.sessionStorage.setItem('do_not_show_invite_message', '1');
              setRegisterLoading(true);
              submitForm();
            }}>
            <Text variant='webSmall' marginTop='m' color='greenSecondary'>
              {t('models:onBoarding.skip')}
            </Text>
          </TouchableOpacity>
        </Box>
      </ShadowBox>
    </Box>
  );
};

export default CreateTeamOnlyWeb;
