import ArrowDownLong from '../assets/EmptyState/arrow_down_long.svg';
import ArrowUpLong from '../assets/EmptyState/arrow_up_long.svg';
import NoChat from '../assets/EmptyState/no_chat.svg';
import NoFiles from '../assets/EmptyState/no_files.svg';
import NoFilesMob from '../assets/EmptyState/no_files_mob.svg';
import NoProjects from '../assets/EmptyState/no_projects.svg';
import NoSearchProjectsColor from '../assets/EmptyState/no_projects_colour.svg';
import NoProjectsMob from '../assets/EmptyState/no_projects_mob.svg';
import NoSearchProjects from '../assets/EmptyState/no_search_project.svg';
import NoSearchResults from '../assets/EmptyState/no_search_results.svg';
import NoSearchTask from '../assets/EmptyState/no_search_task.svg';
import NoTasks from '../assets/EmptyState/no_tasks.svg';
import NoTasksMob from '../assets/EmptyState/no_tasks_mob.svg';
import Present from '../assets/EmptyState/present.svg';
import WinnerIsometric from '../assets/EmptyState/winner_isometric.svg';
import WinnerIsometric1 from '../assets/EmptyState/winner_isometric1.svg';
import WinnerIsometric2 from '../assets/EmptyState/winner_isometric2.svg';
import WinnerTwoColor from '../assets/EmptyState/winners_two_color.svg';
import Activity1 from '../assets/icons/activity 1.svg';
import Airplay from '../assets/icons/airplay.svg';
import AlertCircle from '../assets/icons/alert-circle.svg';
import AlertOctagon from '../assets/icons/alert-octagon.svg';
import AlertTriangle from '../assets/icons/alert-triangle.svg';
import AlignCenter from '../assets/icons/align-center.svg';
import AlignJustify from '../assets/icons/align-justify.svg';
import AlignLeft from '../assets/icons/align-left.svg';
import AlignRight from '../assets/icons/align-right.svg';
import AmericanExpress from '../assets/icons/americanExpress.svg';
import Anchor from '../assets/icons/anchor.svg';
import Aperture from '../assets/icons/aperture.svg';
import Apple from '../assets/icons/apple.svg';
import Apple24 from '../assets/icons/apple24.svg';
import Archive from '../assets/icons/archive.svg';
import ArrowDownCircle from '../assets/icons/arrow-down-circle.svg';
import ArrowDownLeft from '../assets/icons/arrow-down-left.svg';
import ArrowDownNarrow from '../assets/icons/arrow-down-narrow.svg';
import ArrowDownRight from '../assets/icons/arrow-down-right.svg';
import ArrowDown from '../assets/icons/arrow-down.svg';
import ArrowDown1 from '../assets/icons/arrow-down1.svg';
import ArrowLeftCircle from '../assets/icons/arrow-left-circle.svg';
import ArrowLeft from '../assets/icons/arrow-left.svg';
import ArrowRightCircle from '../assets/icons/arrow-right-circle.svg';
import ArrowRight from '../assets/icons/arrow-right.svg';
import ArrowUpCircle from '../assets/icons/arrow-up-circle.svg';
import ArrowUpLeft2 from '../assets/icons/arrow-up-left-2.svg';
import ArrowUpLeft from '../assets/icons/arrow-up-left.svg';
import ArrowUpNarrow from '../assets/icons/arrow-up-narrow.svg';
import ArrowUpRight from '../assets/icons/arrow-up-right.svg';
import ArrowUp from '../assets/icons/arrow-up.svg';
import Arrow from '../assets/icons/arrow.svg';
import ArrowsRefresh from '../assets/icons/arrows-refresh.svg';
import AssignTaskMsg1 from '../assets/icons/assign-task-msg1.svg';
import AssignTaskMsg2 from '../assets/icons/assign-task-msg2.svg';
import AssignTaskMsg3 from '../assets/icons/assign-task-msg3.svg';
import AtSign from '../assets/icons/at-sign.svg';
import Award from '../assets/icons/award.svg';
import BarChart2 from '../assets/icons/bar-chart-2.svg';
import BarChart from '../assets/icons/bar-chart.svg';
import BatteryCharging from '../assets/icons/battery-charging.svg';
import Battery from '../assets/icons/battery.svg';
import BellOff from '../assets/icons/bell-off.svg';
import Bell from '../assets/icons/bell.svg';
import Bill from '../assets/icons/bill.svg';
import Bluetooth from '../assets/icons/bluetooth.svg';
import Bold from '../assets/icons/bold.svg';
import BookOpen from '../assets/icons/book-open.svg';
import Book from '../assets/icons/book.svg';
import Bookmark from '../assets/icons/bookmark.svg';
import Box from '../assets/icons/box.svg';
import Briefcase from '../assets/icons/briefcase.svg';
import BuildingHomeHouse from '../assets/icons/building_home_house.svg';
import Calendar from '../assets/icons/calendar.svg';
import CameraOff from '../assets/icons/camera-off.svg';
import Camera from '../assets/icons/camera.svg';
import Cast from '../assets/icons/cast.svg';
import ChatNew from '../assets/icons/chat-new.svg';
import Chat from '../assets/icons/chat.svg';
import CheckCircle from '../assets/icons/check-circle.svg';
import CheckSquare from '../assets/icons/check-square.svg';
import Check from '../assets/icons/check.svg';
import CheckBoxSelected from '../assets/icons/checkbox-selected.svg';
import ChevronDown from '../assets/icons/chevron-down.svg';
import ChevronLeft from '../assets/icons/chevron-left.svg';
import ChevronRight from '../assets/icons/chevron-right.svg';
import ChevronUp from '../assets/icons/chevron-up.svg';
import ChevronsDown from '../assets/icons/chevrons-down.svg';
import ChevronsLeft from '../assets/icons/chevrons-left.svg';
import ChevronsRight from '../assets/icons/chevrons-right.svg';
import ChevronsUp from '../assets/icons/chevrons-up.svg';
import Chrome from '../assets/icons/chrome.svg';
import Circle from '../assets/icons/circle.svg';
import Clipboard from '../assets/icons/clipboard.svg';
import Clock from '../assets/icons/clock.svg';
import Close from '../assets/icons/close.svg';
import CloudDrizzle from '../assets/icons/cloud-drizzle.svg';
import CloudLightning from '../assets/icons/cloud-lightning.svg';
import CloudOff from '../assets/icons/cloud-off.svg';
import CloudRain from '../assets/icons/cloud-rain.svg';
import CloudSnow from '../assets/icons/cloud-snow.svg';
import Cloud from '../assets/icons/cloud.svg';
import Code from '../assets/icons/code.svg';
import Codepen from '../assets/icons/codepen.svg';
import Codesandbox from '../assets/icons/codesandbox.svg';
import Coffee from '../assets/icons/coffee.svg';
import Columns from '../assets/icons/columns.svg';
import Command from '../assets/icons/command.svg';
import Compass from '../assets/icons/compass.svg';
import Copy from '../assets/icons/copy.svg';
import CornerDownLeft from '../assets/icons/corner-down-left.svg';
import CornerDownRight from '../assets/icons/corner-down-right.svg';
import CornerLeftDown from '../assets/icons/corner-left-down.svg';
import CornerLeftUp from '../assets/icons/corner-left-up.svg';
import CornerRightDown from '../assets/icons/corner-right-down.svg';
import CornerRightUp from '../assets/icons/corner-right-up.svg';
import CornerUpLeft from '../assets/icons/corner-up-left.svg';
import CornerUpRight from '../assets/icons/corner-up-right.svg';
import Counter from '../assets/icons/counter.svg';
import Cpu from '../assets/icons/cpu.svg';
import CreateTeam from '../assets/icons/create_team.svg';
import CreateTeamSuccess from '../assets/icons/create_team_success.svg';
import CreditCard from '../assets/icons/credit-card.svg';
import Crop from '../assets/icons/crop.svg';
import crossCircle from '../assets/icons/crossCircle.svg';
import Crosshair from '../assets/icons/crosshair.svg';
import Database from '../assets/icons/database.svg';
import Delete from '../assets/icons/delete.svg';
import Disc from '../assets/icons/disc.svg';
import Discover from '../assets/icons/discover.svg';
import DivideCircle from '../assets/icons/divide-circle.svg';
import DivideSquare from '../assets/icons/divide-square.svg';
import Divide from '../assets/icons/divide.svg';
import DollarSign from '../assets/icons/dollar-sign.svg';
import Dot from '../assets/icons/dot.svg';
import DownloadCloud from '../assets/icons/download-cloud.svg';
import Download from '../assets/icons/download.svg';
import Dribbble from '../assets/icons/dribbble.svg';
import Droplet from '../assets/icons/droplet.svg';
import Duplicate from '../assets/icons/duplicate.svg';
import Edit2 from '../assets/icons/edit-2.svg';
import Edit3 from '../assets/icons/edit-3.svg';
import Edit from '../assets/icons/edit.svg';
import Emoji from '../assets/icons/emoji.svg';
import ExternalLink from '../assets/icons/external-link.svg';
import EyeOff from '../assets/icons/eye-off.svg';
import Eye from '../assets/icons/eye.svg';
import Facebook from '../assets/icons/facebook.svg';
import FastForward from '../assets/icons/fast-forward.svg';
import Feather from '../assets/icons/feather.svg';
import Figma from '../assets/icons/figma.svg';
import FileMinus from '../assets/icons/file-minus.svg';
import FilePlus from '../assets/icons/file-plus.svg';
import FileText from '../assets/icons/file-text.svg';
import File from '../assets/icons/file.svg';
import Filefolder from '../assets/icons/Filefolder.svg';
import Film from '../assets/icons/film.svg';
import Filter from '../assets/icons/filter.svg';
import Flag from '../assets/icons/flag.svg';
import FolderMinus from '../assets/icons/folder-minus.svg';
import FolderPlus from '../assets/icons/folder-plus.svg';
import Folder from '../assets/icons/folder.svg';
import Forward from '../assets/icons/forward.svg';
import Framer from '../assets/icons/framer.svg';
import Frown from '../assets/icons/frown.svg';
import Gallery from '../assets/icons/gallery.svg';
import Gift from '../assets/icons/gift.svg';
import GitBranch from '../assets/icons/git-branch.svg';
import GitCommit from '../assets/icons/git-commit.svg';
import GitMerge from '../assets/icons/git-merge.svg';
import GitPullRequest from '../assets/icons/git-pull-request.svg';
import Github from '../assets/icons/github.svg';
import Gitlab from '../assets/icons/gitlab.svg';
import Globe from '../assets/icons/globe.svg';
import Google from '../assets/icons/google.svg';
import Grid from '../assets/icons/grid.svg';
import Grid2 from '../assets/icons/grid2.svg';
import HCircle from '../assets/icons/h-circle.svg';
import HardDrive from '../assets/icons/hard-drive.svg';
import Hash from '../assets/icons/hash.svg';
import HashSearch from '../assets/icons/hashsearch.svg';
import Headphones from '../assets/icons/headphones.svg';
import Heart from '../assets/icons/heart.svg';
import HelpCircle from '../assets/icons/help-circle.svg';
import Hexagon from '../assets/icons/hexagon.svg';
import Home from '../assets/icons/home.svg';
import IconStart1 from '../assets/icons/iconStart-1.svg';
import IconStart10 from '../assets/icons/iconStart-10.svg';
import IconStart11 from '../assets/icons/iconStart-11.svg';
import IconStart12 from '../assets/icons/iconStart-12.svg';
import IconStart13 from '../assets/icons/iconStart-13.svg';
import IconStart14 from '../assets/icons/iconStart-14.svg';
import IconStart15 from '../assets/icons/iconStart-15.svg';
import IconStart16 from '../assets/icons/iconStart-16.svg';
import IconStart17 from '../assets/icons/iconStart-17.svg';
import IconStart18 from '../assets/icons/iconStart-18.svg';
import IconStart19 from '../assets/icons/iconStart-19.svg';
import IconStart2 from '../assets/icons/iconStart-2.svg';
import IconStart20 from '../assets/icons/iconStart-20.svg';
import IconStart21 from '../assets/icons/iconStart-21.svg';
import IconStart22 from '../assets/icons/iconStart-22.svg';
import IconStart23 from '../assets/icons/iconStart-23.svg';
import IconStart24 from '../assets/icons/iconStart-24.svg';
import IconStart25 from '../assets/icons/iconStart-25.svg';
import IconStart26 from '../assets/icons/iconStart-26.svg';
import IconStart27 from '../assets/icons/iconStart-27.svg';
import IconStart28 from '../assets/icons/iconStart-28.svg';
import IconStart29 from '../assets/icons/iconStart-29.svg';
import IconStart3 from '../assets/icons/iconStart-3.svg';
import IconStart30 from '../assets/icons/iconStart-30.svg';
import IconStart31 from '../assets/icons/iconStart-31.svg';
import IconStart32 from '../assets/icons/iconStart-32.svg';
import IconStart33 from '../assets/icons/iconStart-33.svg';
import IconStart34 from '../assets/icons/iconStart-34.svg';
import IconStart35 from '../assets/icons/iconStart-35.svg';
import IconStart36 from '../assets/icons/iconStart-36.svg';
import IconStart37 from '../assets/icons/iconStart-37.svg';
import IconStart38 from '../assets/icons/iconStart-38.svg';
import IconStart39 from '../assets/icons/iconStart-39.svg';
import IconStart4 from '../assets/icons/iconStart-4.svg';
import IconStart5 from '../assets/icons/iconStart-5.svg';
import IconStart6 from '../assets/icons/iconStart-6.svg';
import IconStart7 from '../assets/icons/iconStart-7.svg';
import IconStart8 from '../assets/icons/iconStart-8.svg';
import IconStart9 from '../assets/icons/iconStart-9.svg';
import IconStart0 from '../assets/icons/iconStart.svg';
import Image from '../assets/icons/image.svg';
import Inbox from '../assets/icons/inbox.svg';
import Info from '../assets/icons/info.svg';
import Instagram from '../assets/icons/instagram.svg';
import Invoice from '../assets/icons/invoice.svg';
import Italic from '../assets/icons/italic.svg';
import Key from '../assets/icons/key.svg';
import Layers from '../assets/icons/layers.svg';
import Layout from '../assets/icons/layout.svg';
import Leave from '../assets/icons/leave.svg';
import LifeBuoy from '../assets/icons/life-buoy.svg';
import LightbulbAlt1 from '../assets/icons/lightbulb-alt 1.svg';
import Link2 from '../assets/icons/link-2.svg';
import Link from '../assets/icons/link.svg';
import LinkColour from '../assets/icons/link_color.svg';
import Linkedin from '../assets/icons/linkedin.svg';
import List from '../assets/icons/list.svg';
import Loader from '../assets/icons/loader.svg';
import Lock from '../assets/icons/lock.svg';
import LogIn from '../assets/icons/log-in.svg';
import LogOut from '../assets/icons/log-out.svg';
import LogoInitials from '../assets/icons/logo-initials.svg';
import Logo from '../assets/icons/logo.svg';
import Mail from '../assets/icons/mail.svg';
import MapPin from '../assets/icons/map-pin.svg';
import Map from '../assets/icons/map.svg';
import MapFlat from '../assets/icons/mapFlat.svg';
import MasterCard from '../assets/icons/masterCard.svg';
import Maximize2 from '../assets/icons/maximize-2.svg';
import Maximize from '../assets/icons/maximize.svg';
import Meh from '../assets/icons/meh.svg';
import Menu from '../assets/icons/menu.svg';
import MessageCircle from '../assets/icons/message-circle.svg';
import MessageDot from '../assets/icons/message-dot.svg';
import MessageSquare from '../assets/icons/message-square.svg';
import Message1 from '../assets/icons/message1.svg';
import Message21 from '../assets/icons/message21.svg';
import MicOff from '../assets/icons/mic-off.svg';
import Mic from '../assets/icons/mic.svg';
import Minimize2 from '../assets/icons/minimize-2.svg';
import Minimize from '../assets/icons/minimize.svg';
import MinusCircle from '../assets/icons/minus-circle.svg';
import MinusSquare from '../assets/icons/minus-square.svg';
import Minus from '../assets/icons/minus.svg';
import Monitor from '../assets/icons/monitor.svg';
import Moon from '../assets/icons/moon.svg';
import MoreHorizontal from '../assets/icons/more-horizontal.svg';
import MoreVertical from '../assets/icons/more-vertical.svg';
import MousePointer from '../assets/icons/mouse-pointer.svg';
import Move from '../assets/icons/move.svg';
import Move1 from '../assets/icons/move1.svg';
import Music from '../assets/icons/music.svg';
import Mute from '../assets/icons/mute.svg';
import Navigation2 from '../assets/icons/navigation-2.svg';
import Navigation from '../assets/icons/navigation.svg';
import Notification from '../assets/icons/notification.svg';
import Np_helmet from '../assets/icons/np_helmet.svg';
import Octagon from '../assets/icons/octagon.svg';
import Office from '../assets/icons/office.svg';
import Package from '../assets/icons/package.svg';
import Paperclip from '../assets/icons/paperclip.svg';
import PauseCircle from '../assets/icons/pause-circle.svg';
import Pause from '../assets/icons/pause.svg';
import PenTool from '../assets/icons/pen-tool.svg';
import Pending from '../assets/icons/pending.svg';
import Percent from '../assets/icons/percent.svg';
import PhoneCall from '../assets/icons/phone-call.svg';
import PhoneForwarded from '../assets/icons/phone-forwarded.svg';
import PhoneIncoming from '../assets/icons/phone-incoming.svg';
import PhoneMissed from '../assets/icons/phone-missed.svg';
import PhoneOff from '../assets/icons/phone-off.svg';
import PhoneOutgoing from '../assets/icons/phone-outgoing.svg';
import Phone from '../assets/icons/phone.svg';
import PieChart from '../assets/icons/pie-chart.svg';
import Pin from '../assets/icons/pin.svg';
import Pin2 from '../assets/icons/pin2.svg';
import PlayCircle from '../assets/icons/play-circle.svg';
import Play from '../assets/icons/play.svg';
import PlusBold from '../assets/icons/plus-bold.svg';
import PlusCircle from '../assets/icons/plus-circle.svg';
import PlusSquare from '../assets/icons/plus-square.svg';
import Plus from '../assets/icons/plus.svg';
import PlusColor from '../assets/icons/plus_color.svg';
import Pocket from '../assets/icons/pocket.svg';
import Power from '../assets/icons/power.svg';
import Printer from '../assets/icons/printer.svg';
import Project from '../assets/icons/project.svg';
import Radio from '../assets/icons/radio.svg';
import Read from '../assets/icons/read.svg';
import Recording from '../assets/icons/recording.svg';
import RecordingCircle from '../assets/icons/recordingCircle.svg';
import RefreshCcw from '../assets/icons/refresh-ccw.svg';
import RefreshCw from '../assets/icons/refresh-cw.svg';
import Repeat from '../assets/icons/repeat.svg';
import Reply from '../assets/icons/reply.svg';
import Retry from '../assets/icons/retry.svg';
import Rewind from '../assets/icons/rewind.svg';
import RotateCcw from '../assets/icons/rotate-ccw.svg';
import RotateCw from '../assets/icons/rotate-cw.svg';
import Rss from '../assets/icons/rss.svg';
import Save from '../assets/icons/save.svg';
import Scissors from '../assets/icons/scissors.svg';
import Scribble from '../assets/icons/scribble.svg';
import Search from '../assets/icons/search.svg';
import Selected from '../assets/icons/selected.svg';
import Send from '../assets/icons/send.svg';
import Send_v2 from '../assets/icons/send_v2.svg';
import Server from '../assets/icons/server.svg';
import Settings from '../assets/icons/settings.svg';
import Share2 from '../assets/icons/share-2.svg';
import Share from '../assets/icons/share.svg';
import ShieldOff from '../assets/icons/shield-off.svg';
import Shield from '../assets/icons/shield.svg';
import ShoppingBag from '../assets/icons/shopping-bag.svg';
import ShoppingCart from '../assets/icons/shopping-cart.svg';
import Shuffle from '../assets/icons/shuffle.svg';
import Sidebar from '../assets/icons/sidebar.svg';
import SkipBack from '../assets/icons/skip-back.svg';
import SkipForward from '../assets/icons/skip-forward.svg';
import Slack from '../assets/icons/slack.svg';
import Slash from '../assets/icons/slash.svg';
import Sliders from '../assets/icons/sliders.svg';
import Smartphone from '../assets/icons/smartphone.svg';
import Smile from '../assets/icons/smile.svg';
import Sort from '../assets/icons/sort.svg';
import Sort2 from '../assets/icons/sort2.svg';
import Speaker from '../assets/icons/speaker.svg';
import Square from '../assets/icons/square.svg';
import Star2 from '../assets/icons/star 2.svg';
import Star from '../assets/icons/star.svg';
import StateDisabled from '../assets/icons/state-disabled.svg';
import StateNo from '../assets/icons/state-no.svg';
import StateYes from '../assets/icons/state-yes.svg';
import StatusHigh from '../assets/icons/status-high.svg';
import StatusMedium from '../assets/icons/status-medium.svg';
import StopCircle from '../assets/icons/stop-circle.svg';
import Sun from '../assets/icons/sun.svg';
import Sunrise from '../assets/icons/sunrise.svg';
import Sunset from '../assets/icons/sunset.svg';
import Tablet from '../assets/icons/tablet.svg';
import Tag from '../assets/icons/tag.svg';
import Target from '../assets/icons/target.svg';
import Terminal from '../assets/icons/terminal.svg';
import TextMessage from '../assets/icons/textMessage.svg';
import Thermometer from '../assets/icons/thermometer.svg';
import ThumbsDown from '../assets/icons/thumbs-down.svg';
import ThumbsUp from '../assets/icons/thumbs-up.svg';
import ToggleLeft from '../assets/icons/toggle-left.svg';
import ToggleRight from '../assets/icons/toggle-right.svg';
import Tool from '../assets/icons/tool.svg';
import Trash2 from '../assets/icons/trash-2.svg';
import Trash from '../assets/icons/trash.svg';
import Trello from '../assets/icons/trello.svg';
import TrendingDown from '../assets/icons/trending-down.svg';
import TrendingUp from '../assets/icons/trending-up.svg';
import Triangle from '../assets/icons/triangle.svg';
import Trophy from '../assets/icons/trophy.svg';
import Truck from '../assets/icons/truck.svg';
import TTSquare from '../assets/icons/tt-square.svg';
import TTProject from '../assets/icons/tt-tag.svg';
import TT from '../assets/icons/tt.svg';
import TT20 from '../assets/icons/tt20.svg';
import TT24 from '../assets/icons/tt24.svg';
import TT32 from '../assets/icons/tt32.svg';
import TT40 from '../assets/icons/tt40.svg';
import TT48 from '../assets/icons/tt48.svg';
import Tv from '../assets/icons/tv.svg';
import Twitch from '../assets/icons/twitch.svg';
import Twitter from '../assets/icons/twitter.svg';
import Type from '../assets/icons/type.svg';
import Umbrella from '../assets/icons/umbrella.svg';
import Underline from '../assets/icons/underline.svg';
import Unlock from '../assets/icons/unlock.svg';
import UploadCloud from '../assets/icons/upload-cloud.svg';
import Upload from '../assets/icons/upload.svg';
import UserCheck from '../assets/icons/user-check.svg';
import UserCircle from '../assets/icons/user-circle.svg';
import UserMinus from '../assets/icons/user-minus.svg';
import UserPlus from '../assets/icons/user-plus.svg';
import UserX from '../assets/icons/user-x.svg';
import User from '../assets/icons/user.svg';
import Users from '../assets/icons/users.svg';
import VideoOff from '../assets/icons/video-off.svg';
import Video from '../assets/icons/video.svg';
import Visa from '../assets/icons/visa.svg';
import Voicemail from '../assets/icons/voicemail.svg';
import Volume1 from '../assets/icons/volume-1.svg';
import Volume2 from '../assets/icons/volume-2.svg';
import VolumeX from '../assets/icons/volume-x.svg';
import Volume from '../assets/icons/volume.svg';
import Watch from '../assets/icons/watch.svg';
import WifiOff from '../assets/icons/wifi-off.svg';
import Wifi from '../assets/icons/wifi.svg';
import Wind from '../assets/icons/wind.svg';
import Winners from '../assets/icons/winners.svg';
import XCircle from '../assets/icons/x-circle.svg';
import XOctagon from '../assets/icons/x-octagon.svg';
import XSquare from '../assets/icons/x-square.svg';
import X from '../assets/icons/x.svg';
import X2 from '../assets/icons/x2.svg';
import X3 from '../assets/icons/x3.svg';
import Youtube from '../assets/icons/youtube.svg';
import ZapOff from '../assets/icons/zap-off.svg';
import Zap from '../assets/icons/zap.svg';
import ZoomIn from '../assets/icons/zoom-in.svg';
import ZoomOut from '../assets/icons/zoom-out.svg';

const images = {
  Activity1,
  Airplay,
  AlertCircle,
  AlertTriangle,
  AlignJustify,
  AlignCenter,
  AlertOctagon,
  AmericanExpress,
  Anchor,
  AlignRight,
  AlignLeft,
  Arrow,
  ArrowDownCircle,
  Aperture,
  Apple,
  Apple24,
  ArrowDownLeft,
  ArrowDownRight,
  ArrowLeftCircle,
  ArrowLeft,
  ArrowRightCircle,
  ArrowDown,
  ArrowDown1,
  ArrowDownNarrow,
  ArrowUpNarrow,
  ArrowRight,
  ArrowUpLeft,
  ArrowUpLeft2,
  ArrowUpCircle,
  Archive,
  ArrowUpRight,
  ArrowUp,
  ArrowsRefresh,
  Award,
  AtSign,
  BarChart,
  BatteryCharging,
  BellOff,
  BarChart2,
  Bill,
  Bell,
  Battery,
  Bluetooth,
  Book,
  BookOpen,
  Bookmark,
  Briefcase,
  Box,
  BuildingHomeHouse,
  Calendar,
  CameraOff,
  Camera,
  CreateTeam,
  CreateTeamSuccess,
  Bold,
  CheckCircle,
  Cast,
  Chat,
  ChatNew,
  Check,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronsDown,
  ChevronUp,
  CheckSquare,
  ChevronsLeft,
  Chrome,
  ChevronsRight,
  Clipboard,
  ChevronsUp,
  Clock,
  Close,
  Circle,
  CloudLightning,
  CloudOff,
  CloudDrizzle,
  CloudSnow,
  Cloud,
  CloudRain,
  Codepen,
  Codesandbox,
  Coffee,
  Code,
  Columns,
  Copy,
  Command,
  CornerDownLeft,
  CornerDownRight,
  CornerLeftUp,
  CornerLeftDown,
  CornerRightDown,
  CornerUpLeft,
  CornerRightUp,
  Counter,
  CornerUpRight,
  Compass,
  CreditCard,
  Crop,
  Crosshair,
  Delete,
  Database,
  DivideCircle,
  Cpu,
  Disc,
  Discover,
  DivideSquare,
  DownloadCloud,
  Divide,
  DollarSign,
  Dot,
  Droplet,
  Dribbble,
  Duplicate,
  Download,
  Edit,
  Edit2,
  Emoji,
  ExternalLink,
  EyeOff,
  Edit3,
  Facebook,
  FastForward,
  Feather,
  Eye,
  FileMinus,
  FileText,
  Figma,
  FilePlus,
  Filefolder,
  Film,
  Filter,
  FolderMinus,
  Flag,
  FolderPlus,
  Folder,
  Forward,
  Frown,
  File,
  Framer,
  Gallery,
  GitBranch,
  Gift,
  GitMerge,
  GitPullRequest,
  Github,
  GitCommit,
  Grid,
  Gitlab,
  HardDrive,
  Globe,
  Headphones,
  Grid2,
  Google,
  Hash,
  HashSearch,
  Heart,
  Hexagon,
  IconStart0,
  IconStart1,
  IconStart2,
  IconStart3,
  IconStart4,
  IconStart5,
  IconStart6,
  IconStart7,
  IconStart8,
  IconStart9,
  IconStart10,
  IconStart11,
  IconStart12,
  IconStart13,
  IconStart14,
  IconStart15,
  IconStart16,
  IconStart17,
  IconStart18,
  IconStart19,
  IconStart20,
  IconStart21,
  IconStart22,
  IconStart23,
  IconStart24,
  IconStart25,
  IconStart26,
  IconStart27,
  IconStart28,
  IconStart29,
  IconStart30,
  IconStart31,
  IconStart32,
  IconStart33,
  IconStart34,
  IconStart35,
  IconStart36,
  IconStart37,
  IconStart38,
  IconStart39,
  Image,
  HelpCircle,
  Home,
  Inbox,
  Instagram,
  Info,
  Italic,
  Layers,
  Invoice,
  LifeBuoy,
  LightbulbAlt1,
  Layout,
  Key,
  Link2,
  Linkedin,
  Loader,
  Link,
  LinkColour,
  LogIn,
  List,
  Leave,
  Lock,
  Logo,
  LogoInitials,
  LogOut,
  Map,
  MapPin,
  Mail,
  MapFlat,
  MasterCard,
  Maximize2,
  Maximize,
  AssignTaskMsg1,
  AssignTaskMsg2,
  AssignTaskMsg3,
  Message1,
  Message21,
  MessageCircle,
  MessageDot,
  Menu,
  MessageSquare,
  Mic,
  MicOff,
  Minimize2,
  MinusCircle,
  Meh,
  MinusSquare,
  Minimize,
  Monitor,
  Moon,
  Minus,
  MoreHorizontal,
  MoreVertical,
  MousePointer,
  Move,
  Move1,
  Music,
  Navigation2,
  Navigation,
  Notification,
  Np_helmet,
  Office,
  Octagon,
  Paperclip,
  Mute,
  Package,
  PauseCircle,
  Pause,
  PenTool,
  Percent,
  PhoneCall,
  PhoneForwarded,
  PhoneIncoming,
  PhoneOff,
  Pending,
  PhoneMissed,
  PhoneOutgoing,
  Pin,
  Pin2,
  PieChart,
  PlayCircle,
  Phone,
  PlusCircle,
  PlusSquare,
  Pocket,
  Project,
  Plus,
  PlusColor,
  Play,
  Power,
  RefreshCcw,
  RefreshCw,
  Repeat,
  Printer,
  Radio,
  Read,
  Recording,
  RecordingCircle,
  Rewind,
  RotateCw,
  RotateCcw,
  Rss,
  Scissors,
  Send,
  Search,
  Save,
  Scribble,
  Send_v2,
  Settings,
  Share,
  Share2,
  ShieldOff,
  Shield,
  ShoppingBag,
  ShoppingCart,
  Sidebar,
  Shuffle,
  SkipBack,
  SkipForward,
  Slash,
  Server,
  Sliders,
  Smartphone,
  Smile,
  Speaker,
  Square,
  Slack,
  Star2,
  Star,
  StateDisabled,
  StateNo,
  StateYes,
  StopCircle,
  Sunrise,
  Sort,
  Sort2,
  Sunset,
  Sun,
  Tablet,
  Terminal,
  Tag,
  Thermometer,
  ThumbsUp,
  ThumbsDown,
  ToggleRight,
  Tool,
  ToggleLeft,
  Trash,
  Target,
  TextMessage,
  TrendingDown,
  TrendingUp,
  Triangle,
  Trophy,
  Truck,
  Trello,
  Tv,
  Twitch,
  Twitter,
  Umbrella,
  Trash2,
  Underline,
  UploadCloud,
  Upload,
  Unlock,
  UserCheck,
  UserCircle,
  UserPlus,
  UserMinus,
  User,
  UserX,
  VideoOff,
  Users,
  Voicemail,
  TTProject,
  Type,
  Volume1,
  VolumeX,
  Volume2,
  Volume,
  WifiOff,
  Video,
  Wind,
  Visa,
  Watch,
  XOctagon,
  XCircle,
  X,
  XSquare,
  Youtube,
  Wifi,
  X2,
  X3,
  ZapOff,
  ZoomOut,
  Zap,
  ZoomIn,
  Selected,
  TT,
  ArrowDownLong,
  ArrowUpLong,
  NoFiles,
  NoSearchTask,
  NoProjects,
  NoSearchProjects,
  NoTasks,
  WinnerIsometric,
  WinnerIsometric1,
  WinnerIsometric2,
  WinnerTwoColor,
  CheckBoxSelected,
  TT20,
  TT24,
  TT32,
  TT40,
  TT48,
  HCircle,
  NoChat,
  Winners,
  StatusHigh,
  StatusMedium,
  TTSquare,
  Reply,
  PlusBold,
  Retry,
  crossCircle,
  NoProjectsMob,
  NoTasksMob,
  NoFilesMob,
  NoSearchProjectsColor,
  NoSearchResults,
  Present,
};

export default images;
