import React from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList } from 'react-native';

import { SimpleHeader } from '@components/Headers/SimpleHeader.web';
import { WebModal } from '@components/Modals/WebModal';
import { Box } from '@components/Restyle';
import Button from '@components/shared/Button/Button';
import { MemberRole, MemberType } from '@components/User/MemberRole';
import { TeamRole } from '@graphql/generated';
import { selectableTeamRoles } from '@utils/teams';

type RoleModalProps = {
  title: string;
  buttonLabel?: string;
  onClose?: () => void;
  updateMemberRole?: (id: string, role: TeamRole) => void;
  list: MemberType<TeamRole>[];
};

export const RoleModal: React.FC<RoleModalProps> = ({
  title,
  buttonLabel: buttonLabelProp,
  onClose,
  list,
  updateMemberRole,
}) => {
  const { t } = useTranslation();

  const buttonLabel = buttonLabelProp || t('shared:done');
  return (
    <WebModal
      visible
      onClose={onClose}
      width={504}
      height={(list.length && 512) || undefined}>
      <SimpleHeader title={title} onClose={onClose} />

      <Box>
        <FlatList
          data={list}
          renderItem={({ item }) => {
            return (
              <Box marginLeft='m' marginRight='l'>
                <MemberRole<TeamRole>
                  roles={selectableTeamRoles.filter(
                    (role) => role != TeamRole.Owner
                  )}
                  member={item}
                  updateRole={
                    item.role !== TeamRole.Admin &&
                    item.role !== TeamRole.Member
                      ? undefined
                      : (role: TeamRole) => {
                          updateMemberRole?.(item.user.id, role);
                        }
                  }
                />
              </Box>
            );
          }}
          keyExtractor={(item) => item.user.id}
          ItemSeparatorComponent={() => <Box marginTop='m' />}
          ListFooterComponent={() => <Box marginBottom='listFooter' />}
          ListHeaderComponent={() => <Box marginTop='m' />}
        />
      </Box>
      <Box padding='m' flexDirection='row' justifyContent='flex-end'>
        <Button variant='primary' onPress={onClose}>
          {buttonLabel || t('shared:done')}
        </Button>
      </Box>
    </WebModal>
  );
};
