import { StackActions, useNavigation } from '@react-navigation/native';
import { useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';

import { SimpleHeader } from '@components/Headers/SimpleHeader.web';
import { WebModal } from '@components/Modals/WebModal';
import { Box } from '@components/Restyle';
import Button from '@components/shared/Button/Button';
import { Team } from '@graphql/generated';
import { FormValues } from '@navigation/payments/create-subscription-stack';
// import CreateTeam from '@screens/Teams/CreateTeam';
import CreateTeamNew from '@screens/Teams/CreateTeamNew';
import CreateTeamNewWeb from '@screens/Teams/CreateTeamNew.web';
import { useFilePicker } from '@utils/filePicker';

type TeamsModalProps = {
  title: string;
  buttonLabel?: string;
  onClose?: () => void;
  teams?: Team[];
};

export const TeamsModal: React.FC<TeamsModalProps> = ({
  title,
  buttonLabel: buttonLabelProp,
  onClose: onCloseProp,
}) => {
  const { t } = useTranslation();
  const navigator = useNavigation();
  const { launchImageSelection } = useFilePicker();
  const { setFieldValue, getFieldProps } = useFormikContext<FormValues>();
  const { name: fieldName } = getFieldProps('logo');

  const buttonLabel = buttonLabelProp || t('shared:done');

  const onClose = onCloseProp || navigator.goBack;
  const addLogo = async () => {
    await launchImageSelection(1).then((r) => {
      if (r && r.length > 0) {
        setFieldValue(fieldName, r[0]);
      }
    });
  };
  return (
    <WebModal
      visible
      onClose={onClose}
      width={Platform.OS === 'web' ? 504 : 604}>
      <SimpleHeader title={title} onClose={onClose} />

      <Box>
        {Platform.OS === 'web' && (
          <CreateTeamNewWeb
            onAddLogo={addLogo}
            onClose={onClose}
            editing={title.includes('Edit')}
          />
        )}
        {Platform.OS !== 'web' && (
          <CreateTeamNew
            onAddLogo={addLogo}
            onAddSkills={() =>
              navigator.dispatch(StackActions.replace('skill-select'))
            }
            showCreateButton={false}
          />
        )}
      </Box>
      {Platform.OS !== 'web' && (
        <Box padding='m' flexDirection='row' justifyContent='flex-end'>
          <Button
            variant='primary'
            onPress={onClose}
            accessibilityLabel={buttonLabel || t('shared:done')}>
            {buttonLabel || t('shared:done')}
          </Button>
        </Box>
      )}
    </WebModal>
  );
};
