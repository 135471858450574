import { getDocumentAsync } from 'expo-document-picker';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';
import { useToast } from 'react-native-toast-notifications';

import { Alert } from '@components/Alert';
import { LocalFile } from '@graphql/generated';
import { fileToLocalFile } from '@utils/fileUtils';
import { useFileValidation } from '@utils/fileValidationUtils';
import { selectFiles } from '@utils/selectFiles';
import { selectImageFromGallery } from '@utils/selectImage';

export const useFilePicker = () => {
  const { t } = useTranslation();
  const { isValidExtension } = useFileValidation();
  const toast = useToast();

  // Show a toast notification whenever a file is selected, based on the showToast flag
  const showFileSelectedToast = (files: LocalFile[], showToast: boolean) => {
    if (!showToast) return;
    const videos = files.filter((i) => i.contentType.includes('video'));
    if (videos.length > 0) {
      toast.show(`Media selected`, { type: 'normal', duration: 1000 });
    }
  };

  return {
    launchImageSelection: (
      selectionLimit?: ImageSelectionOptions['selectionLimit'],
      showToast = true
    ) =>
      launchImageSelection({
        denyAccessTitle: t('accessDenied.camera.title'),
        denyAccessMessage: t('accessDenied.camera.message'),
        selectionLimit,
        isValidExtension,
        showFileSelectedToast,
        showToast,
      }),
    launchFileSelection: (selectionLimit?: number, showToast = true) =>
      launchFileSelection(
        isValidExtension,
        selectionLimit,
        showFileSelectedToast,
        showToast
      ),
  };
};

type ImageSelectionOptions = {
  denyAccessTitle: string;
  denyAccessMessage: string;
  selectionLimit?: number;
  isValidExtension: (contentType: string) => Promise<boolean>;
  showFileSelectedToast: (files: LocalFile[], showToast: boolean) => void;
  showToast: boolean;
};

const launchImageSelection = (options: ImageSelectionOptions) => {
  return Platform.select({
    web: async ({
      selectionLimit,
      isValidExtension,
      showFileSelectedToast,
      showToast,
    }: ImageSelectionOptions) =>
      launchFileSelectionWeb(
        isValidExtension,
        'photo',
        selectionLimit,
        showFileSelectedToast,
        showToast
      ),
    default: async ({
      selectionLimit,
      isValidExtension,
      showFileSelectedToast,
      showToast,
    }: ImageSelectionOptions) => {
      const images = await selectImageFromGallery(selectionLimit);
      if (images) showFileSelectedToast(images, showToast);
      return validateFiles(images, isValidExtension);
    },
  })(options);
};

const launchFileSelection = (
  isValidExtension: (contentType: string) => Promise<boolean>,
  selectionLimit?: number,
  showFileSelectedToast?: (files: LocalFile[], showToast: boolean) => void,
  showToast?: boolean
) => {
  return Platform.select({
    web: async () =>
      launchFileSelectionWeb(
        isValidExtension,
        'file',
        selectionLimit,
        showFileSelectedToast,
        showToast
      ),
    default: async () => {
      const files = await selectFiles(!!selectionLimit && selectionLimit > 1);
      if (files && showFileSelectedToast)
        showFileSelectedToast(files, showToast || false);
      return validateFiles(files, isValidExtension);
    },
  })();
};

const launchFileSelectionWeb = async (
  isValidExtension: (contentType: string) => Promise<boolean>,
  type: 'photo' | 'file' = 'photo',
  selectionLimit?: number,
  showFileSelectedToast?: (files: LocalFile[], showToast: boolean) => void,
  showToast?: boolean
): Promise<LocalFile[] | undefined> => {
  const result = await getDocumentAsync({
    multiple: selectionLimit === undefined || selectionLimit > 1,
    type: type === 'photo' ? 'image/*,video/*' : undefined,
  });
  if (result.type === 'cancel' || !result.output) {
    return;
  }

  const files = Array.from(result.output);

  if (files && (!selectionLimit || files.length <= selectionLimit)) {
    const localFiles = await Promise.all(
      files.map((file) => fileToLocalFile(file))
    );
    if (showFileSelectedToast)
      showFileSelectedToast(localFiles, showToast || false);
    return validateFiles(localFiles, isValidExtension);
  } else if (selectionLimit && files.length > selectionLimit) {
    Alert.alert(`Max selection limit of ${selectionLimit} has been reached`);
  }

  return;
};

// Validation function to check if all selected files are valid
const validateFiles = async (
  files: LocalFile[] | undefined,
  isValidExtension: (contentType: string) => Promise<boolean>
): Promise<LocalFile[] | undefined> => {
  if (!files) return;

  const validationResults = await Promise.all(
    files.map(async (file) => await isValidExtension(file.contentType))
  );

  const invalidFiles = files.filter((_, index) => !validationResults[index]);

  if (invalidFiles.length > 0) {
    Alert.alert(
      'Upload failed',
      'The selected file type is not supported for upload. Please check the allowed file types https://tasktag.com/faq.'
    );

    return undefined;
  }

  return files;
};
