import React, { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, TouchableOpacity } from 'react-native';

import theme from '@themes/theme';

import Images from '../../../../themes/images';
import { Box, Text } from '../../Restyle';
import Icon from '../Icon/Icon';
import Tag from '../Tags/Tag';

export type MenuItem = {
  name: string;
  iconName?: keyof typeof Images;
  isRed?: boolean;
  isComingSoon?: boolean;
  onSelect?: (item: string) => Promise<void | undefined> | void | undefined;
  isSelected?: boolean;
  accessibilityLabel?: string;
} & Pick<ComponentProps<typeof Text>, 'numberOfLines'>;

export type PopupItemProps = {
  menuItem: MenuItem;
  closePopFn?: () => void;
  marginIconToText?: keyof typeof theme.spacing;
};

const PopupItem: React.FC<PopupItemProps> = (props) => {
  const { t } = useTranslation('shared');
  const { menuItem, closePopFn, marginIconToText = 'm' } = props;
  const {
    iconName,
    name,
    isRed = false,
    isComingSoon = false,
    onSelect,
    isSelected,
    numberOfLines,
    accessibilityLabel,
  } = menuItem;

  return (
    <TouchableOpacity
      disabled={isComingSoon}
      accessibilityLabel={accessibilityLabel ? accessibilityLabel : name}
      onPress={() => {
        if (closePopFn) {
          closePopFn();
        }
        if (onSelect) {
          onSelect(name);
        }
      }}>
      <Box
        accessibilityLabel={name}
        marginBottom='m'
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'>
        {iconName && (
          <Icon
            name={iconName}
            variant={Platform.OS === 'web' ? 's' : 'l'}
            marginRight={marginIconToText}
            color={isRed ? 'alertRed' : 'textPrimary'}
          />
        )}

        <Text
          variant={Platform.OS === 'web' ? 'metadata1' : 'labelLarge'}
          color={isRed ? 'alertRed' : 'textPrimary'}
          numberOfLines={numberOfLines}
          style={{ flex: 1, letterSpacing: Platform.OS === 'web' ? 0.2 : 0 }}>
          {name}
        </Text>

        {isComingSoon && (
          <Tag
            variant='secondary'
            label={t('comingSoon')}
            borderRadius='xxs'
            padding='xxs'
            marginLeft='m'
          />
        )}
        {isSelected && <Icon name='Check' variant='s' color='greenSecondary' />}
      </Box>
    </TouchableOpacity>
  );
};

export default PopupItem;
