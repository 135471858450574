import { object, string, array } from "yup";

import { locationSchema } from "@schemas/locationSchema";

export const createTeamSchema = object({
  logo: object().nullable(),
  name: string().required("Please fill in the field"),
  location: locationSchema.nullable(),
  skillIds: array().of(string()).ensure(),
});
