import { StackActions } from '@react-navigation/native';
import * as Clipboard from 'expo-clipboard';
import React from 'react';
import { FlatList } from 'react-native';
import { useToast } from 'react-native-toast-notifications';

import { Box } from '@components/Restyle';
import ListNavigationItem, {
  ListNavigationItemProps,
} from '@components/shared/ListNavigationItem/ListNavigationItem';
import { useCreateTeamInvitationMutation } from '@graphql/generated';
import { useAppNavigation } from '@navigation/useAppNavigation';

interface TeamNavListProps {
  teamId: string;
  listHeaderComponent: () => JSX.Element;
  itemSeparatorComponent: () => JSX.Element;
}

const TeamNavList: React.FC<TeamNavListProps> = ({
  teamId,
  listHeaderComponent,
  itemSeparatorComponent,
}) => {
  const appNavigation = useAppNavigation();
  const toast = useToast();
  const push = StackActions.push;

  const [createTeamInvitationMutation] = useCreateTeamInvitationMutation({
    onCompleted: ({
      createTeamInvitation,
    }: {
      createTeamInvitation: string;
    }) => {
      if (createTeamInvitation) {
        const formattedLink =
          createTeamInvitation.replace(
            'register/register-with-email',
            'invited-user'
          ) + `&type=team`;
        copyToClipboard(formattedLink);
      }
    },
  });

  const handleInviteToTeamPress = () => {
    createTeamInvitationMutation({ variables: { id: teamId } });
  };

  const copyToClipboard = async (link: string) => {
    if (!link) return; // Early return if link is falsy
    await Clipboard.setStringAsync(link);

    toast.show('Copied', {
      duration: 2000,
    });
  };

  const allNavItems: ListNavigationItemProps[] = [
    {
      title: 'Members',
      description: 'Team Members',
      iconName: 'Users',
      iconBackgroundColor: 'grey01',
      // disabled: !team.plan,
      onPress: () => {
        appNavigation.dispatch(push('team-members', { teamId: teamId }));
      },
    },
    {
      title: 'Analytics',
      description: 'See how the storage is used',
      iconName: 'PieChart',
      iconBackgroundColor: 'grey01',
      disabled: false,
      comingSoon: false,
      onPress: () => {
        appNavigation.navigate('team-analytics', {
          teamId: teamId,
        });
      },
    },
    {
      title: 'Invite to Team',
      description: 'Invite to team via link',
      iconName: 'Link',
      iconBackgroundColor: 'grey01',
      disabled: false,
      comingSoon: false,
      onPress: handleInviteToTeamPress,
    },
    {
      title: 'Preferences',
      description: 'Manage preferences',
      iconName: 'Key',
      iconBackgroundColor: 'grey01',
      disabled: true,
      comingSoon: true,
    },
  ];

  const renderItem = ({ item, index }) => {
    return (
      <Box key={index}>
        <ListNavigationItem {...item} marginBottom='none' />
      </Box>
    );
  };

  return (
    <FlatList
      data={allNavItems}
      renderItem={renderItem}
      ItemSeparatorComponent={itemSeparatorComponent}
      ListHeaderComponent={listHeaderComponent}
    />
  );
};

export default TeamNavList;
