/* eslint-disable @typescript-eslint/no-explicit-any */
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, KeyboardAvoidingView, SectionList } from 'react-native';

import { ProjectTagList } from '@components/Chat/ProjectTagList';
import { TaskTagList } from '@components/Chat/TaskTagList.web';
import EmptyStateNoProjectTag from '@components/EmptyState/EmptyStateNoProjectTag';
import { Box, ShadowBox, Text } from '@components/Restyle';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import Icon from '@components/shared/Icon/Icon';
import {
  SearchableResult,
  Chat,
  ProjectSortOption,
  useSearchTagsLazyQuery,
  Project,
} from '@graphql/generated';
import useChatInput from '@hooks/useChatInput';
import { useListProjectsTasksFromQuery } from '@hooks/useListProjectsTasksFromQuery';
import { useListRecentProjectsTasksFromQuery } from '@hooks/useListProjectsTasksRecentFromQuery';
import useMe from '@hooks/useMe';
import { useProjectMembership } from '@hooks/useProjectMembership';
import { Search, SearchResultType } from '@root/types';
import { ProjectTagType } from '@src/types/project';
import { TaskTagType } from '@src/types/task';
import theme from '@themes/theme';
import { isProjectSearchResult, isTaskSearchResult } from '@utils/typeGuards';

import { MyTagModalHeader } from './MyTagModalHeader.web';

const DEBOUNCE = 500;

interface MyTagModalProps {
  bottomInset?: number;
  filterVal: string;
  chatId: Chat['id'];
}

const PER_PAGE_COUNT = 10;

export const MyTagModal: React.FC<MyTagModalProps> = ({
  chatId,
  filterVal: initFilter,
}) => {
  const { t } = useTranslation();
  const { setIsTagModalOpen, setTagsCollection, getTagsCollection } =
    useChatInput();
  const { me } = useMe();

  const [filterVal, setFilterVal] = useState(initFilter);
  const [loading, setLoading] = useState(false);
  const tagsCollection = getTagsCollection(chatId);

  const updateTagsCollectionProject = (project: ProjectTagType) => {
    if (!me) return;
    const newTagsColl = [
      ...tagsCollection,
      {
        tasks: [],
        project: { ...project },
        author: me,
      },
    ];
    setTagsCollection(chatId, newTagsColl);
    setIsTagModalOpen(false);
  };

  const updateTagsCollectionTasks = (task: TaskTagType) => {
    const collectionToUpdate = tagsCollection?.findIndex(
      (t) => t.project.id === task.project.id
    );

    if (collectionToUpdate !== undefined && collectionToUpdate !== -1) {
      // put task into correct coll based on project

      if (
        !tagsCollection?.[collectionToUpdate].tasks.find(
          (t) => t.id === task.id
        )
      ) {
        const newTagsColl = [...(tagsCollection ?? [])];
        newTagsColl[collectionToUpdate] = {
          ...newTagsColl[collectionToUpdate],
          tasks: [...newTagsColl[collectionToUpdate].tasks, task],
        };

        setTagsCollection(chatId, newTagsColl);
        setIsTagModalOpen(false);
      }
    } else if (me) {
      // new tags coll
      const newTagsColl = [
        ...(tagsCollection ?? []),
        {
          tasks: [task],
          project: task.project,
          author: me,
        },
      ];
      setTagsCollection(chatId, newTagsColl);
      setIsTagModalOpen(false);
    }
  };

  //All project and tasks api

  const {
    projectsTasks: allProjectsTasks,
    refetch: refetchProjects,
    refreshing: refreshingProjects,
    setRefreshing: setRefreshingProjects,
    fetchMoreFromCursor: fetchMoreProjectsFromCursor,
    loading: allProjectsTasksLoading,
    pageInfo,
  } = useListProjectsTasksFromQuery({
    first: PER_PAGE_COUNT,
    sortBy: ProjectSortOption.NameAsc,
    chatId: chatId,
  });

  //recent project and tasks

  const {
    recentProjectsTasks: recentProjectTasks = [],
    loading: recentProjectsTasksLoading,
  } = useListRecentProjectsTasksFromQuery({
    chatId: chatId,
  });

  const projectsTasksData = [
    { title: 'Recent Project & Tasks', data: recentProjectTasks },
    { title: 'All Projects & Tasks', data: allProjectsTasks },
  ];

  const [globalSearch, { data, loading: loadingSearch }] =
    useSearchTagsLazyQuery();
  const searchData = (data?.search as Search<SearchableResult>[]) || [];
  const initialResults = {
    messages: [],
    tasks: [],
    documents: [],
    projects: [],
    contacts: [],
  };

  const results =
    searchData.reduce<SearchResultType>((previous, result) => {
      if (isProjectSearchResult(result))
        return { ...previous, projects: [...previous.projects, result] };
      if (isTaskSearchResult(result))
        return { ...previous, tasks: [...previous.tasks, result] };

      return previous;
    }, initialResults) || initialResults;

  const searchResults = results.projects
    ? results.projects.map((r) => r.record)
    : [];

  const globalSearchCall = () =>
    globalSearch({
      variables: {
        term: filterVal,
        size: 100,
        includeMessages: false,
        includeDocuments: false,
        includeProjects: true,
        includeTasks: false,
        includeChats: false,
        includeTasksTags: true,
        includeCompletedTask: true,
      },
      onCompleted: () => setLoading(false),
      onError: () => setLoading(false),
    });

  const debouncedGlobalSearch = useCallback(
    debounce(globalSearchCall, DEBOUNCE),
    [filterVal]
  );

  useEffect(() => {
    if (filterVal && filterVal.length) {
      setLoading(true);
      debouncedGlobalSearch();
    }
    return () => {
      debouncedGlobalSearch.cancel();
      setLoading(false);
    };
  }, [filterVal]);

  const renderItem = ({ item, section }: { item: any; section: any }) => {
    const isMember = useProjectMembership(item).isMember;
    if (section.title === 'Recent Project & Tasks') {
      const isSelectedTask = tagsCollection?.find((tag) =>
        tag.tasks.find((task) => task.id === item.id)
      );
      return (
        <Box>
          {!item.projectId && (
            <ProjectTagList
              key={item.id}
              loading={false}
              filterVal={filterVal}
              projectTags={[item]}
              isMember={isMember}
              isFrom='Chat'
              onSelect={(newProject: ProjectTagType) =>
                isMember && updateTagsCollectionProject(newProject)
              }
            />
          )}

          {item.projectId && !isSelectedTask && (
            <TaskTagList
              key={item.id}
              filterVal={filterVal}
              taskTags={[item]}
              onSelect={(newTask: TaskTagType) =>
                updateTagsCollectionTasks(newTask)
              }
              loading={false}
            />
          )}
        </Box>
      );
    } else {
      return (
        <Box>
          <ProjectTagList
            key={item.id}
            refreshing={refreshingProjects}
            loading={false}
            onRefresh={() => {
              setRefreshingProjects(true);
              refetchProjects();
            }}
            isMember={isMember}
            filterVal={filterVal}
            projectTags={[item]}
            isFrom='Chat'
            onSelect={(newProject: ProjectTagType) =>
              isMember && updateTagsCollectionProject(newProject)
            }
          />
          {item?.tasks?.map((taskObj) => {
            const isSelectedTask = tagsCollection?.find((tag) =>
              tag.tasks.find((task) => task.id === taskObj.id)
            );
            if (isSelectedTask) return;
            return (
              <TaskTagList
                key={taskObj.id}
                filterVal={filterVal}
                taskTags={[taskObj]}
                onSelect={(newTask: TaskTagType) =>
                  updateTagsCollectionTasks(newTask)
                }
                loading={false}
              />
            );
          })}
        </Box>
      );
    }
  };

  const sectionHeader = ({ section: { title, data } }) => {
    return (
      data &&
      data.length > 0 && (
        <Box padding='xs' backgroundColor='white'>
          <Text color='grey04' variant='bodySecondary' marginLeft='xs'>
            {title}
          </Text>
        </Box>
      )
    );
  };

  const renderFooterUI = (isLoadingMore: boolean) => {
    return (
      <Box marginBottom='listFooter'>
        {isLoadingMore && <ActivityIndicatorLoading />}
      </Box>
    );
  };

  const renderSearch = (item: Project) => {
    const isMember = item.members?.filter((i) => i.user.id === me?.id);
    return (
      <Box>
        <ProjectTagList
          key={item.id}
          refreshing={refreshingProjects}
          loading={false}
          isMember={isMember?.length > 0}
          onRefresh={() => {
            setRefreshingProjects(true);
            refetchProjects();
          }}
          filterVal={filterVal}
          projectTags={[item]}
          isFrom='Chat'
          onSelect={(newProject: ProjectTagType) =>
            isMember?.length > 0 && updateTagsCollectionProject(newProject)
          }
        />

        {item?.tasksTagList?.map((taskObj) => {
          if (taskObj) {
            const isSelectedTask = tagsCollection?.find((tag) =>
              tag.tasks.find((task) => task.id === taskObj.id)
            );
            if (isSelectedTask) return;
            return (
              <TaskTagList
                key={taskObj.id}
                filterVal={filterVal}
                taskTags={[taskObj]}
                onSelect={(newTask: TaskTagType) => {
                  updateTagsCollectionTasks(newTask);
                }}
                loading={false}
              />
            );
          }
        })}
      </Box>
    );
  };

  const showEmptyState = projectsTasksData.every((i) => i.data?.length === 0);

  return (
    <ShadowBox
      accessibilityLabel='Tag modal'
      backgroundColor='white'
      flex={1}
      borderRadius='m'
      borderWidth={1}
      borderColor='grey01'>
      <MyTagModalHeader
        isTagSearch={true}
        filterVal={filterVal}
        onChangeFilterVal={(newFilterVal) => setFilterVal(newFilterVal)}
      />
      <KeyboardAvoidingView style={{ flex: 1 }}>
        {showEmptyState &&
          !allProjectsTasksLoading &&
          !recentProjectsTasksLoading &&
          !filterVal && (
            <Box
              style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: theme.spacing.listFooter,
              }}>
              <EmptyStateNoProjectTag />
            </Box>
          )}

        {!showEmptyState && (
          <>
            {filterVal.length > 0 && loadingSearch && (
              <ActivityIndicatorLoading />
            )}
            {filterVal.length > 0 &&
              !loading &&
              !loadingSearch &&
              searchResults.length === 0 && (
                <Box
                  flex={1}
                  alignItems='center'
                  justifyContent='center'
                  alignContent='center'>
                  <Box
                    flex={1}
                    justifyContent='center'
                    alignItems='center'
                    alignContent='center'
                    flexDirection='column'
                    alignSelf='center'
                    paddingBottom='xxl'
                    paddingTop='xxl'>
                    <Icon name='NoSearchTask' width={141} height={105}></Icon>
                    <Text
                      variant='heading1'
                      textAlign='center'
                      paddingBottom='xs'
                      color='onSurfaceSecondary'>
                      {t('shared:noResults')}
                    </Text>
                    <Text
                      variant='body'
                      textAlign='center'
                      color='onSurfaceSecondary'>
                      {t('models:tasks.filter.activeTasksEmpty')}
                    </Text>
                  </Box>
                </Box>
              )}
            {allProjectsTasksLoading && recentProjectsTasksLoading && (
              <ActivityIndicatorLoading />
            )}
            {projectsTasksData.length !== 0 && filterVal.length === 0 && (
              <SectionList
                showsVerticalScrollIndicator={true}
                sections={projectsTasksData}
                keyboardShouldPersistTaps='always'
                renderItem={renderItem}
                renderSectionHeader={sectionHeader}
                initialNumToRender={10}
                ListFooterComponent={() =>
                  renderFooterUI(pageInfo?.hasNextPage || false)
                }
                onEndReached={() => {
                  fetchMoreProjectsFromCursor();
                }}
              />
            )}

            {filterVal.length > 0 && (
              <FlatList
                data={searchResults}
                keyboardShouldPersistTaps='always'
                renderItem={({ item }) => {
                  return <>{renderSearch(item)}</>;
                }}
              />
            )}
          </>
        )}
      </KeyboardAvoidingView>
    </ShadowBox>
  );
};
