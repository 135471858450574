import { TeamRole, Team, User } from '@graphql/generated';
import { TeamAndIsMember } from '@src/types/team';

export const selectableTeamRoles = Object.values(TeamRole).filter(
  (role) => role != TeamRole.Owner
);
export const selectableAllTeamRoles = Object.values(TeamRole);

export const defaultTeamRole = TeamRole.Member;
export const getTeamRoleEnumValue = (role: string) => {
  const indexOfRole = Object.values(TeamRole).indexOf(role as TeamRole);
  return Object.values(TeamRole)[indexOfRole];
};

export const convertTeamsToTeamAndIsMember = (
  teams: Team[],
  myId: User['id']
): TeamAndIsMember[] => {
  return teams.map((t): TeamAndIsMember => {
    return {
      ...t,
      isMember: !!t.users.find((user) => user.id === myId),
    };
  });
};

export const sortTeamsByIsMember = (
  teamsWithIsMember: TeamAndIsMember[]
): TeamAndIsMember[] => {
  return teamsWithIsMember.sort((a, _b) => (a.isMember ? -1 : 1));
};
