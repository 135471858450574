export const SUBSCRIPTION_STATUS = {
  UNSUBSCRIBED: 'unsubscribed',
  SUBSCRIBED: 'subscribed',
  LAPSED: 'lapsed',
} as const;

export const PLAN_NAME = {
  TASKTAG_TEAM: 'tasktag_team',
  TASKTAG_PLUS: 'tasktag_plus',
} as const;

export const PROJECTS_AMOUNT = {
  BASIC: '3',
  PLUS: '50',
  TEAM: 'unlimited',
} as const;
