import React from 'react';
import { StyleSheet, ViewStyle } from 'react-native';

import { Box } from '@components/Restyle';
import Icon from '@components/shared/Icon/RestyleIcon';
import theme from '@themes/theme'; // Assuming you're using a theme file for colors

interface PlayCircleProps {
  iconSize?: number;
  iconColor?: string;
  containerStyle?: ViewStyle;
}

const PlayCircle: React.FC<PlayCircleProps> = ({
  iconSize = 30,
  iconColor = 'white',
  containerStyle,
}) => {
  return (
    <Box style={[styles.container, containerStyle]}>
      <Box style={styles.iconBackground}>
        <Icon
          name='PlayCircle'
          width={iconSize}
          height={iconSize}
          color={iconColor}
        />
      </Box>
    </Box>
  );
};

const styles = StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconBackground: {
    backgroundColor: theme.colors.grey04, // You can replace this with any gray color
    borderRadius: 50, // To make sure it's fully rounded
    padding: 5, // Adjust this to control the size of the background
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default PlayCircle;
