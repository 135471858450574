import { Field, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, TouchableOpacity } from 'react-native';
import { InferType } from 'yup';

import RadioButton from '@components/RadioButton/RadioButton';
import { Box } from '@components/Restyle';
import Icon from '@components/shared/Icon/RestyleIcon';
import TextField from '@components/shared/TextField/TextField';
import { CheckBoxButton } from '@components/Web/CheckBoxButton';
import { TaskChecklist as ChecklistType } from '@graphql/generated';
import { addChecklistSchema } from '@schemas/addChecklistSchema';

type EditChecklistProps = {
  item: ChecklistType;
  submit: (value: FormValues) => void;
  onDelete: (item: ChecklistType) => void;
  onSelect: (item: ChecklistType) => void;
  processing: boolean;
};

export type FormValues = InferType<typeof addChecklistSchema>;

export const EditChecklist: React.FC<EditChecklistProps> = ({
  item,
  submit,
  onDelete,
  onSelect,
  processing,
}) => {
  const { t } = useTranslation('models');
  const initialValue: FormValues = {
    id: item.id,
    name: item.name,
    completed: item.completedAt !== null,
    sort: item.sort,
  };
  return (
    <Formik
      initialValues={initialValue}
      validationSchema={addChecklistSchema}
      onSubmit={(values) => submit(values)}>
      {({ isValid, handleSubmit }) => (
        <Box
          accessibilityLabel='Edit Checklist Item'
          flexDirection='row'
          alignItems='center'
          paddingLeft='m'>
          {item.completedAt === null && (
            <Box marginRight='xs'>
              {Platform.OS !== 'web' && <Icon name='Move1' variant='l' />}
            </Box>
          )}
          <Box
            marginRight='xs'
            marginLeft={item.completedAt !== null ? 'xs' : undefined}
            paddingLeft={item.completedAt !== null ? 'xxxs' : undefined}>
            {Platform.OS === 'web' ? (
              <TouchableOpacity
                onPress={() => {
                  onSelect(item);
                }}>
                <CheckBoxButton checked={item.completedAt} />
              </TouchableOpacity>
            ) : (
              <RadioButton
                onPress={() => {
                  onSelect(item);
                }}
                isSelected={item.completedAt !== null}
              />
            )}
          </Box>
          <Box flex={1} ml='xs' mr='xs' pt='xxs' alignItems='center'>
            <Field
              component={TextField}
              name='name'
              isRequired
              autoFocus
              borderless
              marginBottom='xs'
              marginTop={isValid ? undefined : 'm'}
              placeholder={t('tasks.checklist.item')}
              accessibilityLabel={t('tasks.checklist.item')}
              textInputProps={{
                fontSize: 16,
                fontHeight: 21,
                maxLength: 100,
                onSubmitEditing: () => {
                  isValid && handleSubmit();
                },
              }}
            />
          </Box>
          {!processing && (
            <Box mr='m'>
              <TouchableOpacity
                accessibilityLabel='Delete'
                onPress={() => {
                  onDelete(item);
                }}>
                <Icon name='X' variant='l' />
              </TouchableOpacity>
            </Box>
          )}
        </Box>
      )}
    </Formik>
  );
};
