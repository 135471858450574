import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { Box } from '../Restyle';

interface HeaderWrapperProps {
  simple?: boolean;
  children: React.ReactNode;
}

export const HeaderWrapper: React.FC<HeaderWrapperProps> = ({
  children,
  simple,
}) => {
  const insets = useSafeAreaInsets();

  return (
    <Box
      paddingHorizontal='m'
      paddingBottom='m'
      borderBottomColor='grey02'
      borderBottomWidth={1}
      flexDirection='row'
      alignItems='center'
      style={{ paddingTop: simple ? 'auto' : insets.top }}
      backgroundColor='white'>
      {children}
    </Box>
  );
};
