/* eslint-disable no-nested-ternary */
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dimensions,
  ScrollView,
  TextInput,
  TouchableOpacity,
} from 'react-native';

import { ChatList } from '@components/Chat/ChatList.web';
import { EmptyChatList } from '@components/Chat/EmptyChatList.web';
import { NewChatModal } from '@components/Modals/NewChatModal';
import RadioButton from '@components/RadioButton/RadioButton';
import { Box, Text } from '@components/Restyle';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import Button from '@components/shared/Button/Button';
import Icon from '@components/shared/Icon/RestyleIcon';
import { WebIcon } from '@components/shared/Icon/WebIcon';
import { MenuItem } from '@components/shared/Popup/PopupItem';
import SearchInput from '@components/shared/SearchInput/SearchInput';
import {
  ChatEmptyState,
  EmptyStateType,
} from '@components/Web/Chats/ChatEmptyState.web';
import { Drawer } from '@components/Web/Drawer';
import {
  ChatDrawerScreen,
  useWebDrawer,
} from '@components/Web/Drawer/WebDrawerContext';
import {
  Chat,
  SearchableResult,
  useMarkChatsReadMutation,
  useSearchLazyQuery,
} from '@graphql/generated';
import useActiveChat from '@hooks/useActiveChat';
import { useListChatsFromQuery } from '@hooks/useListChatsFromQuery';
import useSearch from '@hooks/useSearch';
import { useSelected } from '@hooks/useSelected';
import { useAppNavigation } from '@navigation/useAppNavigation';
import { Search, SearchResultType } from '@root/types';
import { ChatsProps } from '@screens/Chats/ChatsData';
import ChatsSearch from '@screens/Global/ChatsSearch.web';
import { isMessageSearchResult } from '@utils/typeGuards';

export const ChatListDrawer = (props: ChatsProps) => {
  const { listChats, loading, refresh, refreshing } = props;
  const {
    chatExpanded,
    chatListOpen,
    setChatListOpen,
    setShouldChatListOpen,
    toggleChatListOpen,
    setIsBottomBannerShow,
    screen: currentScreen,
  } = useWebDrawer();
  const navigate = useAppNavigation();
  const { t } = useTranslation();
  const { setActiveMessageCursor, setIsOpenChat } = useActiveChat();
  const [selected, toggleSelected, selectAll, unSelectAll] =
    useSelected<(typeof listChats)[number]>();
  const [isEditing, setIsEditing] = useState(false);
  const [isArchiveList, setIsArchiveList] = useState(false);
  const [isNewChatOpen, setIsNewChatOpen] = useState(false);
  const [isQRshow, setIsQRshow] = useState(true);

  const [markChatsRead] = useMarkChatsReadMutation({
    onCompleted: () => {
      unSelectAll(), setIsEditing(false);
    },
  });

  const { chats } = useListChatsFromQuery({
    archivesOnly: true,
  });

  const [filteredChats, setFilteredChats] = useState<Chat[]>(chats);

  useEffect(() => {
    if (!chatListOpen) {
      setIsEditing(false);
      setIsSearching(false);
    }
    if (!isEditing) {
      unSelectAll();
    }
  }, [chatListOpen, isEditing]);

  const readSelected = () => {
    if (selected.length) {
      const selectedChatIds = selected.map((c) => c.id);
      markChatsRead({
        variables: {
          attributes: {
            chatIds: selectedChatIds,
          },
        },
      });
    }
  };

  // Timer used to refresh the render to update the chat message timestamps
  const [, setTimer] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer(new Date());
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    window.addEventListener('focus', refresh);
    // Calls refresh when the window first loads
    refresh();
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener('focus', refresh);
    };
  }, []);

  const menuList: MenuItem[] = [
    {
      iconName: 'Edit',
      name: t('shared:edit'),
      numberOfLines: 1,
      onSelect: () => setIsEditing((isEditing) => !isEditing),
    },
    {
      iconName: 'Archive',
      name: t('shared:archivedChat'),
      numberOfLines: 1,
      onSelect: () => setIsArchiveList(true),
    },
  ];

  const editingTitle = (
    <Box flexDirection='row' justifyContent='space-between' alignItems='center'>
      {isEditing ? (
        <>
          <Text variant='heading2'>
            {t('shared:selectedWithCount', {
              count: selected.length,
              context: selected.length === listChats.length ? 'all' : undefined,
            })}
          </Text>
          <WebIcon
            name='X'
            onPress={() => setIsEditing(false)}
            color='textPrimary'
            variant='l'
          />
        </>
      ) : (
        <>
          <Text variant='heading2' lineHeight={0}>
            {t('shared:archivedChat')}
          </Text>
          <WebIcon
            name='X'
            onPress={() => setIsArchiveList(false)}
            color='textPrimary'
            variant='l'
          />
        </>
      )}
    </Box>
  );

  const [search, setSearch] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const PAGESIZE = 20;
  const [counter, setCounter] = useState(0);
  const [showFooter, setShowFooter] = useState(false);

  const [globalSearch, { data, loading: loadingSearchChats }] =
    useSearchLazyQuery({
      fetchPolicy: 'no-cache',
    });

  const [searchedMessageList, setSearchedMessageList] = useState<
    Search<SearchableResult>[]
  >([]);
  useEffect(() => {
    const searchData = (data?.search as Search<SearchableResult>[]) || [];
    const initialResults = {
      messages: [],
      tasks: [],
      documents: [],
      projects: [],
      contacts: [],
    };

    const results =
      searchData.reduce<SearchResultType>((previous, result) => {
        if (
          previous.messages.some(
            (existingResult) => existingResult.record.id === result.record.id
          )
        )
          return previous;
        if (isMessageSearchResult(result))
          return { ...previous, messages: [...previous.messages, result] };

        return previous;
      }, initialResults) || initialResults;

    if (search !== '')
      setSearchedMessageList((prev) => [...prev, ...results.messages]);
    getLoadMore(results);
    setChatTotal(results?.messages[0]?.total);
  }, [data]);

  const getLoadMore = (results: SearchResultType) => {
    if (
      results.messages.length > 0 &&
      results.messages.length < results.messages[0].total
    ) {
      setChatLength(results.messages.length);
    } else {
      setChatLength(0);
    }
  };
  const [_chatTotal, setChatTotal] = useState(0);
  const globalSearchCall = () => {
    const from = PAGESIZE * counter;
    const total = data?.search[0]?.total || 0;
    if (!search || from > total) return;
    if (search) {
      return globalSearch({
        variables: {
          term: search,
          size: PAGESIZE,
          from: from,
          includeMessages: true,
          includeChats: false,
          includeContacts: false,
          includeDocuments: false,
          includeProjects: false,
          includeTasks: false,
        },
        onCompleted: () => setCounter(counter + 1),
      });
    }
  };
  const renderLoadMoreComponent = (): JSX.Element => {
    return loadingSearchChats ? (
      <Box alignItems='center' minHeight={40} p='s' minWidth={100}>
        <ActivityIndicatorLoading />
      </Box>
    ) : (
      <Box alignItems='center'>
        {_chatTotal > searchedMessageList.length && (
          <TouchableOpacity
            disabled={loadingSearchChats}
            accessibilityLabel={t('shared:loadMore')}
            onPress={() => globalSearchCall()}>
            <Box
              marginVertical='s'
              backgroundColor='grey01'
              p='s'
              borderRadius='xs'
              alignSelf='flex-start'
              minWidth={100}
              minHeight={40}>
              <Text variant='labelSmall' textAlign='center'>
                {t('shared:loadMore')}
              </Text>
            </Box>
          </TouchableOpacity>
        )}
      </Box>
    );
  };

  const [_isChatLength, setChatLength] = useState(0);

  const DEBOUNCE = 500;
  const debouncedGlobalSearch = useCallback(
    debounce(globalSearchCall, DEBOUNCE),
    [search]
  );

  useEffect(() => {
    setCounter(0);
    setSearchedMessageList([]);
    debouncedGlobalSearch();
    setChatLength(0);
    return () => debouncedGlobalSearch.cancel();
  }, [search]);

  useEffect(() => {
    setShowFooter(searchedMessageList.length > 19);
  }, [searchedMessageList]);

  useEffect(() => {
    if (isArchiveList) {
      search
        ? setFilteredChats(
            chats.filter((chat) =>
              chat.name
                .trim()
                .toLowerCase()
                .includes(search.trim().toLowerCase())
            ) as Chat[]
          )
        : setFilteredChats(chats as Chat[]);
    } else {
      if (search) {
        debouncedGlobalSearch();
      }
      return () => debouncedGlobalSearch.cancel();
    }
  }, [search]);

  const searchInputRef = useRef<TextInput>(null);
  const _title = isEditing || isArchiveList ? editingTitle : 'Chat';

  const handleToggleOpen = () => {
    toggleChatListOpen();
  };

  const { setIsSearching: setChatDetailIsSearching } = useSearch();

  const openChat = (chat) => {
    setIsNewChatOpen(false);
    if (isEditing) {
      toggleSelected(chat);
    } else {
      if (!chatExpanded && chatListOpen) {
        setShouldChatListOpen(true);
        setChatListOpen(false);
      }
      setIsOpenChat(true);
      setChatDetailIsSearching(false);

      setSearch('');
      setIsSearching(false);
      setActiveMessageCursor(undefined);
      navigate.navigateToChatDrawer({
        screen: ChatDrawerScreen.details,
        activeChat: chat,
      });
    }
  };

  const displayList = () => {
    const list = isArchiveList ? chats : listChats;
    if (!isEditing) return list;
    return list.filter((t) => (t.unreadCount ?? 0) > 0);
  };

  const chatWidth = Dimensions.get('window').width <= 1280 ? '480px' : '550px';

  const getTitle = () => {
    if (chatListOpen) {
      return isSearching ? 'Chat Search' : 'Chat';
    }
    return '';
  };

  return (
    <Box
      accessibilityLabel='Chats modal'
      width={chatListOpen ? chatWidth : '81px'}
      height='100vh'
      maxHeight='100vh'
      flexDirection='column'
      justifyContent='flex-end'>
      <Drawer
        title={getTitle()}
        titleVariant='heading2'
        onOpenToggle={handleToggleOpen}
        onClose={
          isSearching
            ? () => setIsSearching(false)
            : isEditing
            ? () => setIsEditing(false)
            : isArchiveList
            ? () => setIsArchiveList(false)
            : undefined
        }
        onSearch={() => {
          if (!isSearching) {
            setSearch('');
            setSearchedMessageList([]);
          }
          setIsSearching((o) => (o = !o));
        }}
        open={chatListOpen}
        elevated={!currentScreen}
        menuList={(!isEditing && !isArchiveList && menuList) || undefined}>
        {chatListOpen && !isEditing && isSearching && !isArchiveList && (
          <Box mx='m'>
            <SearchInput
              autoFocus
              ref={searchInputRef}
              value={search}
              onFocus={() => {
                setIsSearching(true);
              }}
              onBlur={() => {
                if (!search) {
                  setSearch('');
                }
              }}
              onTextChanged={(t: string) => {
                setSearch(t);
              }}
              onCancel={() => {
                setSearch('');
                setSearchedMessageList([]);
              }}
            />
            <Box mt='m'>
              <ScrollView
                style={{
                  maxHeight: 'calc(100vh - 180px)',
                  minHeight: '100px',
                }}
                showsVerticalScrollIndicator={false}>
                <ChatsSearch
                  search={search}
                  data={searchedMessageList}
                  loading={
                    loadingSearchChats && searchedMessageList.length === 0
                  }
                  sectionFooter={renderLoadMoreComponent()}
                  showFooter={showFooter && searchedMessageList.length > 19}
                  onPress={() => {
                    setSearch('');
                    setIsSearching(false);
                    setIsNewChatOpen(false);
                    if (!chatExpanded && chatListOpen) {
                      setShouldChatListOpen(true);
                      setChatListOpen(false);
                    }
                    setIsOpenChat(true);
                  }}
                />
              </ScrollView>
            </Box>
          </Box>
        )}

        {chatListOpen && isSearching && isArchiveList && (
          <Box flexDirection='column' flex={1} mb='m'>
            <Box flex={1}>
              <ChatList
                open={chatListOpen}
                loading={loading}
                isEditing={isEditing}
                chats={filteredChats}
                selected={selected}
                filterVal={search}
                hideSeparatorItem={true}
                onPress={(chat) => {
                  openChat(chat);
                }}
                refreshing={refreshing}
                onRefresh={refresh}
              />
            </Box>
          </Box>
        )}

        {!isSearching && (
          <Box flexDirection='column' flex={1}>
            <Box mx='m'>
              {chatListOpen && isEditing && displayList().length > 0 && (
                <TouchableOpacity
                  onPress={() => selectAll(listChats)}
                  style={{ alignSelf: 'flex-start' }}>
                  <Box
                    flexDirection='row'
                    marginVertical='s'
                    alignSelf='flex-start'>
                    <RadioButton
                      onPress={() => selectAll(listChats)}
                      isSelected={selected.length === listChats.length}
                    />
                    <Text variant='labelEmphasized' marginLeft='m'>
                      Select All
                    </Text>
                  </Box>
                </TouchableOpacity>
              )}
            </Box>
            {!loading && listChats.length == 0 && chatListOpen && (
              <Box flex={1}>
                {isQRshow && (
                  <ChatEmptyState
                    onClose={() => {
                      setIsQRshow(false);
                      setIsBottomBannerShow(true);
                    }}
                    type={EmptyStateType.Chat}></ChatEmptyState>
                )}
                {!isQRshow && (
                  <Box
                    flex={1}
                    alignItems='center'
                    px='listFooter'
                    pt='listFooter'>
                    <EmptyChatList />
                    <Box mb='xs' width={155}>
                      <Button
                        backgroundColor='textPrimary'
                        onPress={() => {
                          setActiveMessageCursor(undefined);
                          setIsNewChatOpen(true);
                        }}
                        borderRadius='xl'
                        variant='primary'
                        isSmall
                        accessibilityLabel={t(
                          'models:chat.labels.startANewChat'
                        )}>
                        {t('models:chat.labels.startANewChat')}
                      </Button>
                    </Box>
                  </Box>
                )}
              </Box>
            )}
            <Box flex={1}>
              {chatListOpen && isEditing && displayList().length == 0 && (
                <Box flex={1} paddingTop='m' alignItems='center'>
                  <Text>{t('models:chat.labels.unreadChatNotFound')}</Text>
                </Box>
              )}
              {!(chatListOpen && isEditing && displayList().length == 0) && (
                <ChatList
                  open={chatListOpen}
                  loading={loading}
                  isEditing={isEditing}
                  chats={displayList()}
                  selected={selected}
                  onPress={(chat) => {
                    openChat(chat);
                  }}
                  refreshing={refreshing}
                  onRefresh={refresh}
                  hideSeparatorItem={true}
                />
              )}
              {!isEditing &&
                !isArchiveList &&
                chatListOpen &&
                listChats.length > 0 && (
                  <Box mr='xxs'>
                    <Button
                      backgroundColor='textPrimary'
                      prefix={
                        <Box ml='s' mr='xxs'>
                          <Icon variant='l' name='ChatNew' color='white' />
                        </Box>
                      }
                      onPress={() => {
                        setActiveMessageCursor(undefined);
                        setIsNewChatOpen(true);
                      }}
                      borderRadius='xl'
                      variant='primary'
                      prefixMarginRight='s'
                      float='bottom-right'
                      isSmall
                      accessibilityLabel={t('models:chat.labels.newMessage')}>
                      {t('models:chat.labels.newMessage')}
                    </Button>
                  </Box>
                )}

              {!isEditing && !isArchiveList && !chatListOpen && (
                <Box
                  mt='xs'
                  mb='m'
                  backgroundColor='background'
                  justifyContent='center'
                  alignItems='center'>
                  <TouchableOpacity
                    onPress={() => {
                      setActiveMessageCursor(undefined);
                      setIsNewChatOpen(true);
                    }}>
                    <Box
                      width={48}
                      height={48}
                      bg='textPrimary'
                      borderRadius='l'
                      justifyContent='center'
                      alignItems='center'>
                      <Icon variant='l' name='ChatNew' color='white' />
                    </Box>
                  </TouchableOpacity>
                </Box>
              )}
            </Box>
          </Box>
        )}
        {chatListOpen && isEditing && displayList().length > 0 && (
          <TouchableOpacity>
            <Button
              marginHorizontal='s'
              disabled={!selected.length}
              variant='primary'
              onPress={selected.length > 0 ? readSelected : undefined}
              float='bottom-inset'
              accessibilityLabel={t('models:chat.edit.read')}>
              {t('models:chat.edit.read')}
            </Button>
          </TouchableOpacity>
        )}
        {isNewChatOpen && (
          <NewChatModal
            showModal={isNewChatOpen}
            onClose={() => setIsNewChatOpen(false)}
            onOpenChat={(chat) => {
              openChat(chat);
            }}
          />
        )}
      </Drawer>
    </Box>
  );
};
