import { useNavigation, useRoute } from '@react-navigation/native';
import { Field, useFormikContext } from 'formik';
import React, { ComponentProps, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Platform,
  TouchableOpacity,
  StyleSheet,
  View,
  Modal,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import { AddMembersSection } from '@components/Common/AddMembersSection';
import Button from '@components/shared/Button/Button';
import { FormValues } from '@components/Tasks/TaskFormStack';
import { useGetProjectLazyQuery } from '@graphql/generated';
import { TasksStackScreenProps } from '@navigation/tasks/tasks-stack';
import theme from '@themes/theme';
import { localDateFromUTCDate } from '@utils/formatters/date';

import Box from '../Box/Box';
import Icon from '../shared/Icon/Icon';
import TextField from '../shared/TextField/TextField';

type TaskCreateFormModalProps = {
  onProjectPress: () => void;
  onDatePress: () => void;
  parentProject?: boolean;
  selectedList?: [];
  taskname?: string;
  onSave?: () => void;
  disabled?: boolean;
  onCancel?: () => void;
  goBackOnComplete?: boolean;
  onFormValidityChange?: (isFormBlank: boolean) => void;
} & ComponentProps<typeof AddMembersSection>;

export const TaskCreateModalForm: React.FC<TaskCreateFormModalProps> = ({
  onProjectPress,
  onDatePress,
  taskname,
  onSave,
  disabled,
  onFormValidityChange,
  goBackOnComplete,
  ...addMembersSectionProps
}) => {
  const { t } = useTranslation();

  const { values, errors, touched, setFieldValue, resetForm } =
    useFormikContext<FormValues>();
  const { projectId, dueDate, name } = values;
  const [persistedProjectId, setPersistedProjectId] = useState<string | null>(
    null
  );

  const route =
    useRoute<TasksStackScreenProps<'task-copy-create-modal'>['route']>();

  const areAllFieldsBlank = () => {
    const { name, description, projectId, dueDate } = values;

    return (
      !name.trim() &&
      !description?.trim() &&
      !projectId &&
      !dueDate &&
      addMembersSectionProps.selectedList?.length === 0
    );
  };

  const [getProjectLazy, { data }] = useGetProjectLazyQuery({
    variables: {
      id: projectId,
    },
  });
  const { getProject: selectedProject } = data || {
    selectedProject: undefined,
  };
  const handleSave = () => {
    if (projectId && !goBackOnComplete) {
      setPersistedProjectId(projectId);
    }
    onSave && onSave();
    if (!goBackOnComplete && name.trim() && projectId) {
      setTimeout(() => {
        resetForm();
      }, 5);
    }
  };

  useEffect(() => {
    const isFormBlank = areAllFieldsBlank();
    onFormValidityChange(isFormBlank);
  }, [values, errors]);

  useEffect(() => {
    if (persistedProjectId && !projectId) {
      setFieldValue('projectId', persistedProjectId);
    }
  }, [persistedProjectId, projectId, setFieldValue]);

  useEffect(() => {
    if (projectId) {
      getProjectLazy({
        variables: {
          id: projectId,
        },
      });
    }
  }, [projectId]);

  const [memberCount, setMemberCount] = useState(
    addMembersSectionProps.selectedList?.length || 0
  );

  useEffect(() => {
    setMemberCount(addMembersSectionProps?.members.length || 0);
    console.log(
      '<<<<<<<< memb ',
      memberCount,
      addMembersSectionProps.members.length
    );
  }, [
    addMembersSectionProps.members,
    addMembersSectionProps.selectedList,
    addMembersSectionProps.members,
  ]);

  const getTaskname = () => {
    if (taskname === undefined) return null;
    return taskname;
  };
  const navigation = useNavigation();

  const projectEmpty = errors.projectId && touched.projectId;
  const [isCopyModalVisible, setCopyModalVisible] = useState(false);

  const openCopyModal = () => {
    setCopyModalVisible(true);
  };
  const closeCopyModal = () => setCopyModalVisible(false);

  const getProjectName = () => {
    if (!selectedProject) return '';

    const selectedProjectName = selectedProject.name;

    if (selectedProjectName.length > 20) {
      return selectedProjectName.slice(0, 13) + '...';
    }

    return selectedProjectName;
  };
  const getProjectIconColor = () => {
    if (projectEmpty) return 'alertRed';
    if (selectedProject && projectId) return 'white';
    return 'textPrimary';
  };

  const getButtonVariant = () => {
    if (projectEmpty) return 'editError';
    if (projectId && selectedProject) return 'editWhite';
    return 'edit';
  };

  return (
    <Box flex={1}>
      <KeyboardAwareScrollView style={{ flex: 1 }}>
        <Box
          paddingBottom='s'
          paddingHorizontal='s'
          maxWidth={Platform.OS === 'web' ? 560 : undefined}>
          <Box flex={1} marginHorizontal='xxs' style={{ paddingBottom: 40 }}>
            <Field
              component={TextField}
              label='Name'
              name='name'
              accessibilityLabel='Name'
              isRequired
              placeholder={t('models:tasks.placeholders.nameShortModal')}
              borderless={true}
              isLargeText={true}
              autoFocus
              marginBottom='xs'
              showCharsRemaining={name?.length > 0}
              textInputProps={{ maxLength: 25 }}
              defaultValue={getTaskname()}
            />
            <Field
              component={TextField}
              label='Description'
              name='description'
              accessibilityLabel='Description'
              placeholder={t('models:tasks.placeholders.descriptionShortModal')}
              borderless={true}
              showCharsRemaining
              textInputProps={{ maxLength: 500, multiline: true }}
            />

            <Box style={styles.projectDateContainer}>
              <Button
                marginRight='s'
                backgroundColor={
                  selectedProject && projectId ? 'greenSecondary' : 'white'
                }
                accessibilityLabel={t(
                  'models:tasks.create.project.addToProject'
                )}
                variant={getButtonVariant()}
                prefix={<Icon name='Folder' color={getProjectIconColor()} />}
                onPress={onProjectPress}
                isSmall>
                {selectedProject && projectId
                  ? getProjectName()
                  : t('models:tasks.create.project.title')}
              </Button>
              <Box>
                <Box flex={1}>
                  <Button
                    accessibilityLabel={t('models:tasks.create.dates.dueDate')}
                    variant={dueDate ? 'editSelected' : 'edit'}
                    onPress={onDatePress}
                    marginRight='s'
                    backgroundColor='white'
                    prefixMarginRight='xs'
                    prefix={<Icon name='Calendar' />}
                    isSmall>
                    {dueDate
                      ? t('format:monthAndDay', {
                          val: localDateFromUTCDate(dueDate),
                        })
                      : t('models:tasks.create.dates.dueDate')}
                  </Button>
                </Box>
              </Box>

              <Button
                accessibilityLabel={t('models:tasks.create.copyCta')}
                variant='edit'
                prefixMarginRight='xxxs'
                backgroundColor={
                  isCopyModalVisible ? 'greenSecondary' : 'white'
                }
                prefix={<Icon name='MoreHorizontal' />}
                onPress={() => {
                  route.name !== 'task-copy-create-modal' && openCopyModal();
                }}
                isSmall></Button>
            </Box>

            <Modal
              transparent={true}
              visible={isCopyModalVisible}
              animationType='fade'
              onRequestClose={closeCopyModal}>
              <TouchableOpacity style={styles.overlay} onPress={closeCopyModal}>
                <View style={styles.popup}>
                  <Button
                    onPress={() => {
                      closeCopyModal();
                      navigation.navigate('copy-from-task');
                    }}
                    prefix={<Icon name='Copy' />}
                    variant='edit'
                    backgroundColor='white'>
                    {t('Copy from existing task')}
                  </Button>
                </View>
              </TouchableOpacity>
            </Modal>

            <Box style={styles.memberContainer}>
              <Box>
                <Box flex={1}>
                  <Button
                    accessibilityLabel={t('models:tasks.create.members.title')}
                    variant='editSelected'
                    backgroundColor='white'
                    onPress={addMembersSectionProps.addMembers}
                    prefixMarginRight='xs'
                    prefix={<Icon name='Users' color='textPrimary' />}
                    isSmall>
                    {memberCount > 0
                      ? memberCount +
                        ' ' +
                        t('models:tasks.create.members.title')
                      : t('models:tasks.create.members.title')}
                  </Button>
                </Box>
              </Box>

              {onSave && (
                <Box style={styles.save}>
                  <TouchableOpacity
                    onPress={handleSave}
                    accessibilityLabel={t('shared:save')}>
                    <Box
                      alignItems='center'
                      justifyContent='center'
                      borderRadius='xxl'
                      bg={
                        disabled || !name.trim() || !projectId
                          ? 'grey04'
                          : 'greenSecondary'
                      }
                      width={40}
                      height={40}>
                      <Icon variant='xl' name='ArrowUp' color='white' />
                    </Box>
                  </TouchableOpacity>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </KeyboardAwareScrollView>
    </Box>
  );
};

const styles = StyleSheet.create({
  save: {
    zIndex: 99,
  },
  memberContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: theme.spacing.s,
  },
  projectDateContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingVertical: theme.spacing.s,
    alignItems: 'center',
  },
  overlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  popup: {
    backgroundColor: 'white',
    borderRadius: theme.borderRadii.s,
    elevation: 5,
    minWidth: 150,
  },
});
