import React, { ReactNode } from 'react';
import { Modal, StyleSheet, TouchableWithoutFeedback } from 'react-native';

import { SimpleHeader } from '@components/Headers/SimpleHeader.web';
import { Box, ShadowBox } from '@components/Restyle';
import theme from '@themes/theme';

type FinalWebModalProps = {
  accessibilityLabel: string;
  visible: boolean;
  children: ReactNode;
  onClose?: () => void;
  height?: number;
  width?: number;
  title?: string;
  isFullScreen?: boolen;
  titleVariant?: keyof typeof theme.textVariants;
  titleColor?: keyof typeof theme.colors;
  marginHorizontal?: keyof typeof theme.spacing;
  marginTop?: keyof typeof theme.spacing;
};

export const WebModal = ({
  accessibilityLabel,
  visible,
  onClose,
  children,
  height,
  width,
  title,
  isFullScreen = false,
  titleVariant,
  titleColor,
  marginHorizontal,
  marginTop,
}: FinalWebModalProps) => {
  return (
    <Modal animationType='fade' transparent={true} visible={visible}>
      <Box
        accessibilityLabel={
          accessibilityLabel ? accessibilityLabel + ' Screen' : 'Modal'
        }
        style={styles.modalContainer}>
        <TouchableWithoutFeedback onPress={onClose} style={styles.modal}>
          <Box style={styles.modal}></Box>
        </TouchableWithoutFeedback>

        <ShadowBox
          accessibilityLabel={title ? title + ' Screen' : 'Modal'}
          variant='thin'
          style={styles.popUp}
          borderRadius={isFullScreen ? 'none' : 'm'}
          overflow='hidden'
          height={height}
          width={width}>
          <>
            {!!title && (
              <SimpleHeader
                title={title}
                onClose={onClose}
                titleVariant={titleVariant}
                titleColor={titleColor}
                marginHorizontal={marginHorizontal}
                marginTop={marginTop}
              />
            )}
            {children}
          </>
        </ShadowBox>
      </Box>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modal: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'black',
    opacity: 0.5,
  },
  modalContainer: {
    ...StyleSheet.absoluteFillObject,
    alignItems: 'center',
    justifyContent: 'center',
  },
  popUp: {
    position: 'absolute',
    backgroundColor: 'white',
  },
});
