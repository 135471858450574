import { useFormikContext } from 'formik';
import React from 'react';

import { TeamRole } from '@graphql/generated';
import { FormValues } from '@navigation/payments/create-subscription-stack';
import { useAppNavigation } from '@navigation/useAppNavigation';

import { RoleModal } from './RoleModal';

interface MemberSelectProps {
  title?: string;
  onClose?: () => void;
  buttonLabel?: string;
}

export const MemberRoles = ({
  title = 'Members',
  onClose: onCloseProp,
  buttonLabel,
}: MemberSelectProps) => {
  const { setFieldValue, values, getFieldProps } =
    useFormikContext<FormValues>();
  const { users } = values;
  const { name: fieldName } = getFieldProps('users');
  const navigation = useAppNavigation();

  const members = users.map((u) => ({ user: u, role: u.role }));
  // const filteredUsers = usersAndMe.filter(
  //   (u) => u?.id !== ownerId && u?.id !== me.id
  // );
  // const filteredValues = value.filter(
  //   (m) => m.id !== ownerId && m.id !== me.id
  // );
  // const owner = value.find((v) => v.id === ownerId);
  // const sortedFilteredUsers = filteredUsers
  //   .slice()
  //   .sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1));
  //

  const onClose = onCloseProp || navigation.goBack;

  const updateMemberRole = (id: string, role: TeamRole) => {
    // Find the index of the object with the specified id
    const index = members.findIndex((obj) => obj.user.id === id);

    // If the object with the specified id is found, update its "name" property
    if (index !== -1) {
      const updatedArray = users; // Create a copy of the original array
      updatedArray[index] = { ...updatedArray[index], role }; // Update the "name" property
      setFieldValue(fieldName, updatedArray);
    }
  };

  return (
    <RoleModal
      title={title}
      list={members}
      buttonLabel={buttonLabel}
      updateMemberRole={updateMemberRole}
      onClose={onClose}
    />
  );
};
