import React, { createContext, useContext, useState } from 'react';

import { ProjectMemberRole } from '@graphql/generated';
import { ProjectSectionOptions } from '@hooks/useTasksFilter';
import { PickEnum } from '@root/types';

export type FilterableProjectRolesOptions = PickEnum<
  ProjectMemberRole,
  | ProjectMemberRole.Owner
  | ProjectMemberRole.Admin
  | ProjectMemberRole.Editor
  | ProjectMemberRole.Viewer
>;

type projectsFilterContext = {
  sectionOption: ProjectSectionOptions;
  setSectionOption: React.Dispatch<React.SetStateAction<ProjectSectionOptions>>;

  roleFilter: FilterableProjectRolesOptions | undefined;
  setRoleFilter: React.Dispatch<
    React.SetStateAction<FilterableProjectRolesOptions | undefined>
  >;
  isMapView: boolean;
  setMapView: React.Dispatch<boolean>;
};

const projectsFilterContext = createContext<projectsFilterContext | undefined>(
  undefined
);

export const ProjectsFilterProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { Provider } = projectsFilterContext;

  const [roleFilter, setRoleFilter] = useState<FilterableProjectRolesOptions>();
  const [isMapView, setMapView] = useState(false);
  const [sectionOption, setSectionOption] = useState<ProjectSectionOptions>(
    ProjectSectionOptions.Active
  );
  return (
    <Provider
      value={{
        sectionOption,
        setSectionOption,
        roleFilter,
        setRoleFilter,
        isMapView,
        setMapView,
      }}>
      {children}
    </Provider>
  );
};

export const useProjectsFilter = (): projectsFilterContext => {
  const context = useContext(projectsFilterContext);
  if (context === undefined) {
    throw new Error('useProjectsFilter must be used within a Provider');
  }
  return context;
};
