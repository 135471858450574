import {
  StackActions,
  useFocusEffect,
  useNavigation,
} from '@react-navigation/native';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActivityIndicator,
  ScrollView,
  useWindowDimensions,
} from 'react-native';

import { useListTeamsQuery } from '@graphql/generated';
import useRevenueCat from '@hooks/useRevenueCat';
import { PLAN_NAME } from '@src/constants/constants';

import theme from '../../../themes/theme';
import { Plan } from '../../../types';
import BenefitList from '../../components/Payments/BenefitList';
import PaymentOptionCard from '../../components/Payments/PaymentOptionCard';
import PlanTitle from '../../components/Payments/PlanTitle';
import { Box, Text } from '../../components/Restyle';
import Button from '../../components/shared/Button/Button';

type PlanKeys = 'tasktag_plus' | 'tasktag_team';

type Plans = {
  [key in PlanKeys]: Plan;
};

const UpgradePlus: React.FC = () => {
  const { t } = useTranslation();

  const { data: teamsData, loading: teamsLoading } = useListTeamsQuery();

  const {
    newCustomer,
    currentPlan,
    setCurrentPlan,
    billingCycle,
    setBillingCycle,
    loading: subscriptionLoading,
    billedAnuallyCost,
    billedAnuallyMonthlyCost,
    billedMonthlyCost,
    annualSavingsPercentage,
    startSubscription,
  } = useRevenueCat();

  const navigation = useNavigation();

  const popAction = StackActions.pop(1);

  useFocusEffect(
    useCallback(() => {
      setCurrentPlan('tasktag_plus');
    }, [setCurrentPlan])
  );

  const PLUS_PLAN: Plan = {
    name: t('models:payments.plusPlan.name'),
    color: 'orange',
    icon: 'Zap',
    description: t('models:payments.plusPlan.description'),
    benefits: t('models:payments.plusPlan.benefits', { returnObjects: true }),
    monthlyPayment: t('models:payments.plusPlan.monthlyPayment'),
    yearlyPayment: t('models:payments.plusPlan.yearlyPayment'),
    switchPrefix: t('models:payments.teamPlan.cta.prefix'),
    switchSuffix: t('models:payments.teamPlan.cta.suffix'),
  };

  const TEAM_PLAN: Plan = {
    name: 'Team',
    color: 'greenBrand',
    icon: 'Zap',
    monthlyPayment: t('Need-to-check'),
    yearlyPayment: t('Need-to-check'),
    description: t('models:payments.teamPlan.description'),
    benefits: t('models:payments.teamPlan.benefits', { returnObjects: true }),
    switchPrefix: t('models:payments.plusPlan.cta.prefix'),
    switchSuffix: t('models:payments.plusPlan.cta.suffix'),
  };

  const PLANS: Plans = {
    tasktag_plus: PLUS_PLAN,
    tasktag_team: TEAM_PLAN,
  };

  const { width } = useWindowDimensions();

  const PAYMENT_OPTION_HEIGHT = width / 2 - theme.spacing.m * 3;

  const switchPlan = () => {
    if (currentPlan === PLAN_NAME.TASKTAG_PLUS) {
      console.log('visit enterprise page');
    }
  };

  const purchasePlus = () => {
    const teamId = teamsData?.listTeams.find((t) => t.personal)?.id;
    startSubscription(teamId, () => {
      navigation.dispatch(popAction);
    });
  };

  const showTeamCTA = false;

  const purchaseButtonText = () => {
    return newCustomer
      ? t('models:payments.freeTrialCta')
      : t('models:payments.subscribeNow');
  };

  return (
    <>
      <ScrollView
        style={{
          flex: 1,
        }}>
        <Box
          flex={1}
          marginTop='s'
          paddingBottom='listFooter'
          paddingHorizontal='m'
          paddingTop='m'>
          <PlanTitle
            planName={PLANS[currentPlan].name}
            {...PLANS[currentPlan]}
          />
          <Box
            style={{ marginTop: 32 }}
            paddingBottom='m'
            borderBottomColor='grey02'
            borderBottomWidth={1}>
            <Text variant='labelLarge'>
              {t('models:payments.benefits.prefix')}{' '}
              <Text
                variant='labelLarge'
                fontFamily='Inter_600SemiBold'
                color={PLANS[currentPlan].color}>
                {PLANS[currentPlan].name}
              </Text>{' '}
              {t('models:payments.benefits.suffix')}
            </Text>
          </Box>
          <Box marginTop='m'>
            <BenefitList {...PLANS[currentPlan]} />
          </Box>
          <Box flexDirection='row' style={{ marginTop: 32 }}>
            <Box
              style={{ height: PAYMENT_OPTION_HEIGHT, flex: 1 }}
              paddingRight='xs'>
              {billedAnuallyCost && (
                <PaymentOptionCard
                  billingCycle='annually'
                  monthlyCost={parseInt(
                    billedAnuallyMonthlyCost?.toFixed(0) || ''
                  )}
                  setBillingCycle={(cycle) => setBillingCycle(cycle)}
                  totalCost={parseInt(billedAnuallyCost?.toFixed(1) || '0')}
                  isSelected={billingCycle === 'annually'}
                  savingPercentage={annualSavingsPercentage}
                />
              )}
            </Box>
            <Box
              style={{ height: PAYMENT_OPTION_HEIGHT, flex: 1 }}
              paddingLeft='xs'>
              {billedMonthlyCost && (
                <PaymentOptionCard
                  billingCycle='monthly'
                  monthlyCost={parseInt(billedMonthlyCost.toFixed(0) || '')}
                  setBillingCycle={(cycle) => setBillingCycle(cycle)}
                  isSelected={billingCycle === 'monthly'}
                />
              )}
            </Box>
          </Box>
          {showTeamCTA && (
            <Text marginTop='l' variant='labelSmall'>
              {PLANS[currentPlan].switchPrefix}{' '}
              <Text
                variant='labelSmall'
                color='greenSecondary'
                onPress={switchPlan}>
                {t('shared:clickHere')}
              </Text>{' '}
              {PLANS[currentPlan].switchSuffix}
            </Text>
          )}
        </Box>
      </ScrollView>
      <Button
        variant='primary'
        float='bottom-inset'
        onPress={purchasePlus}
        disabled={teamsLoading}>
        {!subscriptionLoading ? purchaseButtonText() : <ActivityIndicator />}
      </Button>
    </>
  );
};

export default UpgradePlus;
