import React from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';

import { WebModal } from '@components/Modals/WebModal.web';
import { Box, Text } from '@components/Restyle';

interface WebModalComponentProps {
  modalWidth: number;
  modalHeight: number;
  xVisible: boolean;
  msgBody: string;
  okButtonTitle: string;
  onCloseModal: () => void;
  onPressOK: () => void;
}

const WebModalComponent: React.FC<WebModalComponentProps> = ({
  modalWidth,
  modalHeight,
  xVisible,
  msgBody,
  okButtonTitle,
  onCloseModal,
  onPressOK,
}) => {
  const { t } = useTranslation();

  return (
    <WebModal
      visible={xVisible}
      width={modalWidth}
      height={modalHeight}
      title={t('models:teams.leave.title')}
      titleVariant='labelLarge'
      titleColor='textSecondary'
      marginTop='m'
      marginHorizontal='l'
      onClose={onCloseModal}>
      <Box flex={1} mt='l' mx='l'>
        <Text variant='webBodySecondary' color='black'>
          {msgBody}
        </Text>
        <Box
          mt='l'
          flexDirection='row'
          alignItems='flex-end'
          justifyContent='flex-end'>
          <TouchableOpacity onPress={onCloseModal}>
            <Box
              height={56}
              justifyContent='center'
              alignContent='center'
              alignItems='center'
              accessibilityLabel={t('models:teams.removeTeamMember.assign')}>
              <Text variant='typography' color='textPrimary' mr='l'>
                {t('shared:cancel')}
              </Text>
            </Box>
          </TouchableOpacity>

          <TouchableOpacity onPress={onPressOK}>
            <Box
              backgroundColor='black'
              borderRadius='xs'
              width={100}
              height={56}
              justifyContent='center'
              alignContent='center'
              alignItems='center'
              accessibilityLabel={okButtonTitle}>
              <Text variant='typography' color='white'>
                {okButtonTitle}
              </Text>
            </Box>
          </TouchableOpacity>
        </Box>
      </Box>
    </WebModal>
  );
};

export default WebModalComponent;
