import React from 'react';
import { FlatList, TouchableOpacity } from 'react-native';

import { Box } from '@components/Restyle';
import { Member } from '@components/User/Member';
import { User } from '@graphql/generated';

interface MemberListProps {
  users: User[];
  onPress: (user: User) => void;
  listEmptyComponent: JSX.Element;
  highlightedValue?: string;
}

export const MemberList: React.FC<MemberListProps> = ({
  users,
  onPress,
  listEmptyComponent,
  highlightedValue,
}) => {
  return (
    <FlatList
      data={users}
      renderItem={({ item }) => (
        <TouchableOpacity onPress={() => onPress(item)}>
          <Box paddingHorizontal='m' paddingVertical='s'>
            <Member member={item} highlightedValue={highlightedValue} />
          </Box>
        </TouchableOpacity>
      )}
      ListHeaderComponent={<Box marginTop='m' />}
      ListFooterComponent={<Box marginTop='m' />}
      ListEmptyComponent={listEmptyComponent}
      keyExtractor={(item) => item.id}
    />
  );
};
