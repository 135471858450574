import { gql, useApolloClient } from '@apollo/client';
import { useCallback } from 'react';

// Define your mutation
const VALIDATE_FILE_EXTENSION = gql`
  query validateContentType($contentType: String!) {
    validateContentType(contentType: $contentType)
  }
`;

export const useFileValidation = () => {
  const client = useApolloClient();

  const isValidExtension = useCallback(
    async (contentType: string): Promise<boolean> => {
      try {
        const response = await client.query({
          query: VALIDATE_FILE_EXTENSION,
          variables: { contentType },
        });
        return response.data.validateContentType;
      } catch (error) {
        console.error('Error calling validateFileExtension mutation:', error);
        return false;
      }
    },
    [client]
  );

  return { isValidExtension };
};
