import { Platform } from 'react-native';

export const actionsModal = () => {
  if (Platform.OS !== 'web') return;

  dispatchEvent(
    new CustomEvent('actions-modal', {
      detail: null,
    })
  );
};
