import { WatchQueryFetchPolicy } from '@apollo/client';
import { useState } from 'react';
import { RefreshControl } from 'react-native';

import {
  Document,
  DocumentSortOption,
  ListProjectDocumentsQuery,
  Project,
  User,
  useListProjectDocumentsQuery,
} from '@graphql/generated';

const DEFAULT_PER_PAGE_COUNT = 15;

export const useListProjectDocumentsFromQuery = ({
  projectIds = undefined,
  ownerIds = undefined,
  sortBy = undefined,
  first = DEFAULT_PER_PAGE_COUNT,
  last = undefined,
  after = undefined,
  before = undefined,
  includeFiles = undefined,
  includeMedia = undefined,
  fetchPolicy = 'cache-and-network',
  onCompleted = undefined,
  onFetchMoreCompleted = undefined,
}: {
  projectIds?: Project['id'][];
  ownerIds?: User['id'][];

  sortBy?: DocumentSortOption;
  limit?: number;
  first?: number;
  last?: number;
  after?: string;
  before?: string;
  includeFiles?: boolean;
  includeMedia?: boolean;
  fetchPolicy?: WatchQueryFetchPolicy;
  onCompleted?: (data?: ListProjectDocumentsQuery) => void;
  onFetchMoreCompleted?: () => void;
}) => {
  const [refreshing, setRefreshing] = useState(false);
  const [listProjectDocumentsData, setListProjectDocumentsData] = useState<
    ListProjectDocumentsQuery | undefined
  >();

  const { loading, refetch, fetchMore } = useListProjectDocumentsQuery({
    variables: {
      projectIds,
      ownerIds,
      sortBy,
      first,
      last,
      before,
      after,
      includeFiles,
      includeMedia,
    },
    fetchPolicy,
    onCompleted: (data) => {
      setListProjectDocumentsData(data);
      onCompleted && onCompleted(data);
    },
  });

  const onRefresh = () => {
    setRefreshing(true);
    refetch();
  };
  const refreshControl = (
    <RefreshControl
      refreshing={refreshing}
      onRefresh={() => {
        setRefreshing(true);
        refetch();
      }}
    />
  );

  const { listProjectDocuments } = listProjectDocumentsData || {
    listProjectDocuments: undefined,
  };
  const { edges = [], pageInfo } = listProjectDocuments || { edges: [] };
  const documents =
    edges?.reduce((acc, curr) => {
      if (curr && curr.node && curr.node !== undefined) {
        return [...acc, curr.node as Document];
      } else {
        return acc;
      }
    }, [] as Document[]) || [];

  const fetchFromCursor = () => {
    if (!documents || !pageInfo?.hasNextPage || loading) {
      onFetchMoreCompleted && onFetchMoreCompleted();
      return;
    }
    const cursor = pageInfo?.endCursor;
    fetchMore({
      variables: {
        after: cursor,
        first,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        onFetchMoreCompleted && onFetchMoreCompleted();
        const newEdges = fetchMoreResult.listProjectDocuments.edges;
        const pageInfo = fetchMoreResult.listProjectDocuments.pageInfo;
        return newEdges?.length
          ? {
              // Put the new comments at the end of the list and update `pageInfo`
              // so we have the new `endCursor` and `hasNextPage` values
              listProjectDocuments: {
                __typename: previousResult.listProjectDocuments.__typename,
                edges: [
                  ...previousResult.listProjectDocuments.edges,
                  ...newEdges,
                ],
                pageInfo,
              },
            }
          : previousResult;
      },
    });
  };

  return {
    documents: documents,
    loading,
    pageInfo,
    refetch,
    fetchFromCursor,
    refreshing,
    setRefreshing,
    refreshControl,
    onRefresh,
  };
};
