import React from 'react';
import { TouchableOpacity } from 'react-native';
import Highlighter from 'react-native-highlight-words';

import { Box, Text } from '@components/Restyle';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import Icon from '@components/shared/Icon/Icon';
import { Document, LocalFile } from '@graphql/generated';
import useSearch from '@hooks/useSearch';
import theme from '@themes/theme';
import { formatSize } from '@utils/fileUtils';
interface DocumentCardInterface {
  filterVal: string;
  document: Document | LocalFile;
  onPress?: (document: Document | LocalFile) => void;
  onLongPress?: (document: Document | LocalFile) => void;
  onDelete?: (document: LocalFile) => void;
}

const DocumentCard: React.FC<DocumentCardInterface> = ({
  filterVal = '',
  document,
  onPress,
  onLongPress,
  onDelete,
}) => {
  const { name, size, contentType } = document;
  const { search } = useSearch();
  const isLocal = document.clientId === document.id;
  filterVal = filterVal ? filterVal : search;
  return (
    <TouchableOpacity
      onPress={() => onPress && onPress(document)}
      onLongPress={() => onLongPress && onLongPress(document)}>
      <Box flexDirection='row' marginBottom='xs'>
        <Box
          accessibilityLabel='Message'
          flexDirection='row'
          alignItems='center'
          borderColor='grey02'
          borderWidth={1}
          borderRadius='xs'
          padding='xs'
          position='relative'
          maxWidth='100%'>
          <Icon fileType={contentType || 'unknown'} color='black' variant='l' />
          <Box marginLeft='xs' flex={1}>
            <Text variant='labelSmall' color='black' numberOfLines={1}>
              <Highlighter
                autoEscape
                highlightStyle={{
                  color: theme.colors.black,
                  backgroundColor: theme.colors.yellowBright,
                }}
                searchWords={filterVal.split(' ') || ''}
                textToHighlight={name}
              />
            </Text>
            {size > 0 && (
              <Text variant='metadataSecondary' color='textSecondary'>
                {formatSize(size)}
              </Text>
            )}
          </Box>
          {onDelete && (
            <TouchableOpacity
              onPress={() => onDelete(document as LocalFile)}
              hitSlop={{ top: 10, bottom: 10, right: 10, left: 10 }}>
              <Icon
                name='X2'
                variant='l'
                marginTop='xxxs'
                color='textPrimary'
              />
            </TouchableOpacity>
          )}
          {!onDelete && isLocal && <ActivityIndicatorLoading />}
        </Box>
        <Box marginLeft='xs' flex={1}></Box>
      </Box>
    </TouchableOpacity>
  );
};

export default DocumentCard;
