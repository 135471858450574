import React, { ComponentProps } from 'react';
import { TextStyle, TouchableOpacity, StyleSheet } from 'react-native';

import { Text, Box } from '@components//Restyle';
import Icon from '@components/shared/Icon/Icon';
import { IconSizes } from '@components/shared/Icon/RestyleIcon';
import theme from '@themes/theme';

enum RadioTextType {
  body = 'body',
  labelEmphasized = 'labelEmphasized',
}

type RadioButtonType = {
  onPress: () => void;
  isSelected: boolean;
  text?: string;
  iconVariant?: IconSizes;
  textVariant?: keyof typeof theme.textVariants;
  textColor?: keyof typeof theme.colors;
  textStyle?: TextStyle;
  disabled?: boolean;
};

const RadioButton = ({
  onPress,
  isSelected,
  text,
  iconVariant = 'l',
  textVariant,
  textColor,
  textStyle = {
    paddingLeft: 8,
  },
  disabled,
}: RadioButtonType) => {
  const _textVariant =
    textVariant === 'labelEmphasized'
      ? RadioTextType.labelEmphasized
      : RadioTextType.body;

  const name = (): ComponentProps<typeof Icon>['name'] => {
    if (disabled) {
      return 'StateDisabled';
    }

    if (isSelected) {
      return 'StateYes';
    }

    return 'StateNo';
  };
  return (
    <TouchableOpacity
      onPress={onPress}
      disabled={disabled}
      accessibilityLabel={disabled ? 'Selected' : 'Unselected'}>
      <Box accessibilityLabel={text} flexDirection='row' alignItems='center'>
        <Box
          style={{ position: 'relative' }}
          opacity={disabled && isSelected ? 0.5 : 1}>
          {disabled && (
            <Icon
              name={name()}
              variant={iconVariant}
              color='grey02'
              disabled={disabled}
              style={{ ...StyleSheet.absoluteFillObject }}
            />
          )}
          <Icon
            name={
              (disabled && !isSelected) || !isSelected ? 'StateNo' : 'StateYes'
            }
            variant={iconVariant}
            color={isSelected ? 'greenSecondary' : 'grey03'}
            onPress={onPress}
            disabled={disabled}
          />
        </Box>
        {text && (
          <Text
            numberOfLines={1}
            variant={_textVariant}
            color={textColor}
            style={textStyle}>
            {text}
          </Text>
        )}
      </Box>
    </TouchableOpacity>
  );
};

export default RadioButton;
